.resource-preview {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    background-color: var(--glass-background);
    border-radius: var(--radius-lg);
    padding: 0;
    border: 1px solid var(--border-interactive);
    margin-bottom: var(--spacing-xl); /* Using a variable for margin */
    overflow: hidden;
    min-height: 300px;
    max-height: 400px; /* Add a max-height to the container */
}

.resource-preview-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--content-spacing-md); /* Consistent spacing */
    border-bottom: 1px solid var(--border-interactive);
}

.resource-preview-header h1 {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: var(--font-size-sm);
    color: var(--text-primary);
    white-space: nowrap;
    max-width: 90%;
}

.resource-preview-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.resource-preview-header-right button {
    background-color: transparent; /* Removed background for a cleaner look */
    border-radius: var(--radius-md); /* Slightly smaller radius */
    padding: var(--spacing-sm);
    border: 1px solid var(--border-interactive);
    cursor: pointer; /* Indicate interactivity */
    color: var(--text-primary);
}

.resource-preview-header-right button:hover {
    background-color: var(--background-hover); /* Subtle hover effect */
    border-color: var(--border-interactive-hover);
}

.resource-preview-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-sm);
    line-height: var(--line-height-base);
    overflow: hidden; /* Changed from auto to hidden */
    position: relative; /* Add position relative */
    height: 100%; /* Ensure it takes full height */
    min-height: 270px;
}

.resource-preview-content {
    width: 100%;
    height: 100%; /* Changed from 30% to 100% */
    box-sizing: border-box;
    overflow-y: auto; /* Keep auto overflow */
    padding: var(--spacing-sm);
    position: absolute; /* Make it absolute */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.resource-preview-content.code {
    padding: 0;
}

/* Add styles for essay and visualization content types */
.resource-preview-content .ai-response-content {
    padding: var(--spacing-sm);
    height: 100%;
    overflow: visible;
}

/* Ensure proper sizing for visualization content */
.resource-preview-content [class*="visualization-"] {
    max-height: 100%;
    overflow: visible;
}

/* Ensure loading state doesn't break layout */
.resource-preview-content.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}