.dev-toolbar {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: var(--z-tooltip);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: var(--glass-background);
  border: 1px solid var(--border-glass);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-elevated);
  max-width: 400px;
  backdrop-filter: blur(8px);
  transition: all var(--transition-base);
}

.dev-toolbar-button {
  padding: 0.625rem 1.25rem;
  background: var(--primary-gradient);
  color: var(--text-light);
  border: none;
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  letter-spacing: var(--letter-spacing-wide);
}

.dev-toolbar-button:hover {
  background: var(--primary-gradient);
  transform: var(--button-hover-transform);
  box-shadow: var(--shadow-primary-hover);
  filter: brightness(1.05);
}

.dev-toolbar-button:active {
  transform: var(--button-active-transform);
  box-shadow: none;
}

/* Branch Debug Panel Styles */
.branch-debug-panel {
  background-color: var(--background-main);
  border: 1px solid var(--border-interactive);
  border-radius: var(--radius-md);
  padding: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.branch-debug-info {
  margin-bottom: 1rem;
}

.branch-debug-info p {
  margin: 0.375rem 0;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-snug);
}

.branch-debug-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  flex-wrap: wrap;
}

.branch-count-input {
  width: 70px;
  padding: 0.5rem;
  border: 1px solid var(--border-interactive);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  background-color: var(--input-bg);
  color: var(--text-primary);
  transition: border-color var(--transition-fast);
  text-align: center;
}

.branch-count-input:focus {
  outline: none;
  border-color: var(--border-focus);
  box-shadow: 0 0 0 2px var(--input-focus);
}
