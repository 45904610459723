.chat-limit-banner {
  position: relative;
  padding: var(--spacing-md) var(--spacing-xl);
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 24px;
  box-shadow: 0 2px 8px var(--card-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Modified to space-between */
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
  transition: all 0.2s ease;
  animation: slideDown 0.3s ease-out;
  max-width: 400px;
}

.chat-limit-banner.empty {
  margin-top: 1rem;
}

.chat-limit-banner .chat-limit-content {
  flex: 1;
  padding: 0 1rem;
  display: flex; /* Add this */
  justify-content: space-between; /* Add this */
  align-items: center; /* Add this */
  width: 100%; /* Add this */
}

.chat-limit-banner .close-banner {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--text-secondary);
  padding: 4px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.chat-limit-banner .close-banner:hover {
  opacity: 1;
}

.chat-limit-banner span {
  font-weight: 500;
  margin-right: 0px; /* Removed margin */
}

.chat-limit-banner .signup-link {
  display: inline-flex;
  align-items: center;
  margin-left: 0px; /* Removed margin */
  padding: 0.35rem 0.8rem;
  background: var(--primary-color);
  color: var(--text-light);
  text-decoration: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.85rem;
  white-space: nowrap;
  transition: all var(--transition-fast);
  box-shadow: 0 2px 8px var(--shadow-primary);
}

.chat-limit-banner .signup-link:hover {
  background: var(--primary-dark);
}

@media (max-width: 500px) {
  .chat-limit-banner {
    margin-left: var(--spacing-lg);
    margin-right: var(--spacing-lg);
    padding: var(--spacing-sm) var(--spacing-sm);
  }
}