.empty-thinking-display {
    display: flex;
    color: var(--primary-color);
    gap: var(--spacing-sm);
    padding: var(--spacing-md) var(--spacing-sm);
    cursor: default;
    transition: color var(--transition-base) var(--transition-bezier);
}

.empty-thinking-display:hover {
    color: var(--primary-dark);
}

.empty-thinking-display-text {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    letter-spacing: 0.01em;
    line-height: var(--line-height-tight);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}