* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-feature-settings: "liga" 1, "kern" 1, "calt" 1, "ss01" 1;
}

/* Heading Styles - More Imposing */
.ai-response-heading {
  font-weight: 600;
  line-height: 1.3;
  color: var(--text-primary);
}

/* Enhanced Header Sizes */
.ai-response-heading-1 { 
  font-size: calc(var(--font-size-2xl) * 1.1);
  font-weight: var(--font-weight-semibold);
  letter-spacing: -0.03em;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.ai-response-heading-2 { 
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.ai-response-heading-3 { 
  font-size: 1.5em;  /* Reduced from 1.6em */
  text-align: left; 
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}

.ai-response-heading-4 { 
  font-size: 1.25em;  /* Reduced from 1.3em */
  text-align: left; 
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.ai-response-heading-5 { 
  font-size: 1.1em;  /* Reduced from 1.15em */
  text-align: left; 
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}

.ai-response-heading-6 { 
  font-size: 1em;  /* Reduced from 1.05em */
  text-align: left; 
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.ai-response-content {
  font-family: var(--font-primary);
  font-size: var(--font-size-sm); /* Reduced font size */
  line-height: var(--line-height-base); /* Reduced line height */
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-feature-settings: "liga" 1, "kern" 1, "calt" 1, "ss01" 1;
  z-index: 1;
  padding: 0 var(--spacing-xs);
}

.ai-response-content a {
  color: var(--primary-color);
  text-decoration: none;
  padding-bottom: 0.1em;
}

.ai-response-content a:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

/* Paragraph Spacing */
.ai-response-paragraph {
  margin-bottom: 1rem; /* Reduced margin */
  line-height: var(--line-height-relaxed); /* Reduced line height */
  color: var(--text-primary);
  font-size: var(--font-size-md); /* Reduced font size */
}

/* Strong Text */
.ai-response-strong {
  font-weight: var(--font-weight-extrabold);
  color: var(--primary-dark);
  text-shadow: var(--text-shadow-base);
  margin-left: -0.15rem;
}

/* Consolidated List Styles */
.ai-response-list,
.ai-response-ordered-list,
.ai-response-unordered-list {
  list-style-position: outside;
  line-height: var(--line-height-base); /* Reduced line height */
  margin: 0;
  padding-left: 1.5em; /* Reduced padding */
}

.ai-response-ordered-list {
  list-style-type: decimal;
}

.ai-response-unordered-list {
  list-style-type: disc;
}

/* List Item Styles */
.ai-response-list-item {
  display: list-item;
  margin-bottom: 0.2em; /* Reduced margin */
  padding-left: 0.4rem; /* Reduced padding */
}

/* Nested List Styles */
.ai-response-list .ai-response-list,
.ai-response-ordered-list .ai-response-ordered-list,
.ai-response-unordered-list .ai-response-unordered-list {
  margin: 0.5rem 0 0.5rem 0.75rem; /* Reduced margin */
}

/* List Content Wrapper */
.ai-response-list-item-content {
  display: inline;
  font-size: var(--font-size-md); /* Reduced font size */
  font-weight: var(--font-weight-medium);
  white-space: normal;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .ai-response-list,
  .ai-response-ordered-list,
  .ai-response-unordered-list {
    padding-left: 1.2em; /* Reduced padding */
  }
  
  .ai-response-list .ai-response-list,
  .ai-response-ordered-list .ai-response-ordered-list,
  .ai-response-unordered-list .ai-response-unordered-list {
    margin-left: 0.4em; /* Reduced margin */
  }
}

.ai-response-blockquote {
  margin: var(--spacing-lg) 0;
  padding-left: var(--spacing-md);
  border-left: 3px solid var(--primary-color);
  color: var(--text-secondary);
  font-style: italic;
  line-height: var(--line-height-relaxed);
}

/* Horizontal Rule */
.ai-response-hr {
  border: none;
  height: 1px;
  background-color: var(--text-tertiary);
  margin: 3.5rem 0;
  width: 100%;
}

@media (max-width: 760px) {
  .ai-response-hr {
    margin-left: -2rem;
    width: 110%;
  }
}

.generating-icon {
  animation: rotate-spinner 1.5s linear infinite, pulse 1.5s ease-in-out infinite;
  color: var(--primary-color);
  transform-origin: center;
}

.loading-indicator {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: var(--spacing-sm);
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

/* Media query for high-resolution mobile displays */
@media (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2), 
     (max-width: 768px) and (min-resolution: 192dpi) {
  body {
    -webkit-font-smoothing: antialiased; /* Changed from subpixel-antialiased */
  }
  
  .ai-response-heading,
  .ai-response-paragraph,
  .ai-response-strong,
  .ai-response-link,
  .ai-response-inline-code,
  .citation {
    text-shadow: none;
  }
}

.interrupted-message {
  background-color: var(--background-hover-secondary);
  width: 100%;
  border: 1px solid var(--border-primary);
  border-radius: var(--radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  margin: calc(var(--spacing-xs) * -1) 0 var(--spacing-sm);
  font-size: var(--font-size-xs);
  color: var(--primary-color);
  box-shadow: var(--shadow-base);
  transition: all var(--transition-base);
}

.interrupted-message p {
  margin: 0;
  line-height: var(--line-height-base);
}

.interrupted-message:hover {
  background-color: var(--background-hover-primary);
  box-shadow: var(--shadow-lg);
  border-color: var(--border-primary-hover);
}

/* Citation Styles */
.citations {
  display: block;
  margin: 1rem 0;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: 6px;
  box-shadow: 0 2px 6px var(--shadow-color);
  position: relative;
  overflow-x: auto;
}

.citations::before {
  content: '§';
  position: absolute;
  top: 0.5em;
  left: -1em;
  font-size: 1.5em;
  color: var(--primary-color);
  opacity: 0.6;
}

.citation {
  display: inline-block;
  padding: 0.2em 0.4em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  background-color: var(--background-hover-primary);
  border-radius: var(--radius-sm);
  font-family: var(--font-mono);
  font-size: 0.95em;
  color: var(--primary-color);
  transition: background-color var(--transition-base);
  font-weight: var(--font-weight-medium);
  text-shadow: var(--text-shadow-sm);
  border: 1px solid var(--primary-light);
  font-feature-settings: "tnum" 1;
}

.citation:hover {
  background-color: var(--background-hover-secondary);
  cursor: pointer;
}
.ai-response-list {
  padding-left: 1.8em;
  margin: 1em 0;
  line-height: var(--line-height-loose);
}

/* Adjust nested list styles */
.ai-response-sublist {
  padding-left: 1.5rem;
  margin: 0.25rem 0;
}

.ai-response-list-item {
  margin-bottom: 0.3em;
}

.ai-response-inline-code {
  display: inline;
  background-color: var(--background-light);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-md);
  font-family: var(--font-mono);
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xs);
  color: var(--primary-dark);
  border: 1px solid var(--border-interactive);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  letter-spacing: var(--letter-spacing-tight);
  transition: 
    background-color var(--transition-fast),
    border-color var(--transition-fast),
    color var(--transition-fast),
    transform var(--transition-fast);
}

.ai-response-inline-code:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: translateY(-1px);
}
/* Table Styles */
.table-wrapper {
  overflow-x: auto;
  margin: var(--spacing-xl) 0;
  border-radius: var(--radius-lg);
  box-shadow: 0 2px 8px var(--shadow-color);
  border: 1px solid var(--border-color);
  transition: all var(--transition-base) var(--transition-bezier);
}

.ai-response-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: var(--background-light);
  font-family: var(--font-primary);
}

.ai-response-table th,
.ai-response-table td {
  padding: var(--spacing-md) var(--spacing-lg);
  text-align: left;
  border-bottom: 1px solid var(--border-light);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-snug);
  transition: background-color var(--transition-fast) var(--transition-bezier);
}

.ai-response-table th {
  font-weight: var(--font-weight-semibold);
  background-color: var(--background-main);
  letter-spacing: var(--letter-spacing-tight);
  text-transform: uppercase;
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.ai-response-table tr:last-child td {
  border-bottom: none;
}

.ai-response-table tbody tr {
  transition: all var(--transition-fast) var(--transition-bezier);
}

.ai-response-table tbody tr:hover {
  background-color: var(--background-hover);
}

.ai-response-table th:first-child {
  border-top-left-radius: var(--radius-md);
}

.ai-response-table th:last-child {
  border-top-right-radius: var(--radius-md);
}

.ai-response-table tr:last-child td:first-child {
  border-bottom-left-radius: var(--radius-md);
}

.ai-response-table tr:last-child td:last-child {
  border-bottom-right-radius: var(--radius-md);
}

@media (max-width: 468px) {
  .table-wrapper {
    margin: var(--spacing-lg) 0;
    border-radius: var(--radius-md);
  }
  
  .ai-response-table th,
  .ai-response-table td {
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--font-size-xs);
  }
}