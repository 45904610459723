.text-toolbar {
    position: absolute;
    background-color: var(--glass-background);
    backdrop-filter: var(--blur-md);
    border-radius: var(--radius-5xl);
    padding: var(--spacing-xs) var(--spacing-sm);
    z-index: var(--z-tooltip);
    border: 1px solid var(--border-glass);
    gap: var(--spacing-sm);
    max-width: 250px;
    transition: all var(--transition-base);
    border: 1px solid var(--border-color);
}

.text-toolbar-top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: var(--spacing-xs) 0;
}

.text-toolbar-top.not-editing {
    border-bottom: none;
    padding-bottom: var(--spacing-sm);
}

.text-toolbar-top svg {
    color: var(--primary-color);
    transition: color var(--transition-fast);
}

.text-toolbar-separator {
    width: 1px;
    background-color: var(--border-color);
    height: 1.5rem;
    margin: 0 var(--spacing-xs);
}

.text-toolbar-bottom {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs);
    align-items: center;
    overflow-x: auto;
    width: 100%;
    padding: var(--content-spacing-sm);
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.text-toolbar-bottom::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.text-toolbar-button {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-xs);
    padding: var(--spacing-sm);
    border-radius: var(--radius-md);
    transition: background-color var(--transition-fast), transform var(--transition-fast);
    color: var(--text-primary);
    font-weight: var(--font-weight-medium);
}

.text-toolbar-button.top {
    border-radius: var(--radius-xl);
}

.text-toolbar-button:hover {
    background-color: var(--background-hover);
    transform: var(--button-hover-transform);
}

.text-toolbar-button:active {
    transform: var(--button-active-transform);
}

.text-toolbar-improve {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm);
    border-radius: var(--radius-md);
    border: none;
    transition: border-color var(--transition-fast);
    padding-right: var(--spacing-md);
}

.text-toolbar-improve-input {
    flex-grow: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: var(--font-size-xs);
    color: var(--text-primary);
    font-family: var(--font-primary);
    line-height: var(--line-height-snug);
    min-width: 200px;
}

.text-toolbar-improve-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xs);
    border-radius: var(--radius-md);
    color: var(--primary-color);
    transition: background-color var(--transition-fast), transform var(--transition-fast);
    margin-right: 1rem;
}

.text-toolbar-improve-button:hover {
    transform: var(--button-hover-transform);
}

.text-toolbar-improve-button:active {
    transform: var(--button-active-transform);
}
