/* Code Display Styles */

/* Base styles for the code display container */
.modern-code-display {
    font-family: var(--font-mono);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
    letter-spacing: var(--letter-spacing-tight);
    margin: 1.5rem 0;
}

/* Variations of the code display container */
.modern-code-display.snippet {
    margin-bottom: var(--spacing-sm);
    margin-top: 0;
    position: relative;
}

.modern-code-display.with-header {
    margin: 0;
    border: none;
}

/* Styles for the code header */
.code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-lg);
    font-family: var(--font-primary);
    position: sticky;
    top: 0;
    z-index: var(--z-sticky);
}

/* Styles for the code language text in the header */
.code-language {
    text-transform: uppercase;
    color: var(--text-primary);
    font-weight: var(--font-weight-normal);
    letter-spacing: var(--letter-spacing-wide);
    font-size: var(--font-size-xs);
}

/* Styles for the standalone copy button */
.standalone-copy-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: var(--z-sticky);
    padding: 6px;
    opacity: 0.8;
    border-radius: var(--radius-md);
    margin-right: var(--spacing-xs);
    transition: opacity var(--transition-base);
}

.standalone-copy-button:hover {
    background-color: var(--background-hover);
    opacity: 1;
}

.code-header-button {
    border-radius: var(--radius-md);
    padding: var(--spacing-sm) var(--spacing-md);
    color: var(--text-primary);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-normal);
    border: none;
    cursor: pointer;
}

.code-header-button:hover {
    background-color: var(--background-hover);
}
