/* Base layout */
.university-promo {
    min-height: 100vh;
    padding: 4rem 2rem;
    background: var(--background-main);
    gap: 4rem;
    max-width: 1400px;
    margin: 0 auto;
}

/* Header section */
.promo-header {
    text-align: center;
    margin: 0 auto 4rem;
    max-width: 800px;
    animation: fadeIn 0.5s ease-out;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
}

.university-promo .logo {
    width: auto;
    height: 100px;
    margin-bottom: 2.5rem;
    border-radius: var(--radius-lg);
    pointer-events: none;
}

.university-promo .logo.school {
    background-color: aliceblue;
}

.pricing-toggle.promo {
    max-width: 500px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 3rem;

}

.promo-header h1 {
    font-size: 3.5rem;
    font-weight: 800;
    background: linear-gradient(135deg, var(--pricing-primary), var(--error-color));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.5rem;
    position: relative;
}

.promo-header h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 4px;
    background: var(--primary-gradient);
    border-radius: 2px;
}

.subtitle {
    font-size: var(--font-size-xl);
    color: var(--text-secondary);
    margin-bottom: 2rem;
}

/* Offer card styling */
.promo-hero {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
}

.offer-card {
    background: linear-gradient(
        145deg,
        rgba(var(--background-main-rgb), 0.8),
        rgba(var(--background-secondary-rgb), 0.9)
    );
    backdrop-filter: blur(12px);
    border-radius: var(--radius-2xl);
    padding: 3.5rem;
    max-width: 500px;
    width: 100%;
    border: 1px solid var(--border-glass);
    box-shadow: var(--shadow-elevated);
    transform: translateY(0);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.offer-card:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.1),
        0 0 0 1px var(--primary-color);
}

.offer-badge {
    position: absolute;
    top: -1rem;
    right: 2rem;
    background: var(--primary-gradient);
    color: var(--text-light);
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
    box-shadow: 0 4px 6px var(--shadow-primary);
}

.offer-card h2 {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
    margin-bottom: 2rem;
    text-align: center;
}

/* Price block */
.price-block {
    text-align: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: var(--background-hover);
    border-radius: var(--radius-xl);
}

.original-price {
    font-size: var(--font-size-lg);
    color: var(--text-tertiary);
    text-decoration: line-through;
    margin-bottom: 0.5rem;
}

.trial-price {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--success-color);
}

/* Features list */
.features-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem 0;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-primary);
    font-size: var(--font-size-base);
}

.university-promo .feature-icon {
    color: var(--primary-color);
}

/* Footer section */
.promo-footer {
    text-align: center;
    margin-top: 2rem;
}

.footer-cta {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 2rem;
    background: var(--primary-gradient);
    color: var(--text-light);
    border: none;
    border-radius: var(--radius-xl);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    transition: all var(--transition-base);
}

.footer-cta:hover {
    transform: var(--button-hover-transform);
    box-shadow: 0 4px 12px var(--shadow-primary);
}

.footer-note {
    margin-top: 1rem;
    color: var(--text-tertiary);
    font-size: var(--font-size-sm);
}

/* Responsive design */
@media (max-width: 768px) {
    .university-promo {
        padding: 2rem 1rem;
        gap: 2rem;
    }

    .promo-header h1 {
        font-size: var(--font-size-2xl);
    }

    .subtitle {
        font-size: var(--font-size-base);
        margin-bottom: 1.5rem;
    }

    .offer-card {
        padding: 2rem 1.5rem;
        margin: 0 1rem;
    }

    .university-logo {
        height: 60px;
        margin-bottom: 1.5rem;
    }

    .price-block {
        padding: 1rem;
    }

    .feature-item {
        font-size: var(--font-size-sm);
    }

    .footer-cta {
        padding: 0.75rem 1.5rem;
        font-size: var(--font-size-base);
    }
}

@media (max-width: 480px) {
    .offer-badge {
        right: 50%;
        transform: translateX(50%);
        font-size: var(--font-size-xs);
        padding: 0.4rem 1rem;
    }

    .footer-cta {
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        max-width: 300px;
    }

    .university-logos {
        gap: 0.5rem;
    }

    .university-logo {
        min-width: 60px;
        min-height: 40px;
        margin: 0 8px;
    }
}

/* Add new animations */
@keyframes fadeInUp {
    from { 
        opacity: 0; 
        transform: translateY(30px); 
    }
    to { 
        opacity: 1; 
        transform: translateY(0); 
    }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.used-by-students {
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 0rem;
}

.used-by-students h3 {
    font-size: var(--font-size-xl);
    color: var(--text-dark);
    margin-bottom: var(--spacing-lg);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-wider);
}

.university-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.university-logo {
    height: 70px;
    min-width: 120px;
    min-height: 90px;
    max-height: 120px;
    margin: 0 15px;
    opacity: 1;
    transition: opacity 0.3s ease;
    border-radius: var(--radius-lg);
}

@media (max-width: 768px) {
    .university-logo {
        min-width: 80px;
        min-height: 50px;
        max-height: 100px;
    }

    .university-promo .logo {
        height: 50px;
    }
}

[data-theme='dark'] .university-logo {
    background-color: aliceblue;
}

/* Add smooth animation for mobile menu transitions */
@media (max-width: 768px) {
    .offer-card {
        transition: transform 0.3s ease;
    }

    .offer-card:hover {
        transform: none;
    }

    /* Improve touch targets */
    .feature-item,
    .footer-cta {
        min-height: 44px;
    }
}

