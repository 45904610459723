.branch-toggle {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
  margin: auto 0;
}

.branch-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.branch-button:hover:not(:disabled) {
  background-color: var(--background-tertiary);
}

.branch-button:disabled {
  color: var(--text-disabled);
  cursor: not-allowed;
  opacity: 0.6;
}

.branch-counter {
  display: flex;
  align-items: center;
  margin: 0 6px;
  font-size: 14px;
  color: var(--text-primary);
}

.branch-separator {
  margin: 0 4px;
  color: var(--text-secondary);
}


