.file-card {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  background-color: var(--background-main);
  border: 1px solid var(--border-color);
  margin: 0 var(--spacing-sm);
  border-radius: var(--radius-2xl);
  font-size: var(--font-size-base);
  max-width: 300px;
  flex-shrink: 0;
  transition: all var(--transition-base);
}

.file-card:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
  cursor: pointer;
}

.file-icon {
  color: var(--primary-color);
  font-size: var(--font-size-xs);
  flex-shrink: 0;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-primary);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.remove-file {
  background: none;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  padding: var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--transition-fast);
}

.remove-file:hover {
  color: var(--error-color);
  transform: var(--button-hover-transform);
}