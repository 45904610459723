/* Global styles */
html, body {
  height: 100vh;
  height: 100dvh;
  min-height: -webkit-fill-available;
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
}

/* Layout */
.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback */
  height: 100dvh;
  min-height: -webkit-fill-available;
  background-color: var(--background-main);
  width: 100%;
  color: var(--text-primary);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat-area {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: 
    width 0.25s ease,
    margin-left 0.25s ease,
    margin-right 0.25s ease;
  will-change: transform;
  height: 93.5%;
  z-index: 1000;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
  -webkit-scrollbar-width: none; /* Arc/Webkit */
}

.chat-area::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Opera/Arc */
  width: 0; /* Arc */
}

@media (max-width: 768px) {
  .chat-area.sidebar-open{
    margin-left: 0;
    opacity: 0.7;
  }
}


@media (min-width: 768px) {
  .chat-area.sidebar-open{
    margin-left: 250px;
  }
}

.study-view-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-right: 0;
  transition: 
      margin-right 0.3s ease,
      width 0.3s ease;
}

.study-view-container.resources-open {
  margin-right: var(--resources-width-medium);
  width: var(--chat-width-medium);
}

.study-view-container.resources-expanded {
  margin-right: var(--resources-width-expanded);
  width: var(--chat-width-expanded);
}

.study-view-container.resources-closed {
  margin-right: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .study-view-container {
      margin-right: 0;
      width: 100%;
  }

  .study-view-container.resources-open,
  .study-view-container.resources-expanded {
      margin-right: 0;
      width: 100%;
  }
}

.logo-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.resources-count {
  position: absolute;
  top: -3px;
  right: 5px;
  font-size: 0.7rem;
  background: var(--accent-primary);
  color: var(--text-primary);
  padding: 2px 5px;
  border-radius: 10px;

  min-width: 18px;
  text-align: center;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px) translateZ(0); }
  to { opacity: 1; transform: translateY(0) translateZ(0); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


/* Media Queries */
@media (max-width: 768px) {
  .sidebar.open {
    left: 0;
  }

  .si{
    width: 100%;
  }

  .logo-title {
    font-size: 1.15rem;
  }
}

@media (max-width: 650px) {
  .sidebar {
    width: 275px;
    left: -275px;
  }
}

/* Animations */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Mobile Responsive */
@media (max-width: 568px) {
  .signup-link {
    padding: 0.3rem 0.8rem;
    font-size: 0.85rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0) translateZ(0);
  }
}

.edit-input {
  width: 100%;
  min-height: 10px;
  max-height: 550px; /* Match the max height from adjustEditTextareaHeight */
  padding: 6px 6px;
  border: none;
  border-radius: 18px 18px 0 18px;
  font-family: var(--user-primary);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  resize: none; /* Remove the resize handle */
  color: var(--text-dark);
  background-color: transparent;
  transition: all var(--transition-base);
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
  border: none;
  outline: none; /* Remove the blue outline on focus */
}

@media (max-width: 468px) {
  .edit-input {
    font-size: 16px;
  }
}

.auto-expand {
  overflow: hidden;
  resize: none;
  height: auto;
}

.hover-text {
  position: absolute;
  background: var(--tooltip-bg);
  color: var(--tooltip-text);
  padding: var(--spacing-sm) var(--spacing-sm);
  border-radius: var(--radius-md);
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  z-index: var(--z-tooltip);
  transform: translateY(5px) translateZ(0);
  transition: opacity var(--transition-fast) var(--ease-in-out),
              transform var(--transition-fast) var(--ease-in-out);
  box-shadow: 0 4px 12px var(--shadow-color);
  backdrop-filter: var(--blur-md);
  border: 1px solid var(--border-color);
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: var(--font-weight-medium);
  animation-fill-mode: forwards;
  font-size: var(--font-size-xs);
}

/* Hover text themes */
.hover-text.primary {
  background: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

.hover-text.secondary {
  background: var(--background-light);
  border-color: var(--border-color);
}

/* Position variants */
.hover-text.top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%) translateY(5px) translateZ(0);
}

.hover-text.bottom {
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%) translateY(-5px) translateZ(0);
}

.hover-text.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%) translateX(5px) translateZ(0);
}

.hover-text.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%) translateX(-5px) translateZ(0);
}

/* Special positioning for specific elements */
.menu-button .hover-text {
  margin-left: 1.8rem;
}

.hover-text.chat {
  top: 50px;
}

/* Show tooltip on hover */
*:hover > .hover-text {
  opacity: 1;
  transform: translateX(-50%) translateY(0) translateZ(0);
}

*:hover > .hover-text.left {
  transform: translateY(-50%) translateX(0) translateZ(0);
}

*:hover > .hover-text.right {
  transform: translateY(-50%) translateX(0) translateZ(0);
}

/* Add a subtle animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.web-indicator {
  display: flex;
  align-items: center;
  background-color: var(--background-hover-secondary);
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  color: var(--text-secondary);
  margin-left: 8px;
}

.web-indicator svg {
  margin-right: 4px;
}

/* Add these new styles for the chat controls toggle */
.chat-controls-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.chat-controls-toggle.active {
  background-color: var(--background-dark);
  padding: 3px;
  border-radius: 4px;
  color: var(--accent-color);
}


.chat-controls-toggle svg {
  width: 24px;
  height: 24px;
}

.imglogo {
  max-width: 60px;
  height: auto;
  margin-bottom: 1.5rem;
  transition: all 0.8s var(--transition-bezier);
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  animation: floatLogo 1.2s var(--transition-bezier) 0.3s forwards;
  font-size: 4rem;
  font-weight: 800;
  color: var(--primary-color);
  text-decoration: none;
  display: block;
  text-align: center;
  letter-spacing: -2px;
  position: relative;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.notexistslogo{
  width: 200px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes slideUp {
  from { transform: translate(-50%, 100%); }
  to { transform: translate(-50%, 0); }
}

.auth-disclaimer {
  position: relative;
  margin: 1rem 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--text-muted);
  padding: 0 var(--spacing-sm);
  z-index: 10;
}

.auth-disclaimer p {
  margin: 0;
  line-height: 1.3;
}

.link-wrapper {
  color: var(--primary-color);
  transition: color 0.2s var(--ease-out);
}

.link-wrapper:hover {
  color: var(--primary-dark);
}

/* Add this CSS class */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.default-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.chat-preview {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.chat-preview svg {
  color: var(--text-muted);
}

.chat-preview .preview-arrow {
  color: var(--text-tertiary);
}

.chat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.chat-title {
  font-weight: 500;
  color: var(--text-primary);
  font-size: 0.95rem;
}

.chat-date {
  font-size: 0.8rem;
  color: var(--text-muted);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .default-sections {
    padding: 0 1rem;
  }

  .chat-preview {
    padding: 0.75rem;
    width: 100%;
  }
}

/* Add this CSS class */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 568px) and (max-height: 800px) {
  .auth-prompt {
    position: fixed;
    bottom: 25%;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Animation for the processing icon */
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.space-chat-icon {
  color: var(--primary-color);
}

/* Add styles for the spaces link */
.spaces-link {
  color: var(--primary-color);
  font-size: 0.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.spaces-link:hover {
  background-color: var(--background-hover-primary);
  transform: translateY(-1px);
}

.spaces-link svg {
  width: 14px;
  height: 14px;
}
.loading-logo {

  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.spinning-logo {
  width: 24px;
  height: 24px;
  animation: spin 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rounded-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mini Loading Display */
.mini-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}

.mini-loading-circle {
  width: 24px;
  height: 24px;
  border: 2px solid var(--background-light);
  border-top: 2px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .mini-loading {
    width: 20px;
    height: 20px;
  }

  .mini-loading-circle {
    width: 20px;
    height: 20px;
    border-width: 1.5px;
  }
}


/* Simple fade-in animation for the overlay */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}