
html, body {
  background-color: var(--background-main);
  margin: 0;
  padding: 0;
}

:root {
  background-color: var(--background-main);
}

/* Error Boundary styling */
.error-boundary-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: var(--text-primary);
  text-align: center;
  padding: 20px;
}

.error-boundary-fallback a {
  color: var(--primary-color);
}

.error-boundary-fallback a:hover {
  color: var(--primary-dark);
}

.error-boundary-fallback button {
  background-color: var(--background-main);
  color: var(--text-primary);
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  border: 1px solid var(--border-color);
}

.error-boundary-fallback button:hover {
  background-color: var(--background-light);
}

.error-boundary-fallback button:active {
  transform: scale(0.98);
}


.error-boundary-fallback-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}