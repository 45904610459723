/* Arrow Icon Styles */
.arrow-icon {
  transition: transform var(--transition-fast);
  color: var(--text-secondary);
}

.arrow-icon.rotated {
  transform: rotate(180deg);
}

/* Dropdown Base Styles */
.mode-dropdown {
  position: absolute;
  top: calc(100% + var(--spacing-xs));
  left: 0;
  background: var(--dropdown-bg);
  backdrop-filter: var(--blur-md);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-elevated);
  border: 1px solid var(--border-color);
  z-index: var(--z-tooltip);
  width: 210px;
  padding: var(--spacing-sm);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95) translateY(-8px);
  transition: all var(--transition-base) var(--ease-out);
  max-height: 350px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Dropdown Position Variants */
.mode-dropdown.chat {
  top: auto;
  bottom: calc(100% + var(--spacing-sm));
}

.mode-dropdown.regenerate-dropdown {
  top: auto;
  bottom: 30px;
  left: auto;
}


.mode-dropdown.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1) translateY(0);
  animation: dropdownFadeIn 0.25s var(--ease-out) forwards;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-8px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Dropdown Title */
.mode-dropdown-title {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  padding: var(--spacing-xs) var(--spacing-md);
  letter-spacing: var(--letter-spacing-wide);
  text-align: left;
  position: sticky;
  top: 0;
  background: var(--dropdown-bg);
  z-index: 1;
}

/* Mode Option Styles */
.mode-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-xs) var(--spacing-md);
  margin: var(--spacing-xs) 0;
  color: var(--dropdown-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
  cursor: pointer;
  gap: var(--spacing-xs);
  border-radius: var(--radius-lg);
  position: relative;
  overflow: visible; /* Changed from hidden to visible to prevent text cutoff */
  border: 1px solid transparent;
  height: auto;
  white-space: normal; /* Ensures text wraps properly */
  word-break: break-word; /* Helps with long words */
  max-width: 100%; /* Ensures content doesn't overflow */
}

.mode-title {
  text-align: left;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  color: var(--text-primary);
  transition: color var(--transition-fast);
}

.mode-description {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Mode Option States */
.mode-option:hover {
  background: var(--dropdown-hover);
  color: var(--dropdown-text-hover);
  box-shadow: var(--shadow-sm);
}

.mode-option:active {
  transform: translateY(1px);
}

.mode-option.active {
  color: var(--text-dark);
  box-shadow: var(--shadow-primary);
  font-weight: var(--font-weight-medium);
  border: 1px solid var(--border-color);
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Tablet styles */
}

@media (max-width: 480px) {
  /* Mobile styles */
  .input-area-pill.bar {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: calc(var(--font-size-xs) - 1px);
  }
  
  .input-area-pill-text {
    max-width: 100px;
  }
  
  .mode-dropdown {
    left: 0;
    transform-origin: top left;
  }
}
