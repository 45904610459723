/* Comparison Modal Styles */
.comparison-modal {
    position: absolute;
    inset: 0;
    z-index: 105;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .comparison-modal-content {
    background-color: var(--background-main);
    width: 90vw;
    height: 100%;
    padding: var(--spacing-md);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
  }
  
  .comparison-panels {
    display: flex;
    gap: var(--spacing-md);
    flex: 1;
    overflow: hidden;
  }
  
  .comparison-panel {
    flex: 1;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-md);
    overflow-y: auto;
    cursor: pointer;
    transition: border-color var(--transition-base);
  }
  
  .comparison-panel:hover {
    border-color: var(--primary-color);
  }
    
  .comparison-message {
    padding: var(--spacing-sm);
    margin: var(--spacing-sm) 0;
    border-radius: var(--radius-md);
  }

  .slider-dots {
    display: none;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .comparison-modal{
        height: 100%;
        width: 100vw;
        padding: 0;
    }

    .comparison-modal-content {
      width: 98vw;
      overflow: hidden;
      margin-bottom: auto;
    }
  
    .comparison-panels {
      flex-direction: row;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */
    }

    .comparison-panels::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  
    .comparison-panel {
      scroll-snap-align: start;
      min-width: 100%;
      max-height: none;
      overflow-y: auto;
    }

    .slider-dots {
      display: flex;
      justify-content: center;
      gap: var(--spacing-xs);
      padding: var(--spacing-md) 0;
      position: relative;
    }

    .dot {
      width: var(--spacing-xs);
      height: var(--spacing-xs);
      border-radius: 50%;
      background-color: var(--border-color);
      transition: background-color var(--transition-base);
    }

    .dot.active {
      background-color: var(--primary-color);
    }
  }