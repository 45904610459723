.study-space-page {
  background-color: var(--background-main);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  padding-top: var(--spacing-xs);
}

.study-space-page .study-space-header {
  padding: 1.25rem 2.5rem;
  border-bottom: 1px solid var(--border-light);
  background-color: var(--background-main);
  box-shadow: 0 1px 3px var(--shadow-color);
}

.study-space-page .back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 6px;
}

.study-space-page .back-button:hover {
  color: var(--text-primary);
  transform: translateX(-2px);
}

.study-space-page .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.study-space-page .header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.study-space-page .study-space-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  background-color: var(--background-main);
  padding: 1.5rem 2.5rem;
  gap: 2rem;
}

.study-space-page .space-sidebar {
  width: 360px;
  border-radius: 16px;
  border: 1px solid var(--border-light);
  background-color: var(--background-main);
  overflow-y: auto;
  padding: 1.5rem;
  box-shadow: 0 4px 12px var(--shadow-color);
  height: fit-content;
  max-height: calc(100vh - 180px);
}

.study-space-page .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding: 0 0.25rem;
}

.study-space-page .section-header h2 {
  font-size: 1.15rem;
  color: var(--text-primary);
  margin: 0;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.study-space-page .instructions-section,
.study-space-page .files-section {
  margin-bottom: 2rem;
}

.study-space-page .instructions-content {
  font-size: 0.95rem;
  color: var(--text-secondary);
  line-height: 1.7;
  max-height: 600px;
  overflow-y: auto;
  padding: 1.25rem;
  background: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: 12px;
  box-shadow: var(--shadow-color);
  transition: all 0.2s ease;
}

.study-space-page .instructions-content::-webkit-scrollbar {
  width: 2px;
}

.study-space-page .instructions-content::-webkit-scrollbar-track {
  background: var(--background-light);
  border-radius: 4px;
}

.study-space-page .instructions-content::-webkit-scrollbar-thumb {
  background: var(--border-light);
  border-radius: 4px;
}

.study-space-page .instructions-content::-webkit-scrollbar-thumb:hover {
  background: var(--border-color);
}

.study-space-page .instructions-editor-container {
  background: var(--background-main);
  border-radius: 16px;
  border: 1px solid var(--border-light);
  box-shadow: var(--shadow-color);
  transition: all 0.2s ease;
  overflow: hidden;
}

.study-space-page .instructions-editor-container:focus-within {
  border-color: var(--border-color);
}

.study-space-page .instructions-editor {
  width: 100%;
  min-height: 120px;
  max-height: 400px;
  padding: 1.25rem;
  border: none;
  font-size: 0.95rem;
  line-height: 1.7;
  color: var(--text-primary);
  background-color: transparent;
  transition: all 0.2s ease;
  font-family: inherit;
  resize: none;
  overflow-y: auto;
}

.study-space-page .instructions-editor:focus {
  outline: none;
}

.study-space-page .editor-actions {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
  padding: 1rem 1.25rem;
  background: var(--background-light);
  border-top: 1px solid var(--border-light);
}

.study-space-page .cancel-button {
  padding: 0.625rem 1.25rem;
  border: 1px solid var(--border-light);
  border-radius: 12px;
  background: var(--background-main);
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-color);
}

.study-space-page .cancel-button:hover {
  background: var(--background-hover);
  border-color: var(--border-color);
  color: var(--text-primary);
  transform: translateY(-1px);
}

.study-space-page .cancel-button.save {
  padding: 0.625rem 1.5rem;
  border: none;
  background: var(--primary-gradient);
  color: var(--text-light);
  font-weight: 500;
  box-shadow: 0 4px 12px var(--shadow-primary);
}

.study-space-page .cancel-button.save:hover {
  filter: brightness(105%);
  transform: translateY(-1px);
  box-shadow: 0 6px 16px var(--shadow-primary-hover);
}

.study-space-page .cancel-button.save:active {
  transform: translateY(0);
  filter: brightness(95%);
}

.study-space-page .upload-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  color: var(--text-primary);
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all 0.2s var(--transition-bezier);
  box-shadow: var(--shadow-color);
}

.study-space-page .upload-button:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-primary);
}

.study-space-page .empty-files-state {
  text-align: center;
  padding: 2rem;
  color: var(--text-tertiary);
  background: var(--background-light);
  border-radius: 8px;
  border: 2px dashed var(--border-light);
}

.study-space-page .empty-files-state p {
  margin: 0;
  font-size: 0.9rem;
}

.study-space-page .chats-section {
  flex: 1;
  padding: 1.75rem;
  overflow-y: auto;
}

.study-space-page .create-chat-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  color: var(--text-light);
  background: var(--primary-gradient);
  border: none;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px var(--shadow-primary);
}

.study-space-page .create-chat-btn:hover {
  filter: brightness(105%);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px var(--shadow-primary-hover);
}

.study-space-page .chats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
  margin-top: 1.25rem;
}
.study-space-page .chat-card {
  background: var(--glass-background);
  border: 1px solid var(--border-light);
  transition: all var(--transition-base) var(--transition-bezier);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  cursor: pointer;
  height: 160px;
  display: flex;
  flex-direction: column;
  position: relative;
  backdrop-filter: var(--blur-sm);
}

.study-space-page .chat-card:hover {
  border-color: var(--border-color);
}

.study-space-page .chat-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.study-space-page .chat-card-header-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  padding-right: var(--spacing-xl);
}

.study-space-page .chat-card-header-left svg {
  color: var(--text-primary);
  align-self: flex-start;
  min-width: 18px;
  margin: auto 0;
}

.study-space-page .chat-card-header-left h3 {
  margin: 0;
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-snug);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: calc(var(--font-size-sm) * var(--line-height-snug) * 2);
}

.study-space-page .chat-card-footer {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  font-size: 0.8rem;
  color: var(--text-secondary);
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-light);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .study-space-page .study-space-content {
    flex-direction: column;
  }

  .study-space-page .space-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-light);
  }

  .study-space-page .chats-grid {
    grid-template-columns: 1fr;
  }

  .study-space-page .chat-card {
    height: 120px;
  }
}

.study-space-page .empty-chats-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 2rem;
  border-radius: 16px;
  margin: 1rem;
}

.study-space-page .empty-chats-content {
  text-align: center;
  max-width: 360px;
  animation: fadeIn 0.5s ease-out;
}

.study-space-page .empty-chats-content p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  line-height: 1.6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.study-space-page .resources-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;
}

.editable-title {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-sm);
  transition: all var(--transition-fast);
}

.editable-title .edit-icon {
  opacity: 0;
  color: var(--primary-color);
  transition: all 0.2s ease;
}

.editable-title:hover .edit-icon {
  opacity: 1;
  transform: translateX(2px);
}

.title-editor {
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--background-main);
  border-radius: var(--radius-lg);
  padding: 4px;
  box-shadow: var(--shadow-color);
  border: 1px solid var(--border-light);
  transition: all 0.2s ease;
}

.title-editor input {
  font-size: 1.75rem;
  font-weight: 600;
  padding: 8px 12px;
  border: none;
  background: transparent;
  color: var(--text-primary);
  width: 100%;
  min-width: 200px;
  letter-spacing: -0.02em;
}

.title-editor input:focus {
  outline: none;
}

/* Mobile Goals & Objectives Dropdown */
@media (max-width: 768px) {
  .study-space-page .instructions-section {
    background: var(--background);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-sm);
    margin-bottom: 1rem;
  }

  .study-space-page .instructions-section .section-header {
    cursor: pointer;
    padding: 1rem 0;
    margin-bottom: 0;
    transition: background-color 0.2s ease;
  }

  .study-space-page .instructions-section .section-header h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .study-space-page .instructions-section .section-header h2::after {
    content: '';
    width: 10px;
    height: 10px;
    border-right: 2px solid var(--text-secondary);
    border-bottom: 2px solid var(--text-secondary);
    transform: rotate(45deg);
    transition: transform 0.2s ease;
  }

  .study-space-page .instructions-section[data-expanded="true"] .section-header h2::after {
    transform: rotate(-135deg);
  }

  .study-space-page .instructions-section .instructions-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 1rem;
    transition: all 0.3s ease;
  }

  .study-space-page .instructions-section[data-expanded="true"] .instructions-content {
    max-height: 500px;
    padding: 1rem;
    padding-top: 0;
    overflow-y: auto;
  }

  .study-space-page .study-space-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0.5rem 0.5rem;
    gap: 2rem;
  }
}

/* Mobile Header Improvements */
@media (max-width: 768px) {
  .study-space-page .study-space-header {
    padding: 0.75rem 1rem;
  }

  .study-space-page .header-content {
    flex-direction: column;
    gap: 0.75rem;
    padding: 0;
  }

  .study-space-page .header-left {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .study-space-page .back-button {
    margin-bottom: 0.5rem;
    padding: 0.375rem;
    font-size: 0.875rem;
  }

  .study-space-page .editable-title {
    width: 100%;
    padding: 0.5rem;
  }

  .study-space-page .title-editor {
    width: 100%;
  }

  .study-space-page .title-editor input {
    font-size: 1.25rem;
    padding: 0.5rem;
  }

  .study-space-page .title-editor-actions {
    padding-right: 4px;
  }

  .study-space-page .title-editor-actions button {
    padding: 4px 8px;
    font-size: 0.75rem;
  }
}

/* Additional improvements for very small screens */
@media (max-width: 380px) {
  .study-space-page .study-space-header {
    padding: 0.5rem;
  }

  .study-space-page .back-button {
    font-size: 0.8125rem;
  }

  .study-space-page .title-editor input {
    font-size: 1.125rem;
    min-width: 150px;
  }
}

.knowledge-tracker {
  padding: 12px 16px;
  background: var(--background-light);
  border-radius: 8px;
  margin-bottom: 16px;
}

.knowledge-tracker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.knowledge-tracker-bar {
  height: 6px;
  background: var(--background-hover);
  border-radius: 3px;
  overflow: hidden;
}

.knowledge-tracker-progress {
  height: 100%;
  background: #ef4444;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.knowledge-tracker-progress.full {
  background-color: #ff4444;
}