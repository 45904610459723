.mini-chat-suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);
    justify-content: center;
    align-items: center;
    padding: var(--spacing-lg) var(--spacing-sm);
}

.mini-chat-suggestion {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-3xl);
    padding: var(--spacing-md);
    cursor: pointer;
    gap: var(--spacing-sm);
    font-size: var(--font-size-xs);
    background-color: var(--background-light);
    color: var(--text-primary);
    transition: all var(--transition-base) var(--ease-out);
    box-shadow: var(--shadow-sm);
}

.mini-chat-suggestion:hover {
    background: var(--background-hover);
    border-color: var(--border-interactive-hover);
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-primary-hover);
}

.mini-chat-suggestion:active {
    transform: var(--button-active-transform);
}

@media (max-width: 768px) {
    .mini-chat-suggestion {
        padding: var(--spacing-sm) var(--spacing-md);
    }
}