.action-bar-content {
    flex: 1;
    overflow-y: auto;
    padding: var(--spacing-md);
}

.playground-action-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    border-radius: var(--radius-lg);
    background: var(--background-main);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all var(--transition-base);
    margin-bottom: var(--spacing-md);
}

.playground-action-item:hover {
    background: var(--background-hover);
    border-color: var(--border-interactive-hover);
    transform: translateY(-1px);
    box-shadow: var(--shadow-elevated);
}

.playground-action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: var(--font-size-lg);
}

.playground-action-info {
    flex: 1;
}

.playground-action-title {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
    margin: 0;
    margin-bottom: var(--spacing-xs);
}

.playground-action-info p {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    margin: 0;
}

.playground-action-arrow {
    color: var(--text-tertiary);
    transition: transform var(--transition-base);
}

.playground-action-item:hover .playground-action-arrow {
    transform: translateX(4px);
    color: var(--primary-color);
}