.scroll-to-bottom {
    position: absolute;
    right: 50%;
    bottom: 105%;
    background: var(--glass-background);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-full);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all var(--transition-base) var(--ease-out);
    animation: fadeInFromBottom 0.3s var(--ease-out) forwards;
    z-index: var(--z-tooltip);
    font-size: var(--font-size-sm);
    box-shadow: var(--shadow-color);
    margin-bottom: 0px; /* Additional margin to ensure separation from input area */

}

@keyframes fadeInFromBottom {
    from {
        opacity: 0;
        transform: translateX(50%) translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateX(50%) translateY(0);
    }
}

@keyframes entrance {
    from {
        opacity: 0;
        transform: translateX(50%) translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateX(50%) translateY(0);
    }
}
  
.scroll-to-bottom.generating-response {
    animation: bounce 1s infinite var(--ease-in-out);
}
  
@keyframes bounce {
    0%, 100% {
        transform: translateX(50%) translateY(0);
    }
    50% {
        transform: translateX(50%) translateY(-5px); 
    }
}
  
@media (max-width: 650px) {
    .scroll-to-bottom {
        width: 32px;
        height: 32px;
    }
}
  
.scroll-to-bottom:hover {
    background: var(--background-hover);
    box-shadow: var(--shadow-elevated);
}

.scroll-to-bottom:active {
    transform: translateX(50%) var(--button-active-transform);
    transition-duration: var(--transition-fast);
}

/* New scroll spacer styling */
.scroll-spacer {
    transition: height 2s var(--ease-out);
    overflow: hidden;
    width: 100%;
    will-change: height;
    transform: translateZ(0); /* Force GPU acceleration for smoother transitions */
}