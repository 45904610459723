/* Resource item styles */
.resource-item {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  border-radius: var(--radius-xl);
  background: var(--background-light);
  transition: all var(--transition-base) var(--transition-bezier);
  cursor: pointer;
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
  margin-bottom: var(--spacing-sm);
}

.resource-item:hover {
  background: var(--background-hover);
  transform: var(--button-hover-transform);
  box-shadow: var(--shadow-primary), 0 4px 12px rgba(0, 0, 0, 0.05);
}

.resource-info {
  max-width: 70%;
}

.resource-action {
  margin-left: auto;
}

.resource-arrow {
  font-size: 0.7rem;
  color: var(--text-tertiary);
  transition: all var(--transition-fast);
  opacity: 0.7;
  flex-shrink: 0;
}

.resource-item:hover .resource-arrow {
  color: var(--text-primary);
}

.resource-item:active {
  transform: var(--button-active-transform);
  box-shadow: none;
}

.resource-item:hover::after {
  opacity: 1;
}

.resource-delete-button:hover {
  transform: scale(1.05);
  color: var(--primary-color);
}