.interruped-indicator {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-full);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-left: auto;
  box-shadow: 0 1px 2px var(--shadow-color);
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.interruped-indicator:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.interruped-indicator-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xs);
  letter-spacing: var(--letter-spacing-tight);
}

[data-theme="dark"] .interruped-indicator {
  background: var(--background-dark);
  border-color: var(--border-interactive);
}

[data-theme="dark"] .interruped-indicator:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
}
