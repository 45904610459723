.confirmation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: var(--spacing-lg);
  background: linear-gradient(135deg, var(--background-main) 0%, var(--background-light) 100%);
}

.confirmation-card {
  background: var(--glass-background);
  border-radius: var(--radius-2xl);
  border: 1px solid var(--border-primary);
  box-shadow: var(--shadow-elevated);
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 680px;
  animation: modalEnter 0.6s var(--ease-out) forwards;
  transform: scale(0.95);
  opacity: 0;
}

@keyframes modalEnter {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.confirmation-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: var(--spacing-xl);
  position: relative;
}

.success-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: var(--primary-gradient);
  color: var(--text-light);
  border-radius: 50%;
  margin-bottom: var(--spacing-md);
  box-shadow: 0 8px 16px var(--shadow-primary);
  animation: pulseIcon 2s infinite ease-in-out;
}

.plan-badge {
  background: var(--primary-gradient);
  color: var(--text-light);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  padding: 0.4rem 0.8rem;
  border-radius: var(--radius-full);
  margin-top: var(--spacing-sm);
  box-shadow: 0 4px 10px var(--shadow-primary);
  animation: fadeInUp 0.8s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseIcon {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 12px var(--shadow-primary);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 24px var(--shadow-primary-hover);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px var(--shadow-primary);
  }
}

.confirmation-header h1 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: var(--spacing-sm) 0;
}

.confirmation-subtitle {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.setup-progress-container {
  margin-bottom: var(--spacing-xl);
}

.setup-progress-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-sm);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.setup-progress-bar {
  height: 8px;
  background-color: var(--background-dark);
  border-radius: var(--radius-full);
  overflow: hidden;
}

.setup-progress-fill {
  height: 100%;
  background: var(--primary-gradient);
  border-radius: var(--radius-full);
  transition: width 0.4s ease-out;
}

.confirmation-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.plan-summary {
  background-color: var(--background-hover-primary);
  border-radius: var(--radius-xl);
  border: 1px solid var(--border-primary);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
}

.plan-summary h3 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

.plan-feature-item {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  line-height: var(--line-height-snug);
}

.feature-check {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 2px;
}

.getting-started {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.getting-started h3 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--background-light);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-light);
  transition: all var(--transition-base);
}

.feature-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
  border-color: var(--border-primary);
}

.confirmation-container .feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-hover-primary);
  color: var(--primary-color);
  width: 40px;
  height: 40px;
  border-radius: var(--radius-lg);
  flex-shrink: 0;
}

.confirmation-container .feature-text {
  flex: 1;
}

.feature-text h3 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.feature-text p {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-snug);
}

.redirect-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--border-light);
}

.redirect-info p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.countdown {
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.redirect-now-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-base);
  box-shadow: 0 4px 12px var(--shadow-primary);
  width: 100%;
  max-width: 300px;
}

.redirect-now-button:hover {
  background-color: var(--primary-dark);
  transform: var(--button-hover-transform);
  box-shadow: 0 6px 16px var(--shadow-primary-hover);
}

.redirect-now-button:active {
  transform: var(--button-active-transform);
}

@media (max-width: 768px) {
  .confirmation-card {
    padding: var(--spacing-lg);
  }

  .plan-features {
    grid-template-columns: 1fr;
  }

  .feature-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .feature-icon {
    margin-bottom: var(--spacing-sm);
  }
  
  .plan-feature-item {
    margin-bottom: var(--spacing-sm);
  }
}
