/* Add to Space Button Styles */
.add-to-space-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.add-to-space-button {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background: transparent;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s var(--ease-in-out);
  color: var(--text-secondary);
  font-size: 0.9rem;
  border: none;
}

.add-to-space-button svg {
  color: var(--primary-color);
}

.add-to-space-button:hover {
  background-color: var(--background-light);
  background: var(--background-hover);
  color: var(--primary-color);
  color: var(--text-primary);
  border-color: var(--primary-color);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(var(--primary-rgb), 0.1);
}

.add-to-space-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(var(--primary-rgb), 0.1);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .add-to-space-button {
    padding: 4px 8px;
    font-size: 0.85rem;
  }
}

.space-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: var(--background-main);
  border: 1px solid var(--border-light);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  box-shadow: 0 4px 20px var(--shadow-color);
  box-shadow: 0 2px 8px var(--shadow-color);
  min-width: 240px;
  overflow: hidden;
  z-index: 1000;
  animation: dropdownFade 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.space-dropdown.open {
  animation: dropdownFade 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.space-dropdown.closing {
  animation: dropdownFadeOut 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdownFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
  }
}

.space-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: var(--background-hover-primary);
  border-bottom: 1px solid var(--border-primary);
}

.space-dropdown-header span {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 0.9rem;
}

.spaces-link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--primary-color);
  font-size: 0.8rem;
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.spaces-link:hover {
  background: var(--background-hover-primary);
  transform: translateY(-1px);
}

.spaces-link svg {
  width: 14px;
  height: 14px;
  transition: transform 0.2s ease;
}

.spaces-link:hover svg {
  transform: translateX(2px);
}

.space-option {
  padding: 10px 16px;
  font-size: 0.9rem;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.space-option:hover {
  background: var(--background-hover-primary);
  color: var(--primary-color);
  padding-left: 20px;
}

.space-option::before {
  content: '';
  position: absolute;
  left: 0;
  width: 3px;
  height: 0;
  background: var(--primary-color);
  transition: height 0.2s ease;
}

.space-option:hover::before {
  height: 100%;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-8px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .space-dropdown {
    min-width: 200px;
  }

  .space-dropdown-header {
    padding: 10px 14px;
  }

  .space-option {
    padding: 8px 14px;
    font-size: 0.85rem;
  }

  .spaces-link {
    font-size: 0.75rem;
  }
}