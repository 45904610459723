.subscription-promo {
  position: relative;
  background: linear-gradient(135deg, var(--background-main) 0%, var(--background-hover-primary) 100%);
  border-radius: var(--radius-2xl);
  padding: var(--spacing-xl);
  margin: var(--spacing-xl) 0;
  border: 1px solid var(--border-primary);
  overflow: hidden;
  box-shadow: 0 10px 30px var(--shadow-primary);
  transition: transform var(--transition-base), box-shadow var(--transition-base);
}

.subscription-promo:hover {
  transform: translateY(-3px);
  box-shadow: 0 15px 40px var(--shadow-primary-hover);
}

.promo-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
}

.promo-badge {
  display: inline-block;
  background: var(--primary-gradient);
  color: var(--text-light);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xxs);
  padding: 0.4rem 0.8rem;
  border-radius: var(--radius-full);
  margin-bottom: var(--spacing-md);
  letter-spacing: var(--letter-spacing-wide);
  box-shadow: 0 4px 12px var(--shadow-primary);
  text-transform: uppercase;
}

.promo-title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-sm);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-tight);
}

.promo-description {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
  max-width: 90%;
  line-height: var(--line-height-base);
}

.subscription-promo .feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.feature-item {
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 0 var(--spacing-md);
  border-radius: var(--radius-md);
  background: rgba(var(--background-light-rgb), 0.1);
  border: 1px solid var(--border-light);
  transition: all var(--transition-base);
  position: relative;
  overflow: hidden;
}

.feature-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: var(--primary-color);
  opacity: 0;
  transition: opacity 0.2s var(--ease-out);
}

.feature-item:hover {
  transform: translateY(-1px);
  background: rgba(var(--background-light-rgb), 0.2);
  border-color: var(--border-primary-hover);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.feature-item:hover::before {
  opacity: 1;
}

.feature-item::after {
  content: '✓';
  color: var(--primary-color);
  font-weight: bold;
  margin-right: var(--spacing-sm);
  font-size: var(--font-size-xs);
  flex-shrink: 0;
}

.feature-item span {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.promo-pricing {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-lg);
  gap: var(--spacing-md);
}

.price-info {
  display: flex;
  align-items: baseline;
}

.price-value {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
}

.price-period {
  font-size: var(--font-size-sm);
  color: var(--text-tertiary);
  margin-left: var(--spacing-xs);
}

.annual-savings {
  font-size: var(--font-size-sm);
  color: var(--success-color);
  font-weight: var(--font-weight-medium);
}

.upgrade-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.875rem 1.75rem;
  background: var(--primary-gradient);
  color: var(--text-light);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  border-radius: var(--radius-lg);
  text-decoration: none;
  transition: all var(--transition-base);
  border: none;
  box-shadow: 0 6px 16px var(--shadow-primary);
}

.upgrade-button:hover {
  transform: var(--button-hover-transform);
  box-shadow: 0 8px 20px var(--shadow-primary-hover);
}

.upgrade-button:active {
  transform: var(--button-active-transform);
}

.button-arrow {
  width: 20px;
  height: 20px;
  margin-left: var(--spacing-sm);
  color: var(--text-light);
  transition: transform var(--transition-fast);
}

.upgrade-button:hover .button-arrow {
  transform: translateX(3px);
}

.promo-decoration {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
}

.decoration-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--primary-light) 0%, transparent 70%);
  opacity: 0.2;
  top: -100px;
  right: -100px;
}

.decoration-shape {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 40%;
  background: radial-gradient(circle, var(--primary-color) 0%, transparent 70%);
  opacity: 0.1;
  bottom: -150px;
  right: -150px;
  transform: rotate(20deg);
}

@media (max-width: 768px) {
  .subscription-promo {
    padding: var(--spacing-lg);
    border-radius: var(--radius-xl);
  }

  .promo-content {
    max-width: 100%;
  }

  .promo-title {
    font-size: var(--font-size-xl);
  }

  .promo-description {
    max-width: 100%;
  }

  .subscription-promo .feature-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-sm);
  }
  
  .feature-item {
    height: 40px; /* Slightly smaller height on mobile */
    width: 100%;
  }

  .promo-pricing {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }

  .upgrade-button {
    width: 100%;
  }

  .promo-decoration {
    display: none;
  }
}