.select-toggle {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 20px;
  transition: all 0.3s ease;
  gap: 8px;
  user-select: none;
}

.select-toggle input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  background-color: var(--background-hover);
  border-radius: 25px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--border-light);
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: var(--background-main);
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px var(--shadow-color);
}

.select-toggle input:checked + .toggle-slider {
  background-color: var(--text-primary);
  border-color: var(--text-primary);
}

.select-toggle input:checked + .toggle-slider:before {
  transform: translateX(14px);
  background-color: var(--background-main);
}

.select-toggle.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.select-toggle.disabled:hover {
  background: var(--background-light);
}
