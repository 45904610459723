.settings-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-modal);
  backdrop-filter: var(--blur-sm);
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.settings-modal {
  background: var(--background-main);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-elevated);
  width: 90%;
  max-width: 900px;
  height: min(800px, 90vh);
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  transform: scale(0.95);
  opacity: 0;
  animation: modalEnter 0.4s var(--ease-out) forwards;
  outline: none; /* For better accessibility */
}

@keyframes modalEnter {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.settings-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-sm);
}

.settings-modal-header-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.settings-modal-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  display: none;
}

.settings-modal-main {
  display: flex;
  gap: var(--spacing-lg);
  flex: 1;
}

.settings-modal-main-nav-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  justify-content: center;
  height: 90%;
}

.settings-modal-main-nav {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  justify-content: center;
  height: 100%;
  margin: auto 0;
}

.settings-modal-main-nav-wrapper-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: auto;
}

.help-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.help-circle:hover {
  transform: scale(1.05);
  transition: all var(--transition-base) var(--ease-out);
}

.settings-modal-main-nav-item {
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: var(--spacing-sm);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  border: 1px solid var(--border-color);
  min-width: 165px;
  transition: var(--transition-base);
  cursor: pointer;
  gap: var(--spacing-sm);
  box-shadow: var(--shadow-sm);
}

.settings-modal-main-nav-item:hover {
  background-color: var(--background-hover);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
  border-color: var(--border-interactive-hover);
}

.settings-modal-main-nav-item.active {
  background-color: var(--background-dark);
  border-color: var(--border-interactive);
  box-shadow: var(--shadow-md);
  font-weight: var(--font-weight-semibold);
}

.settings-modal-main-nav-item.logout {
  background-color: var(--error-color);
  color: var(--text-light);
  transition: var(--transition-base);
}

.settings-modal-main-nav-item.logout:hover {
  background-color: var(--error-color);
  color: var(--text-light);
  border-color: var(--error-color);
  box-shadow: 0 2px 8px rgba(var(--error-rgb), 0.3);
  transform: scale(1) translateY(1px);
}

.settings-modal-main-nav-item svg {
  min-width: 40px;
}

.settings-modal-main-content {
  flex: 1;
  max-height: 700px;
  overflow-y: auto;
}

.settings-content-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;  /* Firefox */
}

.settings-content-wrapper::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.settings-row {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.settings-row.horizontal {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.settings-row-seperator {
  height: 1px;
  background-color: var(--border-color);
  margin: var(--spacing-lg) 0;
}

.setting-description {
  margin-top: var(--spacing-xs);
  opacity: 0.7;
}

.settings-modal-main-content .settings-item {
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-lg);
  border-radius: var(--radius-xl);
  border: 1px solid var(--border-color);
  background: var(--glass-background);
  z-index: 1000;
  transition: all var(--transition-base) var(--ease-out);
}

.settings-item h3 {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  font-weight: var(--font-weight-bold);
}

.settings-item span {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
  margin: 0;
  margin-bottom: var(--spacing-sm);
}

.settings-item p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
  margin: 0;
  margin-bottom: var(--spacing-sm);
}

.settings-item .theme-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: var(--spacing-xl);
  width: 100%;
  margin: var(--spacing-md) 0;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.profile-group-wrap {
  display: flex;
  gap: var(--spacing-md);
}

.profile-group-wrap .profile-form-group {
  flex: 1;
}

.profile-group-wrap .profile-form-group {
  margin-bottom: var(--spacing-md);
}

.profile-form-group label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.profile-form .profile-form-group input {
  width: 100%;
  padding: var(--spacing-md) var(--spacing-md);
  border: 1.5px solid var(--border-interactive);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--input-bg);
  transition: all var(--transition-base) var(--ease-out);
  box-shadow: var(--shadow-md);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  letter-spacing: var(--letter-spacing-normal);
}

.profile-form .profile-form-group input::placeholder {
  color: var(--text-tertiary);
  opacity: 0.8;
}

.profile-form .profile-form-group input:disabled {
  background-color: var(--input-disabled-bg);
  border-color: var(--border-color);
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
  color: var(--text-muted);
  box-shadow: none;
  transition: none;
}

.profile-form .profile-form-group input:hover {
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-lg);
  transform: translateY(-1px);
  transition: all var(--transition-fast) var(--ease-out);
}

.profile-form .profile-form-group input:focus {
  outline: none;
  border-color: var(--border-focus);
  box-shadow: var(--focus-ring);
  transform: var(--button-hover-transform);
  transition: all var(--transition-fast) var(--ease-out);
}

.profile-form .submit-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-interactive);
  padding: var(--spacing-md) var(--spacing-md);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-base) var(--ease-out);
  margin-top: var(--spacing-md);
  display: inline-block;
  width: 100%;
  text-align: center;
  box-shadow: var(--shadow-md);
  position: relative;
  overflow: hidden;
}

.profile-form .submit-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: var(--button-hover-transform);
  box-shadow: var(--shadow-lg);
}

.profile-form .submit-button:active {
  transform: var(--button-active-transform);
  transition: all var(--transition-fast) var(--ease-out);
}

.profile-form .submit-button:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.settings-item .theme-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
  background: var(--glass-background);
  box-shadow: var(--shadow-lg);
  transition: all var(--transition-base) var(--transition-bezier);
  cursor: pointer;
  width: 100%;
  max-width: 130px;
  min-width: 90px;
  min-height: 90px;
  position: relative;
  overflow: hidden;
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
}

.settings-item .button-main {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: var(--spacing-md) var(--spacing-md);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all var(--transition-base) var(--ease-out);
  margin-top: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 200px;
  box-shadow: var(--shadow-md);
}

.settings-item .button-main:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: var(--button-hover-transform);
  box-shadow: var(--shadow-lg);
}

.settings-item .button-main:active {
  transform: var(--button-active-transform);
  transition: all var(--transition-fast) var(--ease-out);
}

.button-main.delete {
  background-color: var(--background-light);
  color: var(--error-color);
  padding: var(--spacing-md) var(--spacing-md);
  width: 100%;
  border: 1px solid rgba(var(--error-rgb), 0.2);
}

.button-main.delete:hover {
  background-color: rgba(var(--error-rgb), 0.05);
  color: var(--error-color);
  border-color: var(--error-color);
  box-shadow: var(--shadow-xl);
}

.button-main.delete:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--error-rgb), 0.2);
}

.settings-item .theme-option:hover {
  border-color: var(--border-primary);
  box-shadow: var(--shadow-primary);
  transform: translateY(-2px);
}

.settings-item .theme-option-icon {
  min-height: 25px;
  color: var(--text-secondary);
}

.settings-item .theme-option.active {
  border-color: var(--primary-color);
  background-color: var(--background-hover-primary);
  transition: all var(--transition-fast) var(--ease-out);
}

.settings-item .theme-option-label {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.settings-item .memory-add-button {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  color: var(--text-primary);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all var(--transition-base);
}

.settings-item .memory-add-button:hover {
  background-color: var(--background-hover);
  box-shadow: var(--shadow-lg);
  border-color: var(--border-interactive-hover);
}

.settings-item .memory-description {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
}

.settings-item .memory-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding-right: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.settings-item .memory-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-xl);
  transition: all var(--transition-base);
  margin-top: var(--spacing-xs);
}

.settings-item .memory-item:hover {
  border-color: var(--border-interactive-hover);
  transform: translateY(-1px);
}

.settings-item .memory-preview {
  flex: 1;
  cursor: pointer;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  min-height: 24px;
  padding: var(--spacing-xs);
}

.settings-item .memory-preview:empty::before {
  content: 'Click to add details...';
  color: var(--text-secondary);
  font-size: var(--font-size-xs);
  font-style: italic;
}

.settings-item .memory-item textarea {
  flex: 1;
  padding: var(--spacing-xs);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--background-main);
  resize: none;
  min-height: 30px;
  max-height: 200px;
  outline: none;
  border: none;
  box-shadow: none;
}

.settings-item .memory-item textarea:focus {
  box-shadow: none;
}

.settings-item .memory-cancel-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-lg);
  color: var(--error-color);
}

.subscription-info {
  background: linear-gradient(145deg, var(--background-main), var(--background-light));
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-xl);
  border: 1px solid var(--border-primary);
  margin-bottom: var(--spacing-lg);
}

.subscription-info p {
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.subscription-info p strong {
  color: var(--primary-color);
  font-weight: var(--font-weight-semibold);
}

.subscription-actions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-sm);
}

.subscription-details-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: var(--font-size-xs);
  transition: all var(--transition-base);
  position: relative;
  padding-left: var(--spacing-md);
  display: inline-block;
  margin: auto 0;
  margin-left: auto;
}

.subscription-details-link::before {
  content: '→';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform var(--transition-base);
}

.subscription-details-link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.subscription-details-link:hover::before {
  transform: translate(4px, -50%);
}

.trial-disclaimer {
  font-size: var(--font-size-xs);
  color: var(--text-muted);
  margin-top: var(--spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.trial-disclaimer p {
  margin: 0;
}

.button-main {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all var(--transition-base);
  margin-top: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 200px;
}

.button-main:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.beta-feature-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
}

.beta-feature-header-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.beta-feature-header-left svg,
.beta-feature-icon {
  min-width: 20px;
  margin: auto 0;
  color: var(--text-secondary);
}


.beta-feature-header h3 {
  font-size: var(--font-size-base);
  letter-spacing: var(--letter-spacing-tight);
  margin: auto 0;
  transition: color var(--transition-fast);
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  line-height: var(--line-height-snug);
  text-shadow: var(--text-shadow-sm);
}

.beta-tag {
  background: var(--primary-gradient);
  color: var(--text-light);
  padding: 0.15em 0.5em;
  border-radius: var(--radius-full);
  font-size: var(--font-size-xxs);
  margin-left: var(--spacing-xs);
  box-shadow: var(--shadow-primary);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-wide);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all var(--transition-fast);
  transform: scale(0.85);
}

.beta-feature-description {
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-xs);
  color: var(--text-secondary);
}

.beta-feature-subtext {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-top: var(--spacing-xs);
}

.settings-modal-main-content-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.settings-modal-main-content-loading-spinner {
  width: 40px;
  height: 40px;
  border: 1px solid var(--border-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spinner-rotation 1s linear infinite;
}

.beta-disclaimer {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  background: linear-gradient(145deg, var(--background-main), var(--background-light));
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
}

.beta-disclaimer .disclaimer-icon {
  color: var(--warning-color);
  flex-shrink: 0;
}

.beta-disclaimer p {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin: 0;
  line-height: var(--line-height-snug);
}

.beta-features-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--background-light);
  border-radius: var(--radius-lg);
  border: 1px dashed var(--border-color);
  margin: var(--spacing-md) 0;
}

.beta-features-empty p {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  text-align: center;
  margin: 0;
}


@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .settings-modal-overlay{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
  }
  .settings-modal{
    width: min(95%, 650px);
    margin: 0;
    overflow: hidden;
    padding: var(--spacing-md);
    height: 85vh;
  }
  
  .settings-modal-title {
    display: block;
  }
  
  .settings-modal-main {
    flex-direction: column;
    height: calc(100% - 40px);
    gap: 0;
    overflow: hidden;
  }

  .settings-modal-main-nav-wrapper{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .settings-modal-main-nav{
    padding-top: var(--spacing-xs);
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    -webkit-overflow-scrolling: touch;
    padding-bottom: var(--spacing-sm);
    max-height: 60px;
    margin: 0;
  }

  .settings-modal-main-nav::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }

  .settings-modal-main-nav-item{
    max-width: 300px;
    min-width: max-content;
    font-size: var(--font-size-xs);
    white-space: nowrap;
  }
  .settings-modal-main-nav-wrapper-bottom{
    display: none;
  }

  .settings-modal-main-content{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-bottom: auto;
    max-height: calc(100% - 80px);
    overflow-y: auto;
    flex: 1;
  }
  
  .settings-content-wrapper {
    padding: 0 var(--spacing-sm);
  }

  .settings-modal-main{
    height: 100%;
  }

  .settings-item .theme-options{
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  .subscription-actions{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .settings-item-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  
  .settings-item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
}