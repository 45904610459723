/* Resources Section */
.resources-section {
  position: fixed;
  right: 0;
  bottom: 15px;
  width: var(--resources-width);
  background: var(--background-light);
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;
  transition: 
    width var(--transition-base),
    margin-right var(--transition-base);
  border-radius: 16px 0 0 16px;
  border-top: 1px solid var(--border-light);
  border-bottom: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  overflow: hidden;
  transform: translateX(100%);
  z-index: var(--z-fixed);
  min-width: 250px;
  max-height: calc(100vh - 65px);
}

.resources-section.expanded {
  border-radius: var(--radius-2xl);
  border: none;
  min-width: 400px;
  max-width: 1050px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .resources-section.expanded {
    top: 0;
    margin-right: 8px;
  }
}

@media (min-width: 850px) {
  .resources-section {
    width: var(--resources-width-medium);
  }
  .resources-section.expanded {
    width: var(--resources-width-expanded);
  }
}

@media (min-width: 1380px) {
  .resources-section {
    width: var(--resources-width-large);
  }
}

/* Header styles */
.resources-header {
  background-color: var(--background-light);
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.resources-header h3 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.resources-header h3 svg {
  font-size: 1.25rem;
  color: var(--primary-color);
}

/* Content area */
.resources-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--spacing-md);
  z-index: var(--z-fixed);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

@media (max-width: 768px) {
  .resources-content {
    padding: var(--spacing-sm);
  }
}

.resources-list {
  overflow-y: auto;
  max-height: 35vh;
  padding: var(--spacing-sm) var(--spacing-xs);
}

.save-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-base);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px var(--shadow-color);
}

.save-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.save-button:active {
  transform: var(--button-active-transform);
}

.panel-section.generating {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-sm);
}

.resources-section .section-title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
  padding: 0 var(--spacing-xs);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  line-height: var(--line-height-snug);
  letter-spacing: var(--letter-spacing-wide);
  transition: color var(--transition-fast);
}

.resources-section .section-title.mode-title {
  padding-bottom: var(--spacing-sm);
}


.resource-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: var(--background-light);
  margin-right: 10px;
  transition: all var(--transition-fast);
  flex-shrink: 0;
  color: var(--text-secondary);
}

.resource-info {
  flex-grow: 1;
  margin-right: 6px;
  overflow: hidden;
}

.resource-title {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-primary);
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .resource-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .resource-title {
    font-size: 0.75rem;
  }
}

.resource-info {
  flex-grow: 1;
}

.resource-title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

/* Compact Layout for Small Screens */
@media (max-width: 768px) {
  .resource-icon {
    font-size: 14px;
    margin-right: 6px;
  }

  .resource-title {
    font-size: 11px;
  }
}

/* Empty Resources Message - Updated Styles */
.empty-resources-message {
  background: linear-gradient(to bottom right, var(--background-main), var(--background-light));
  border-radius: 12px;
  padding: 14px;
  text-align: center;
  margin: 12px 0;
  border: 1px dashed var(--border-color);
  transition: all var(--transition-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.empty-resources-message strong {
  display: block;
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3px;
}

.empty-resources-message p {
  color: var(--text-secondary);
  font-size: 0.8rem;
  line-height: 1.4;
  margin: 0;
}

@media (max-width: 768px) {
  .empty-resources-message {
    padding: 16px;
  }

  .empty-resources-message strong {
    font-size: 0.9rem;
  }

  .empty-resources-message p {
    font-size: 0.75rem;
  }
}

@media (max-width: 600px) {
  .resources-section {
    display: none;
  }
}

/* Global scrollbar styles for desktop */
@media (min-width: 769px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--text-tertiary) var(--background-secondary);
  }

  *::-webkit-scrollbar {
    width: 1px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--text-tertiary);
    border-radius: 1px;
  }

  *::-webkit-scrollbar-track {
    background-color: var(--background-secondary);
  }
}

.memory-list::-webkit-scrollbar,
.resources-list::-webkit-scrollbar {
  width: 1px;
}

.memory-list::-webkit-scrollbar-thumb,
.resources-list::-webkit-scrollbar-thumb {
  background-color: var(--text-tertiary);
  border-radius: 1px;
}

.memory-list::-webkit-scrollbar-track,
.resources-list::-webkit-scrollbar-track {
  background-color: var(--background-secondary);
}

.resource-loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background-primary);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--card-shadow);
  margin: 0.75rem 0;
  min-height: 200px;
  border: 1px solid var(--border-color);
  text-align: center;
}

.resource-loading-indicator .loading-spinner {
  width: 36px;
  height: 36px;
  border: 2px solid var(--background-secondary);
  border-top: 2px solid var(--accent-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.resource-loading-indicator h3 {
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.4rem 0;
}

.resource-loading-indicator p {
  color: var(--text-secondary);
  font-size: 0.8rem;
  margin: 0;
  line-height: 1.3;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .resource-loading-indicator {
    padding: 1.5rem;
    min-height: 150px;
  }

  .resource-loading-indicator .loading-spinner {
    width: 32px;
    height: 32px;
    margin-bottom: 0.75rem;
  }

  .resource-loading-indicator h3 {
    font-size: 0.9rem;
  }

  .resource-loading-indicator p {
    font-size: 0.75rem;
  }
}

/* Loading spinner styles */
.resource-loading-indicator .loading-spinner {
  width: 24px;
  height: 24px;
  border: 2px solid var(--border-primary);
  border-top: 2px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  margin-right: 12px;
  flex-shrink: 0;
}

/* Memory Section Styles */
.memory-group {
  background: var(--background-main);
  border-radius: var(--radius-xl);
  border: 1px solid var(--border-light);
  font-size: var(--font-size-sm);
  overflow: hidden;
}


.memory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.memory-header:hover {
  background-color: var(--background-light);
}

.memory-header h2 {
  margin: 0;
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.memory-toggle {
  font-size: 0.9rem;
  color: var(--text-secondary);
  transition: transform var(--transition-fast);
}

.memory-toggle.open {
  transform: rotate(180deg);
}

.memory-content {
  max-height: 0;
  overflow: hidden;
  transition: 
    max-height var(--transition-base) var(--ease-out),
    transform var(--transition-base) var(--ease-out),
    padding var(--transition-base) var(--ease-out);
  padding: 0 1rem;
  transform: translateY(-10px);
}

.memory-content.open {
  max-height: 600px;
  padding: 1rem;
  transform: translateY(0);
}

.memory-description {
  color: var(--text-secondary);
  font-size: 0.8rem;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.memory-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding-right: 10px;
}

.memory-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--background-light);
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--border-light);
}

.memory-preview {
  flex: 1;
  font-size: 0.85rem;
  color: var(--text-primary);
  cursor: text;
  padding: 0.25rem;
}

.memory-item textarea {
  background: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.85rem;
  padding: 0.5rem;
  transition: border-color var(--transition-fast);
}

.memory-item textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--shadow-primary);
}

.memory-button {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
  transition: all var(--transition-fast);
}

.memory-button:hover {
  color: var(--error-color);
  background: var(--background-hover);
}

.memory-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.memory-add-button {
  background: var(--background-light);
  border: 1px solid var(--border-light);
  color: var(--text-secondary);
  padding: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.memory-add-button:hover {
  background: var(--background-hover);
  color: var(--primary-color);
  transform: scale(1.05);
}
.empty-memory-message {
  color: var(--text-secondary);
  font-size: 0.8rem;
  text-align: center;
  padding: 1rem;
  background: var(--background-light);
  border-radius: 6px;
  border: 1px dashed var(--border-light);
}