/* WelcomeDisplay.css - Minimalist Redesign */

:root {
  --welcome-modal-max-width: 650px;
  --welcome-modal-height: auto;
  --welcome-modal-max-height: 90vh;
  --welcome-modal-padding: var(--spacing-xl);
  --welcome-header-height: 70px;
  --welcome-actions-height: 80px;
}

.welcome-display-container {
  position: fixed;
  inset: 0;
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-overlay {
    position: fixed;
    inset: 0;
    background: var(--background-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-modal);
    min-height: 100vh;
    width: 100%;
    backdrop-filter: var(--blur-md);
}

.welcome-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--welcome-modal-max-width);
  height: var(--welcome-modal-height);
  max-height: var(--welcome-modal-max-height);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-base); /* Softer shadow */
  overflow: hidden;
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  position: relative; /* For corner help button */
}

/* Header styles */
.welcome-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--welcome-modal-padding);
  height: var(--welcome-header-height);
  border-bottom: 1px solid var(--border-light);
  flex-shrink: 0;
}

.welcome-logo {
  height: 30px; /* Smaller logo */
  width: auto;
  opacity: 0.8;
}

.welcome-progress-container {
  flex-grow: 1;
  height: 6px;
  background-color: var(--background-hover);
  border-radius: var(--radius-full);
  margin: 0 var(--spacing-lg);
  overflow: hidden;
}

.welcome-progress-bar {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: var(--radius-full);
  transition: width 0.4s var(--ease-in-out);
}

.step-counter { /* Optional step counter */
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  white-space: nowrap;
}

/* Content area styles */
.welcome-content {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-lg) var(--welcome-modal-padding) 0;
}

.welcome-step-content {
  padding-bottom: var(--spacing-xl); /* Space before actions */
}

.welcome-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px; /* Slightly narrower content */
  margin: 0 auto;
  width: 100%;
}

.welcome-step-container h2 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold); /* Less bold */
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
  text-align: center;
}

.welcome-description {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  text-align: center;
  margin-bottom: var(--spacing-xl);
  line-height: var(--line-height-snug);
}

.welcome-input-group {
  width: 100%;
  margin-top: var(--spacing-md);
}

.welcome-input-group label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.welcome-input {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md); /* Adjusted padding */
  border: 1px solid var(--border-interactive); /* Default border */
  border-radius: var(--radius-lg);
  font-size: var(--font-size-base);
  background-color: var(--background-light);
  color: var(--text-primary);
  transition: all var(--transition-fast);
  outline: none;
}

.welcome-input::placeholder {
  color: var(--text-tertiary);
  opacity: 0.8;
}

.welcome-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--input-focus); /* Softer focus */
}

/* Study level styles */
.study-level-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  width: 100%;
  margin-top: var(--spacing-md);
}

.study-level-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  border-radius: var(--radius-lg);
  background-color: var(--background-light);
  border: 1px solid var(--border-interactive);
  cursor: pointer;
  transition: all var(--transition-fast);
  text-align: center;
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  min-height: 60px;
  outline: none;
}

.study-level-card:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.study-level-card.selected {
  border-color: var(--primary-color);
  background-color: var(--background-hover-primary);
  color: var(--primary-dark);
  box-shadow: none; /* Remove extra shadow */
}

.study-level-card:focus-visible {
  box-shadow: 0 0 0 2px var(--input-focus);
}

/* Memories styles */
.memories-container {
  width: 100%;
  margin-top: var(--spacing-md);
}

.empty-memories {
  text-align: center;
  padding: var(--spacing-md);
  background-color: var(--background-hover);
  border-radius: var(--radius-lg);
  border: 1px dashed var(--border-light);
  margin-top: var(--spacing-lg);
}

.empty-memories p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.memories-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  margin-bottom: var(--spacing-md);
  padding-right: var(--spacing-xs);
}

.memory-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: var(--radius-full);
  background: none;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  transition: all var(--transition-fast);
  flex-shrink: 0;
  margin-top: 4px; /* Align better with textarea */
}

.memory-remove:hover {
  color: var(--error-color);
  background-color: rgba(239, 68, 68, 0.1);
}

.add-memory-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  background-color: var(--background-light);
  border: 1px solid var(--border-interactive);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  width: 100%;
  margin-top: var(--spacing-xs); /* Closer to list */
}

.add-memory-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  color: var(--text-primary);
}

/* Profile summary styles */
.profile-summary {
  width: 100%;
  background-color: var(--background-main);
  border-radius: var(--radius-lg);
  overflow: hidden;
  margin-top: var(--spacing-lg);
  border: 1px solid var(--border-light);
}

.summary-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-lg);
  border-bottom: 1px solid var(--border-light);
  font-size: var(--font-size-sm);
}

.summary-item:last-child {
  border-bottom: none;
}

.summary-icon {
  color: var(--text-tertiary);
  flex-shrink: 0;
}

.summary-item span {
  color: var(--text-secondary);
}

.summary-item strong {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  margin-left: auto; /* Push to the right */
  text-align: right;
}

/* Action buttons */
.welcome-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) var(--welcome-modal-padding);
  background-color: var(--background-light);
  border-top: 1px solid var(--border-light);
  height: var(--welcome-actions-height);
  flex-shrink: 0;
}

.welcome-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-lg); /* Slightly smaller */
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm); /* Smaller text */
  font-weight: var(--font-weight-medium);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all var(--transition-fast);
  min-width: 100px;
  height: 40px;
  outline: none;
}

.welcome-button.secondary {
  background-color: var(--background-light);
  color: var(--text-secondary);
  border-color: var(--border-interactive);
}

.welcome-button.secondary:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  color: var(--text-primary);
}

.welcome-button.primary {
  background-color: var(--primary-color);
  color: var(--text-light);
  margin-left: auto;
}

.welcome-button.primary:hover {
  background-color: var(--primary-dark);
  /* box-shadow: 0 2px 4px var(--shadow-primary); Simple shadow */
}

.welcome-button:disabled {
  background-color: var(--button-disabled);
  border-color: var(--button-disabled);
  color: var(--text-muted);
  cursor: not-allowed;
  opacity: 0.7;
}

.welcome-button.primary:disabled {
  background-color: var(--primary-light);
  opacity: 0.6;
}

.welcome-button:focus-visible {
  box-shadow: 0 0 0 2px var(--input-focus);
}

/* Corner Help Button */
.help-button-corner {
  position: absolute;
  top: calc((var(--welcome-header-height) - 32px) / 2); /* Vertically center in header */
  right: var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: var(--radius-full);
  background: none;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.help-button-corner:hover {
  color: var(--text-primary);
  background-color: var(--background-hover);
}

/* Responsive styles */
@media (max-width: 768px) {
  :root {
    --welcome-modal-padding: var(--spacing-lg);
    --welcome-header-height: 60px;
    --welcome-actions-height: 70px;
  }

  .welcome-overlay {
    padding: 0;
  }

  .welcome-modal {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .welcome-content {
    padding: var(--spacing-md) var(--welcome-modal-padding) 0;
  }

  .welcome-step-container h2 {
    font-size: var(--font-size-lg);
  }

  .welcome-description {
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-lg);
  }

  .study-level-grid {
    grid-template-columns: 1fr; /* Stack on mobile */
  }

  .welcome-actions {
    padding: var(--spacing-sm) var(--welcome-modal-padding);
  }

  .welcome-button {
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--font-size-xs);
    height: 36px;
    min-width: 80px;
  }

  .help-button-corner {
    top: calc((var(--welcome-header-height) - 28px) / 2);
    right: var(--spacing-sm);
    width: 28px;
    height: 28px;
  }
}
