.input-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 42px;
    padding: var(--spacing-sm);
    background-color: var(--glass-background);
    border-radius: var(--radius-4xl);
    border: 1px solid var(--border-color);
    transition: all var(--transition-base);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    position: relative;
    z-index: 10;
}

.input-bar:focus-within {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06), 
                0 0 0 1px rgba(var(--primary-rgb), 0.15);
}

.input-bar-left {
    display: flex;
    flex: 1;
    align-items: center;
    gap: var(--spacing-xs);
    height: 100%;
}

.input-bar-right {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    height: 100%;
}

.input-bar-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xs) var(--spacing-sm);
    cursor: pointer;
    border-radius: var(--radius-lg);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    min-height: 28px;
    user-select: none;
    color: var(--text-secondary);
}

.input-bar-item:hover {
    background-color: var(--background-hover);
    transform: translateY(-1px);
    color: var(--text-primary);
}

.input-bar-item:active {
    transform: translateY(0);
}

.input-bar-item.resources {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 60px;
    padding: var(--spacing-xs) var(--spacing-sm);
}

.input-bar-item.resources:hover .input-bar-pill-text {
    color: var(--primary-color);
}

.resources-button-content {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    transition: all var(--transition-base);
}

.input-bar-pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--radius-lg);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: relative;
    min-height: 28px;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: inherit;
    color: var(--text-secondary);
}

.input-bar-pill:not(:disabled):hover {
    background-color: var(--background-hover);
    transform: translateY(-1px);
    color: var(--text-primary);
}

.input-bar-pill:not(:disabled):active {
    transform: translateY(0);
}

.input-bar-pill:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.input-bar-pill:focus-visible {
    box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.3);
    outline: none;
}

.icon-little-pulse {
    animation: tiny-pulse 2s infinite cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes tiny-pulse {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.08); opacity: 0.9; }
    100% { transform: scale(1); opacity: 1; }
}
 
.separator {
    width: 1px;
    height: 24px;
    background-color: var(--border-color);
    margin: 0 var(--spacing-sm);
    flex-shrink: 0;
    opacity: 0.8;
}

@media (max-width: 600px) {
    .input-bar {
        height: 38px;
        padding: var(--spacing-xs);
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.02);
        border-radius: var(--radius-2xl);
    }
    
    .input-bar-item,
    .input-bar-pill {
        padding: var(--spacing-xs);
        min-height: 26px;
        font-size: 0.9rem;
    }
    
    .separator {
        margin: 0 var(--spacing-xs);
        height: 20px;
        opacity: 0.6;
    }
    
    .hover-text {
        display: none;
    }
    
    .resources-count {
        min-width: 16px;
        height: 16px;
        font-size: 9px;
        top: -3px;
        right: -3px;
    }
}

.prompt-fix-button:not(:disabled):hover {
    background-color: rgba(var(--primary-rgb), 0.08);
}

.prompt-fix-button:not(:disabled):hover .icon-little-pulse {
    color: var(--primary-color);
}

.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.reasoning-button.active {
    background-color: rgba(var(--primary-rgb), 0.1);
    color: var(--primary-color);
}

.reasoning-button:not(.active):hover {
    background-color: rgba(var(--primary-rgb), 0.08);
}

.resources-button {
    position: relative;
}

.resources-button.active {
    background-color: rgba(var(--primary-rgb), 0.1);
    color: var(--primary-color);
}

.resources-button:not(.active):hover {
    background-color: rgba(var(--primary-rgb), 0.08);
}

.resources-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 50%;
    min-width: 18px;
    height: 18px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shine {
    animation: shine 2s infinite;
}

@keyframes shine {
    0% { opacity: 0.7; }
    50% { opacity: 1; transform: scale(1.1); }
    100% { opacity: 0.7; }
}