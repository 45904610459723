.input-area-pill {
  color: var(--text-muted);
  padding: var(--spacing-sm) var(--spacing-sm);
  font-size: var(--font-size-sm);
  border-radius: var(--radius-2xl);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  transition: all var(--transition-base);
  z-index: var(--z-dropdown);
  font-weight: var(--font-weight-medium);
  box-shadow: var(--shadow-sm);
  width: fit-content;
  border: 1px solid var(--border-color);
  z-index: 2;
  position: relative;
}

.input-area-pill.active {
  background-color: var(--background-dark);
}

.input-area-pill.chat {
  padding: 8px;
}

.input-area-pill.bar {
  justify-content: space-between;
  border: none;
}

.input-area-pill:hover {
  background-color: var(--background-hover);
}

.input-area-pill svg {
  margin-left: 0rem;
  margin-right: 0rem;
  color: var(--text-muted);
}

.input-area-pill.bar svg {
  margin-left: 0rem;
  margin-right: 0rem;
  color: var(--primary-color);
  border: none;
}

.input-area-pill-text {
  width: auto;
  margin-left: var(--spacing-xs);
  opacity: 1;
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.2s ease, width 0.2s ease;
}

.submit-button {
  position: relative;
}



.stop-button-icon {
  fill: var(--text-primary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--border-interactive);
  border-radius: var(--radius-full);
  background-color: var(--background-main);
  color: var(--text-primary);
  transition: all var(--transition-fast);
}

.stop-button-icon:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: var(--button-hover-transform);
}

.regenerate-button-content {
  display: flex;
  align-items: center;
  
  gap: var(--spacing-xs);
}

.regenerate-button-content .regenerate-dropdown-button {
  padding: 0;
  opacity: 0;
  transform: translateX(-5px);
  transition: opacity 0.3s var(--ease-out), transform 0.3s var(--ease-out), width 0.3s var(--ease-out);
  pointer-events: none;
}

.mode-selector:hover .regenerate-dropdown-button {
  width: auto;
  margin-left: 0;
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
  animation: fadeSlideIn 0.3s var(--ease-out) forwards;
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateX(-5px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.test-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  padding: 0px 8px;
  border-radius: 24px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
}

.test-button:hover {
  background-color: var(--glass-background-hover);
}

.test-button-content svg {
  color: var(--text-muted);
}

.test-button-content {
  display: flex;
  align-items: center;
  color: var(--text-muted);
  font-size: var(--font-size-xs);
  gap: var(--spacing-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-snug);
}

.test-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-indicator-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--primary-color);
}