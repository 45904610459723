.messages-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width 0.3s ease-in-out;
    max-width: 100%;
    position: relative;
    height: 100%;
    padding: 0 var(--spacing-md); /* Add padding to prevent content from being hidden by scrollbar */
    scrollbar-gutter: stable; /* Reserves space for the scrollbar to prevent layout shifts */
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

.messages-wrapper.no-title-header {
    padding-top: 50px;
}

.messages-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
