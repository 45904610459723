.upgrade-prompt {
    display: flex;
    align-items: center;
}

.upgrade-link{
    color: var(--primary-color);
}

.upgrade-link:hover {
    color: var(--primary-light);
}

.upgrade-item {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background-color: var(--accent-primary);
    color: var(--text-primary);
    border-radius: 25px;
    text-decoration: none;
    font-size: 12px;
    transition: all 0.2s ease;
  }
  
  .upgrade-item svg {
    color: var(--text-primary);
  }
  
  .upgrade-item span {
    white-space: nowrap;
  }