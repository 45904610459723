.study-spaces-container {
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    background-color: var(--background-main);
    overflow-x: hidden;
    padding-top: var(--spacing-xs);
  }

.recent-chats-list{
  padding: 1rem 1rem;
}
  
.study-spaces-container  .study-spaces-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: var(--background-main);
    border-radius: 16px;
    max-width: 1800px;
    padding: var(--spacing-md) 2.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
.study-spaces-container  .header-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .study-spaces-container .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .study-spaces-container .study-spaces-header h1 {
    font-size: 2rem;
    color: var(--text-primary);
    margin: 0;
    font-weight: 700;
    letter-spacing: -0.025em;
    position: relative;
  }
  
  .study-spaces-container .study-spaces-header h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background: var(--primary-gradient);
    border-radius: 2px;
  }
  
  .study-spaces-container .create-space-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.25rem;
    color: var(--text-light);
    background: var(--primary-gradient);
    border: none;
    border-radius: 0.75rem;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 8px var(--shadow-primary);
    gap: 0.5rem;
    max-width: 160px;
  }
  
  .study-spaces-container .create-space-btn:hover {
    box-shadow: 0 4px 12px var(--shadow-primary-hover);
    transform: translateY(-2px);
  }
  
  .study-spaces-container .search-bar {
    position: relative;
    margin: var(--spacing-sm) auto;
    max-width: 600px;
    transform-origin: center;
    transition: all 0.3s ease;
    background: linear-gradient(to right, var(--background-main), var(--background-light));
    border-radius: 16px;


  }
  
  .study-spaces-container .search-bar input {
    width: 100%;
    padding: 12px 16px 12px 46px;
    border: 2px solid var(--border-light);
    border-radius: 24px;
    font-size: 15px;
    background: var(--background-main);
    color: var(--text-primary);
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px var(--shadow-color);
    background: transparent;
    font-size: 1rem;
    padding: 1rem 1rem 1rem 3rem;
  }
  
  .study-spaces-container .search-bar input:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 4px 12px var(--shadow-primary);
  }
  
  .study-spaces-container .search-bar .search-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-tertiary);
    transition: color 0.3s ease;
  }
  
  .study-spaces-container .search-bar input:focus + .search-icon {
    color: var(--primary-color);
  }
  
  .study-spaces-container .study-spaces-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1.5rem;
    margin: 2rem auto 0;
    max-width: 1400px;
    padding: 0 var(--spacing-md);
  }
  
  .study-spaces-container .study-space-card {
    position: relative;
    padding: var(--spacing-lg);
    border-radius: var(--radius-xl);
    background: var(--glass-background);
    border: 1px solid var(--border-light);
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    box-shadow: 0 4px 20px var(--shadow-color);
    transition: all 0.3s var(--ease-out);
  }
  
  .study-spaces-container .study-space-card:hover {
    transform: var(--button-hover-transform);
    box-shadow: 0 8px 24px var(--shadow-primary);
    border-color: var(--border-primary);
  }
  
  .study-space-card svg{
    color: var(--text-primary);
  }
  
  .study-spaces-container .space-header {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .study-spaces-container .space-header h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-primary);
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .study-spaces-container .space-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: 0.75rem;
  }

  .study-spaces-container .last-activity-time {
    color: var(--text-tertiary);
    font-size: var(--font-size-xs);
  }
  
  .study-spaces-container .arrow-icon {
    color: var(--primary-color);
    transition: transform 0.2s ease;
  }
  
  .study-spaces-container .study-space-card:hover .arrow-icon {
    transform: translateX(4px);
  }
  
  @media (max-width: 768px) {
    .study-spaces-container {
      padding: 1rem;
    }
  
    .study-spaces-container .study-spaces-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem 0;
    }
  
    .study-spaces-container .header-left {
      width: 100%;
      justify-content: space-between;
    }
  
    .study-spaces-container .header-actions {
      width: 100%;
    }
  
    .study-spaces-container .create-space-btn {
      flex: 1;
      justify-content: center;
      margin-left: auto;
    }
  
    .study-spaces-container .study-spaces-grid {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      gap: 1rem;
    }
  
    .study-spaces-container .card-actions {
      opacity: 1;
    }
  
    .study-spaces-container .study-space-card {
      height: auto;
      min-height: 120px;
      padding: 1.25rem;
    }
  
    .study-spaces-container .space-stats {
      padding: 0.5rem 0.75rem;
    }
  }
    
  /* Add hover text styles for card actions */
  .study-spaces-container .hover-text {
    position: absolute;
    background: var(--background-dark);
    color: var(--text-light);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    pointer-events: none;
  }
  
  .study-spaces-container .hover-text.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-8px);
  }
  
  .study-spaces-container .card-actions button:hover .hover-text {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-4px);
  }
  
  .study-spaces-container .primary-button {
    background: var(--primary-gradient);
    color: var(--text-light);
    padding: 12px 24px;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .study-spaces-container .secondary-button {
    background: var(--background-light);
    color: var(--text-tertiary);
    padding: 12px 24px;
    border: 1px solid var(--border-light);
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .study-spaces-container .primary-button:hover:not(:disabled) {
    box-shadow: 0 4px 12px var(--shadow-primary);
  }

  .study-spaces-container .primary-button:hover {
    background-color: var(--primary-dark);
  }
  
  .study-spaces-container .secondary-button:hover {
    background: var(--background-hover);
  }
  
  .study-spaces-container .empty-spaces-state h2 {
    justify-content: center;
  }
  
  .study-spaces-container .empty-spaces-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    padding: 2rem;
  }
  
  .study-spaces-container .empty-spaces-content {
    text-align: center;
    max-width: 400px;
    animation: fadeIn 0.5s ease;
  }
  
  .study-spaces-container .empty-spaces-content .empty-icon {
    color: var(--primary-color);
    margin-bottom: 1.5rem;
    opacity: 0.9;
  }
  
  .study-spaces-container .empty-spaces-content h2 {
    font-size: 1.5rem;
    color: var(--text-dark);
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .study-spaces-container .empty-spaces-content p {
    color: var(--text-secondary);
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .study-spaces-container .create-space-btn-empty {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 1.5rem;
    color: var(--text-light);
    background: var(--primary-gradient);
    border: none;
    border-radius: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 8px var(--shadow-primary);
    gap: 0.5rem;
  }
  
  .study-spaces-container .create-space-btn-empty:hover {
    box-shadow: 0 4px 12px var(--shadow-primary-hover);
    transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 640px) {
    .study-spaces-container .empty-spaces-state {
      min-height: 300px;
      padding: 1rem;
    }
  
    .study-spaces-container .empty-spaces-content h2 {
      font-size: 1.25rem;
    }
  
    .study-spaces-container .empty-spaces-content p {
      font-size: 0.95rem;
    }
  }
  
  @keyframes shimmer {
    0% { background-position: -1000px 0 }
    100% { background-position: 1000px 0 }
  }
  
  .study-spaces-container .loading-skeleton {
    background: linear-gradient(90deg, var(--background-hover) 25%, var(--background-light) 50%, var(--background-hover) 75%);
    background-size: 1000px 100%;
    animation: shimmer 2s infinite linear;
  }
  
  @media (max-width: 640px) {
    .study-spaces-container .search-bar {
      margin: 1rem 0;
    }
    
    .study-spaces-container .search-bar input {
      font-size: 14px;
      padding: 10px 16px 10px 40px;
    }
  }
  
  .study-spaces-container .study-space-card:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--border-primary-hover);
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .study-spaces-container .study-space-card {
    animation: fadeInUp 0.6s ease forwards;
    animation-delay: calc(var(--index) * 0.1s);
  }