.file-drop-overlay {
  position: fixed;
  inset: 0;
  background: rgba(var(--background-light-rgb), 0.95);
  backdrop-filter: var(--blur-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-modal);
  animation: fadeIn var(--transition-fast);
}

.file-drop-content {
  background: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-xl);
  text-align: center;
  max-width: 420px;
  width: 90%;
  box-shadow: 0 4px 24px var(--shadow-color);
  transition: all var(--transition-base);
}

.file-drop-content h3 {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  margin: 0 0 var(--spacing-sm) 0;
  letter-spacing: var(--letter-spacing-normal);
}

.file-drop-content p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
  line-height: var(--line-height-base);
}

.file-drop-overlay:hover .file-drop-content {
  border-color: var(--primary-color);
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .file-drop-content {
    padding: var(--spacing-lg);
    max-width: 320px;
  }

  .file-drop-content h3 {
    font-size: var(--font-size-base);
  }

  .file-drop-content p {
    font-size: var(--font-size-xs);
  }
}