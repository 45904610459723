.recent-chats-section {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 1.5rem auto 0;
    padding: var(--spacing-lg);
    border-radius: var(--radius-2xl);
    max-height: 80vh;
    backdrop-filter: var(--blur-sm);
    
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.recent-chats-header {
    display: flex;
    align-items: center;
    padding: var(--content-spacing-md);
}

.recent-chats-list {
    display: grid;
    gap: var(--content-spacing-md);
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 900px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.recent-chat-item {
    background: var(--glass-background);
    border: 1px solid var(--border-interactive);
    border-radius: var(--radius-2xl);
    display: flex;
    align-items: center;
    padding: var(--spacing-md) var(--spacing-sm);
    cursor: pointer;
    transition: all var(--transition-base);
    max-width: 300px;
}

.recent-chat-item:active {
    transform: var(--button-active-transform);
    box-shadow: var(--shadow-primary);
}

.recent-chat-item.space-chat {
    background: var(--background-hover-secondary);
}

.recent-chat-item:hover {
    background: var(--background-hover-primary);
    border-color: var(--border-interactive-hover);
    box-shadow: var(--shadow-primary-hover);
}

.recent-chat-item .chat-preview-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: var(--background-hover-secondary);
    border-radius: var(--radius-md);
    margin-right: var(--spacing-sm);
    flex-shrink: 0;
    min-width: 32px;
    transition: all var(--transition-base);
}

.recent-chat-item .chat-preview-icon svg {
    color: var(--primary-color);
    width: 16px;
    height: 16px;
    transition: transform var(--transition-base);
}

.recent-chat-item:hover .chat-preview-icon svg {
    transform: scale(1.1);
}

.recent-chat-item .chat-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    min-width: 0;
}

.recent-chat-item .chat-title {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
    font-size: var(--font-size-xs);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding-right: var(--spacing-sm);
}

.recent-chat-item .chat-date {
    font-size: var(--font-size-xs);
    color: var(--text-muted);
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    text-align: left;
    letter-spacing: var(--letter-spacing-normal);
    margin-left: auto;
    margin-right: var(--spacing-xs);
}

/* Media Queries - consolidated */
@media (max-width: 1000px) {
    .recent-chat-item {
        max-width: 500px;
    }
    .recent-chats-list {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (min-width: 950px) {
    .recent-chats-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .recent-chats-section {
        padding: var(--content-spacing-xs);
    }
    
    .recent-chats-header {
        flex-direction: column;
        gap: var(--spacing-sm);
    }

    .view-all-button {
        justify-content: center;
        margin: var(--spacing-sm) auto 0;
        max-width: 140px;
    }
}