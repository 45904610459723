/* Update the existing auth-prompt styles */
.auth-prompt {
  position: relative;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--background-overlay);
  padding: 20px 30px;
  width: 80%;
  border-radius: 16px;
  box-shadow: 0 8px 32px var(--shadow-light);
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  border: 1px solid var(--border-primary);
  width: auto;
  max-width: 600px;
  margin: 0 20px;
  margin-bottom: 10px;
  animation: slideUpFade 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 500px;
}

/* Update media query for mobile */
@media (max-width: 480px) {
  .auth-prompt {
    margin: 0 16px;
    margin-bottom: 10px;
    padding: 16px 20px;
  }
}

.auth-prompt-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.close-auth-prompt {
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--background-main);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-muted);
  box-shadow: 0 2px 8px var(--shadow-light);
  transition: all 0.2s ease;
}

.close-auth-prompt:hover {
  background: var(--background-light);
  transform: scale(1.1);
}

.auth-prompt p {
  margin: 0;
  font-size: 0.95rem;
  color: var(--text-dark);
  line-height: 1.4;
}

.auth-prompt-buttons {
  display: flex;
  gap: 12px;
  margin-top: 4px;
}

.auth-prompt-button {
  flex: 1;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease;
  text-decoration: none;
  font-size: 0.9rem;
}

.auth-prompt-button.signup {
  background: var(--primary-gradient);
  color: var(--text-light);
  box-shadow: 0 4px 12px var(--shadow-primary);
}

.auth-prompt-button.signup:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px var(--shadow-primary-hover);
  filter: brightness(105%);
}

.auth-prompt-button.login {
  background: var(--background-main);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.auth-prompt-button.login:hover {
  background: var(--background-hover-primary);
}

@keyframes slideUpFade {
  from {
    opacity: 0;
    transform: translate(-50%, 20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0) translateZ(0);
  }
}
/* Mobile Responsive Styles */
@media (max-width: 480px) {
  .auth-prompt {
    padding: 16px 20px;
    width: 80%;
  }

  .auth-prompt p {
    font-size: 0.9rem;
  }

  .auth-prompt-buttons {
    flex-direction: column;
  }

  .auth-prompt-button {
    padding: 8px 16px;
  }
}