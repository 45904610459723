.sidebar-footer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-xs);
  padding-bottom: 0;
  margin-top: auto;
  background-color: var(--sidebar-bg);
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.sidebar-user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-sm);
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  transition: all var(--transition-fast);
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8px);
  background-color: var(--background-light);
}

.sidebar-user-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.sidebar-user-item:active {
  transform: translateY(1px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.sidebar-user-item-details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: var(--spacing-xs);
}

.sidebar-user-item-name {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
  margin-bottom: var(--spacing-xs);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-tight);
  transition: color var(--transition-fast) var(--ease-out);
}

.sidebar-user-item-email {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
  opacity: 0.9;
}

.sidebar-user-item-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--spacing-sm);
  transition: all var(--transition-fast);
}

.sidebar-user-item:hover .sidebar-user-item-avatar {
  transform: scale(1.08);
}