.create-space-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-modal);
  backdrop-filter: var(--blur-md);
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  animation: fadeIn 0.35s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-space-modal-content {
  background: var(--glass-background);
  border-radius: var(--radius-xl);
  border: 1px solid var(--border-glass);
  box-shadow: var(--shadow-elevated);
  width: 90%;
  max-width: 550px;
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  transform: scale(0.95) translateY(10px);
  opacity: 0;
  animation: modalEnter 0.5s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
}

@keyframes modalEnter {
  from {
    transform: scale(0.95) translateY(10px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.create-space-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-xl);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--border-light);
}

.create-space-modal-header h2 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  letter-spacing: var(--letter-spacing-tight);
}

.create-space-progress {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xl);
  position: relative;
  padding: 0 var(--spacing-sm);
}

.progress-step {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  padding-bottom: var(--spacing-md);
}

.progress-step:last-child {
  flex: 0;
}

.progress-step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 36px;
  width: calc(100% - 40px);
  height: 2px;
  background-color: var(--border-color);
  transition: background-color 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 0;
}

.progress-step.completed:not(:last-child)::after {
  background-color: var(--primary-color);
}

.step-number {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--background-light);
  border: 1.5px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-tertiary);
  margin-right: var(--spacing-md);
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  position: relative;
}

.progress-step.active .step-number {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-light);
  box-shadow: 0 0 0 5px var(--shadow-primary), 0 3px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.progress-step.completed .step-number {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-light);
}

.create-space-modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 0 var(--spacing-xs);
}

.create-space-form-step {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  animation: formStepFadeIn 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes formStepFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.create-space-form-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  margin-bottom: 0;
  letter-spacing: var(--letter-spacing-tight);
}

.create-space-form-step p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
  margin: var(--spacing-xs) 0 var(--spacing-md) 0;
}

.modal-input {
  width: 100%;
  padding: var(--spacing-md) var(--spacing-lg);
  border: 1.5px solid var(--border-interactive);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--input-bg);
  transition: all 0.3s var(--ease-out);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  font-family: var(--font-primary);
  letter-spacing: 0.01em;
}

.modal-input:hover {
  border-color: var(--border-interactive-hover);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.modal-input:focus {
  outline: none;
  border-color: var(--border-focus);
  box-shadow: var(--focus-ring);
  transform: translateY(-1px);
}

.modal-input:disabled {
  background-color: var(--input-disabled-bg);
  border-color: var(--border-color);
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
  transform: none;
  box-shadow: none;
}

textarea.modal-input {
  resize: none;
  line-height: 1.6;
  min-height: 140px;
}

.validation-message {
  font-size: var(--font-size-xs);
  color: var(--error-color);
  min-height: 20px;
  margin-top: calc(var(--spacing-xs) * -0.5);
  transition: all 0.2s ease;
}

.error-message {
  background-color: rgba(var(--error-color), 0.1);
  border: 1px solid var(--error-color);
  color: var(--error-color);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  animation: errorShake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes errorShake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
  20%, 40%, 60%, 80% { transform: translateX(2px); }
}

.button-group {
  display: flex;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.primary-button {
  background: var(--primary-gradient);
  color: var(--text-light);
  border: none;
  padding: var(--spacing-md) var(--spacing-xl);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 2px 8px var(--shadow-primary), 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.01em;
}

.primary-button:hover {
  box-shadow: 0 4px 12px var(--shadow-primary-hover), 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: var(--button-hover-transform);
}

.primary-button:active {
  transform: var(--button-active-transform);
  box-shadow: 0 3px 8px var(--shadow-primary), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition-duration: 0.1s;
}

.primary-button:disabled {
  background: linear-gradient(135deg, #d1d5db 0%, #9ca3af 100%);
  color: var(--text-light);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  opacity: 0.7;
}

.primary-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.primary-button:hover::after {
  opacity: 1;
}

.secondary-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1.5px solid var(--border-interactive);
  padding: var(--spacing-md) var(--spacing-xl);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
}

.secondary-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: var(--button-hover-transform);
}

.secondary-button:active {
  transform: var(--button-active-transform);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition-duration: 0.1s;
}

.secondary-button:disabled {
  background-color: var(--background-light);
  color: var(--text-tertiary);
  border-color: var(--border-color);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  opacity: 0.7;
}

.create-space-modal-content .loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-xs);
  height: 20px;
}

.create-space-modal-content .loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.create-space-modal-content .loading-dots span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--text-light);
  animation: loadingDot 1.4s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  opacity: 0.85;
}

.create-space-modal-content .loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.create-space-modal-content .loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loadingDot {
  0%, 80%, 100% { 
    transform: scale(0);
  } 
  40% { 
    transform: scale(1.0);
  }
}

@media (max-width: 768px) {
  .create-space-modal-overlay {
    padding: var(--spacing-sm);
    align-items: flex-end;
  }
  
  .create-space-modal-content {
    width: 100%;
    max-width: 100%;
    padding: var(--spacing-lg);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: var(--radius-xl) var(--radius-xl) 0 0;
    animation: modalEnterMobile 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
  
  @keyframes modalEnterMobile {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .create-space-modal-header h2 {
    font-size: var(--font-size-lg);
  }
  
  .step-number {
    width: 24px;
    height: 24px;
    margin-right: var(--spacing-sm);
  }
  
  .progress-step:not(:last-child)::after {
    left: 30px;
    width: calc(100% - 34px);
  }
  
  .create-space-form-title {
    font-size: var(--font-size-lg);
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .primary-button, .secondary-button {
    width: 100%;
    padding: var(--spacing-md) var(--spacing-md);
  }
}
