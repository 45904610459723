.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: var(--blur-md);
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity var(--transition-base);
}

.confirmation-modal-content {
  background-color: var(--background-main);
  padding: var(--spacing-xl);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-elevated);
  max-width: 400px;
  width: 90%;
  border: 1px solid var(--border-color);
  transform: translateY(0);
  transition: transform var(--transition-base) var(--ease-out),
              opacity var(--transition-base) var(--ease-out);
  animation: modal-appear 0.3s var(--ease-out);
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dark mode specific adjustments */
[data-theme="dark"] .confirmation-modal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

[data-theme="dark"] .confirmation-modal-content {
  border-color: var(--border-strong);
  background-color: var(--background-light);
}

.confirmation-modal-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.confirmation-modal-content p {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  margin-bottom: 0.5rem;
  text-align: center;
  line-height: var(--line-height-relaxed);
}

.confirmation-modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.confirmation-modal-actions .button-main {
  min-width: 100px;
  margin: 0;
}

.confirmation-modal-actions .cancel-button {
  min-width: 100px;
  margin: 0;
}

.confirmation-modal-actions .confirmation-confirm-button {
  /* Layout */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 200px;
  text-align: center;

  /* Visual Styles */
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 0.6rem 1.5rem;
  border-radius: var(--radius-md);
  box-shadow: 0 2px 8px var(--shadow-primary);

  /* Typography */
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);

  /* Interaction */
  cursor: pointer;
  transition: all var(--transition-base);
}

.confirmation-modal-actions .confirmation-confirm-button:hover {
  background-color: var(--primary-dark);
  transform: var(--button-hover-transform);
  box-shadow: 0 4px 12px var(--shadow-primary-hover);
}

.confirmation-modal-actions .confirmation-confirm-button:active {
  transform: var(--button-active-transform);
}

.confirmation-modal-actions .confirmation-cancel-button {
  background-color: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  padding: 0.6rem 1.5rem;
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all var(--transition-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.confirmation-modal-actions .confirmation-cancel-button:hover {
  background-color: var(--background-hover);
  color: var(--text-primary);
  border-color: var(--border-interactive-hover);
}

.confirmation-modal-actions .confirmation-cancel-button:active {
  transform: var(--button-active-transform);
}