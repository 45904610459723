.premium-tag {
    background: var(--primary-gradient);
    color: var(--text-light);
    padding: 0.15em 0.5em;
    border-radius: var(--radius-full);
    font-size: var(--font-size-xxs);
    margin-left: var(--spacing-xs);
    box-shadow: var(--shadow-primary);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-wide);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all var(--transition-fast);
    transform: scale(0.85);
  }