.test-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-modal);
    backdrop-filter: var(--blur-sm);
}

.test-modal {
  background: var(--background-main);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-elevated);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: var(--spacing-lg);
}

.test-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--border-light);
}

.test-modal-header h2 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.test-modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.test-options {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.option-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.option-group label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.option-group select {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
  background-color: var(--input-bg);
  color: var(--text-primary);
  font-size: var(--font-size-base);
  transition: all var(--transition-base);
}

.option-group select:hover {
  border-color: var(--border-interactive-hover);
}

.option-group select:focus {
  border-color: var(--primary-color);
  box-shadow: var(--input-focus);
  outline: none;
}

.topic-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
}

.topic-card {
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: var(--radius-lg);
  padding: var(--spacing-sm);
  cursor: pointer;
  transition: all var(--transition-base);
}

.topic-card.loading {
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: var(--radius-md);
  padding: var(--spacing-md);
  cursor: pointer;
  transition: all var(--transition-base);
  animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

.topic-card h4 {
    margin: 0;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
    text-align: center;
}

.topic-card:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: translateY(-1px);
}

.topic-card.selected {
  background: var(--background-dark);
  border-color: var(--border-interactive-hover);
}

.custom-topic-input {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-color);
  background-color: var(--input-bg);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  transition: all var(--transition-base);
}

.custom-topic-input:hover {
  border-color: var(--border-interactive-hover);
}

.custom-topic-input:focus {
  border-color: var(--border-interactive-hover);
  background: var(--background-hover);
  border: 1px solid var(--border-interactive-hover);
}

.docs-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xs);
}

.doc-card {
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: var(--radius-md);
  padding: var(--spacing-sm);
  cursor: pointer;
  transition: var(--transition-base);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  min-height: 2.75rem;
}

.doc-card h4 {
  margin: 0;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium); 
  color: var(--text-primary);
  text-align: left;
  line-height: var(--line-height-tight);
}

.doc-card:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.doc-card.selected {
  background: var(--background-hover-primary);
  border-color: var(--primary-color);
  box-shadow: var(--shadow-primary);
  transform: translateY(-2px);
  color: var(--primary-color);
}

.doc-card.selected h4 {
  color: var(--primary-color);
}

.doc-card.selected:hover {
  background: var(--background-hover-secondary);
  border-color: var(--border-primary-hover);
  box-shadow: var(--shadow-primary-hover);
}


.generate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  background: var(--background-hover);
  color: var(--text-primary);
  border: 1px solid var(--border-interactive);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-base);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.generate-button:hover:not(:disabled) {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.generate-button:disabled {
  background: var(--button-disabled);
  border-color: var(--border-color);
  color: var(--text-muted);
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
  box-shadow: none;
}

.generate-button.generating {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
  color: var(--text-primary);
  animation: generating-pulse 1.5s ease-in-out infinite;
}

@keyframes generating-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.02);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.questions-display {
  margin-top: var(--spacing-lg);
  padding-top: var(--spacing-lg);
  border-top: 1px solid var(--border-light);
}

.questions-display h3 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.questions-list {
  background: var(--background-light);
  border-radius: var(--radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--border-light);
}
