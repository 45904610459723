.message {
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-xl);
  line-height: var(--line-height-base);
  animation: messageFadeIn var(--transition-slow) ease-in-out; /* Changed to a more subtle fade-in */
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  min-height: 50px;
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  transform-origin: center; /* Consider removing if not needed for the new animation */
  position: relative;
}

@keyframes messageFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below final position */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-message-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: min(650px, 100%);
  min-width: min(200px, 100%);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--spacing-sm);
}

.user-message-wrapper.editing {
  margin-bottom: var(--spacing-md);
}

.user-message-wrapper:hover {
  z-index: 3;
}


.message.user {
  font-family: var(--font-primary);
  background-color: var(--message-user-bg);
  color: var(--text-primary);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-sm);
  text-align: left;
  border-radius: var(--radius-2xl) var(--radius-xl) var(--radius-sm) var(--radius-2xl);
  position: relative;
  transition: all var(--transition-base) var(--transition-bezier);
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--message-user-border);
  margin-bottom: 0;
  z-index: 1;
  min-width: 50px;
  margin-left: auto;
  width: max-content;
}

@media (max-width: 500px) {
  .message.user {
    max-width: 250px;
  }
}

.user-message-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  line-height: 1.5;
  z-index: 2;
  position: relative;
}

.user-message-left {
  font-size: 0.9rem;
  word-break: break-word;
  white-space: pre-wrap;
}

.user-message-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;
  gap: 4px;
}

.user-icon {
  width: 32px;
  height: 32px;
  background-color: var(--background-dark);
  color: var(--text-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xs);
  margin-left: auto;
  margin-right: -0.2rem;
  min-width: 32px;
  box-shadow: 0 2px 4px var(--card-shadow);
  transition: all var(--transition-base);
}

.message.ai {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  color: var(--message-ai-text);
  max-width: 640px;
  font-size: 1.0005rem;
  padding: 0px;
  text-align: left;
  position: relative;
  margin-top: 0.5rem;
  min-width: 250px;
}

.message.ai .ai-icon {
  position: absolute;
  top: -5px;
  left: -50px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
  border-radius: 40px;
  background-color: transparent;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media (max-width: 768px) {
  .ai-icon {
    display: none;
  }
}

.empty-message {
  display: flex;
  text-align: left;
  height: 100%;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  margin-bottom: 15px;
}

.empty-message p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  font-style: italic;
}

.user-message-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-xs);

  width: 100%;
  max-width: 650px;
  min-width: 250px;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-primary);
  animation: fadeOut 0.2s forwards;
  z-index: 10;
  position: relative;
  transition: opacity var(--transition-base) var(--ease-out);
}

.user-message-footer button {
  position: relative;
}

.user-message-wrapper:hover .user-message-footer {
  opacity: 1;
  animation: fadeIn 0.2s forwards;
  z-index: 10;
}

.message-actions {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  opacity: 0;
  z-index: 3;
  position: relative;
  transition: opacity var(--transition-base) var(--ease-out), 
              transform var(--transition-base) var(--ease-out);
  transform: translateY(2px);
  padding: 0 var(--spacing-xs);
}


.message.ai:hover .message-actions, .message.ai.last-message .message-actions {
  opacity: 1;
  transform: translateY(0);
  animation: fadeIn 0.2s forwards;
  z-index: 10;
}

.user-message-footer.is-locked {
  animation: none !important;
  opacity: 1 !important;
}

.message-actions.is-locked {
  animation: none !important;
  opacity: 1 !important;
}

.message.ai:not(:hover).not-last-message .message-actions {
  animation: fadeOut 0.2s forwards;
  transition: opacity var(--transition-base) var(--ease-out);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(2px);
  }
}

.message .message-actions button {
  color: var(--text-dark);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color var(--transition-base), color var(--transition-base);
  color: var(--text-primary);
  position: relative;
}

.message-actions button:hover {
  color: var(--text-primary);
  background-color: var(--background-light);
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  color: var(--text-muted);
  transition: color 0.2s ease, transform 0.2s ease;
  border-radius: 8px;
}

.action-button:hover {
  color: var(--text-primary);
  background-color: var(--background-light);
}

.message.ai.not-last-message:hover{
  z-index: 10;
}

.message-indicators {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.edit-actions .cancel-edit-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: var(--radius-lg);
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-base), 
              transform var(--transition-fast),
              border-color var(--transition-base);
}

.edit-actions .cancel-edit-button:hover {
  background-color: var(--background-hover);
  transform: var(--button-hover-transform);
  border-color: var(--border-interactive-hover);
}

.edit-actions .save-edit-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: var(--radius-lg);
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-base), 
              transform var(--transition-fast),
              border-color var(--transition-base);
}

.edit-actions .save-edit-button:hover {
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
  transform: var(--button-hover-transform);
}

.message-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.message-footer svg{
  color: var(--text-primary);
}

.message-footer.editing {
  display: block;
} 

.message.user .quote-content {
  border-left: 4px solid var(--border-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  margin: var(--spacing-md) 0;
  border-radius: var(--radius-sm);
  font-style: italic;
}

@media (max-width: 768px) {
    .message.ai,
    .user-message-wrapper{
      max-width: 580px;
    }
}  