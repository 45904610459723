.close-button {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0.4rem;
  border-radius: 50%;
  margin: auto 0;
  outline: none;
}

.close-button:hover {
  background-color: var(--background-hover);
  color: var(--primary-color);
  transform: rotate(90deg) scale(1.05);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.close-button:active {
  transform: rotate(90deg) scale(0.95);
  transition: all 0.1s ease-in-out;
}