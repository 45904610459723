.chats-list-container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin: 0 auto;
  padding: var(--spacing-lg);
  padding-top: 0;
  background-color: var(--background-main);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.chats-list-header {
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-main);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  top: 0;
  position: sticky;
  z-index: 1;
  backdrop-filter: blur(8px);
}

.chats-list-header h1 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
  line-height: var(--line-height-tight);
}

.chats-list-header p {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-base);
  margin: 0;
}

.chats-list {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) var(--background-main);
    display: grid;  
    gap: 0.75rem;
    list-style: none;
    padding: var(--spacing-md);
    margin: 0;
    max-width: 1200px;
    margin: 0 auto;
    flex: 1; /* Fill remaining space */
}
  

/* For larger screens, ensure maximum of 3 columns */
@media (min-width: 1000px) {
    .chats-list {
      grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1000px) {
    .chats-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 568px) {
    .chats-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .delete-icon:hover {
    color: var(--primary-color);
}

.recent-chat-item .delete-button {
    display: none;
}

.recent-chat-item:hover .delete-button {
    display: block;
}

.sentinel {
    height: 1px;
    visibility: hidden;
    order: 1; /* Force to bottom of flex container */
  }
  