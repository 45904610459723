.sales-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-light);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-modal);
    backdrop-filter: var(--blur-md);
}

.sales-modal-content {
    background-color: var(--background-light);
    padding: var(--content-spacing-2xl);
    max-width: 1000px;
    width: 90%;
    position: relative;
}

@keyframes modalFadeIn {
    from { opacity: 0; transform: translateY(-30px); }
    to { opacity: 1; transform: translateY(0); }
}

.sales-modal-close {
    position: absolute;
    top: var(--content-spacing-md);
    right: var(--content-spacing-md);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
    transition: all 0.3s var(--ease-out);
    padding: var(--content-spacing-xs);
    border-radius: 50%;
}

.sales-modal-close:hover {
    color: var(--primary-color);
    background-color: var(--background-light);
    transform: rotate(90deg);
}

.sales-modal-content h2 {
    font-size: var(--font-size-3xl);
    margin-bottom: var(--content-spacing-md);
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    text-align: center;
}

.modal-description {
    margin-bottom: var(--content-spacing-2xl);
    color: var(--text-secondary);
    line-height: var(--line-height-relaxed);
    font-size: var(--font-size-md);
    text-align: center;
}

.plans-grid {
    display: flex;
    justify-content: center;
}

@media (max-width: 468px) {
    .plan-card {
        min-width: 230px;
    }
}

.card-header {
    text-align: center;
    margin-bottom: var(--content-spacing-xl);
}

.card-header h3 {
    font-size: var(--font-size-2xl);
    margin-bottom: var(--content-spacing-xs);
    color: var(--text-primary);
}

.tagline {
    color: var(--text-secondary);
    font-size: var(--font-size-lg);
    margin-bottom: var(--content-spacing-xl);
}

.plan-price {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    text-align: center;
    margin-bottom: var(--content-spacing-xl);
}

.period {
    font-size: var(--font-size-base);
    color: var(--text-secondary);
}

.monthly-equivalent {
    display: block;
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
}

.annual-total {
    display: block;
    font-size: var(--font-size-base);
    color: var(--text-secondary);
    margin-top: var(--content-spacing-xs);
}

.free-trial-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primary-gradient);
    color: var(--text-light);
    padding: var(--content-spacing-xs) var(--content-spacing-xl);
    border-radius: var(--radius-full);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
    box-shadow: var(--shadow-primary);
    z-index: 1;
    white-space: nowrap;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: var(--content-spacing-xl);
    text-align: left;
    flex-grow: 1;
}

.feature-list li {
    display: flex;
    align-items: center;
    margin-bottom: var(--content-spacing-sm);
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
}

.feature-list li svg {
    color: var(--primary-color);
    margin-right: var(--content-spacing-xs);
}

.upgrade-button {
    display: inline-block;
    padding: var(--content-spacing-sm) var(--content-spacing-xl);
    border: none;
    border-radius: var(--radius-full);
    cursor: pointer;
    font-weight: var(--font-weight-bold);
    transition: all 0.3s var(--ease-out);
    text-align: center;
    text-decoration: none;
    font-size: var(--font-size-base);
    background-color: var(--primary-color);
    color: var(--text-light);
    width: 100%;
    margin-top: auto;
}

.upgrade-button:hover {
    background-color: var(--primary-dark);
    box-shadow: var(--shadow-primary-hover);
    transform: var(--button-hover-transform);
}

.modal-footer {
    text-align: center;
    margin-top: var(--content-spacing-2xl);
}

.modal-footer p {
    margin-bottom: var(--content-spacing-xs);
    color: var(--text-secondary);
}

.view-all-plans {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: var(--font-weight-bold);
    transition: all 0.3s var(--ease-out);
}

.view-all-plans:hover {
    text-decoration: underline;
}

.ultimate-star {
    position: absolute;
    top: var(--content-spacing-md);
    right: var(--content-spacing-md);
    color: var(--primary-color);
}


@media (max-width: 768px) {
    .sales-modal-content {
        padding: var(--content-spacing-2xl);
        max-width: 90%;
        max-height: 80vh;
        display: flex;
        flex-direction: column;
    }

    .sales-modal-content h2 {
        font-size: var(--font-size-2xl);
    }

    .modal-description,
    .plan-card h3,
    .plan-price {
        font-size: var(--font-size-base);
    }

    .feature-list li {
        font-size: var(--font-size-sm);
    }

    .upgrade-button {
        padding: var(--content-spacing-xs) var(--content-spacing-md);
        font-size: var(--font-size-sm);
    }
    
    .upgrade-button:hover {
        color: var(--text-light);
    }
}

.save-badge {
    background: var(--primary-dark);
    color: var(--text-light);
    padding: var(--content-spacing-xs) var(--content-spacing-sm);
    border-radius: var(--radius-full);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    margin-left: var(--content-spacing-xs);
    animation: pulse 2s infinite;
}

.pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--content-spacing-2xl) auto;
    gap: var(--content-spacing-md);
    padding: var(--content-spacing-xs);
    background: var(--background-light);
    border-radius: var(--radius-full);
    width: fit-content;
    position: relative;
}

@media (max-width: 800px) {
    .sales-modal-content {
        padding: var(--content-spacing-2xl);
        width: 95%;
        max-height: 90vh;
        overflow-y: auto;
    }

    .card-header h3 {
        font-size: var(--font-size-xl);
    }

    .tagline {
        font-size: var(--font-size-base);
    }

    .plan-price {
        font-size: var(--font-size-2xl);
    }
}

@media (max-width: 538px) {
    .sales-modal-content {
        padding: var(--content-spacing-xl);
        width: 100%;
        max-height: 100vh;
    }

    .pricing-toggle {
        flex-direction: column;
        gap: var(--content-spacing-xs);
    }

    .save-badge {
        display: block;
        margin: var(--content-spacing-xs) auto 0;
        width: fit-content;
    }

    .modal-description {
        font-size: var(--font-size-sm);
        margin-bottom: var(--content-spacing-md);
    }

    .sales-modal-content h2 {
        font-size: var(--font-size-xl);
    }
}