.messages-skeleton {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  height: 100%;
}

.message.skeleton {
  opacity: 0.8;
  min-height: 50px;
  margin-bottom: var(--spacing-sm);
  border-radius: var(--radius-xl);
  background: var(--skeleton-bg);
  background-image: linear-gradient(
    90deg,
    var(--skeleton-bg),
    var(--skeleton-highlight),
    var(--skeleton-bg)
  );
  background-size: 200% 100%;
  animation: skeletonPulse 1.5s infinite ease-in-out;
}

.message.user.skeleton {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 650px;
  min-width: 250px;
  border-radius: var(--radius-2xl);
}

.message.ai.skeleton {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 640px;
}

@keyframes skeletonPulse {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .message.ai.skeleton,
  .message.user.skeleton {
    max-width: 580px;
  }
}

@media (max-width: 600px) {
  .message.ai.skeleton,
  .message.user.skeleton {
    max-width: 480px;
  }
}
