.input-container {
  position: relative;
  width: 100%;
  padding-bottom: var(--spacing-md);
}

.input-container.default,
.input-container.guest {
  padding-bottom: 0;
}

.input-wrapper {
  width: 95%;
  max-width: 750px;
  max-height: 400px;
  padding: var(--spacing-sm);
  position: relative;
  z-index: 2;

  background-color: var(--background-light);
  
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  border-radius: var(--radius-4xl);
  outline: none;
  
  /* Positioning */
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center;
  transition: all var(--transition-base) var(--ease-out);
}

/* Empty state variation */
.input-wrapper.empty {
  width: 100%;
  max-width: 750px;
  max-height: none;
  border-radius: var(--radius-4xl);
  transition: all var(--transition-base) var(--ease-out);
}

@media (min-width: 600px) {
  .input-wrapper.empty {
    margin-top: var(--spacing-xl);
  }
}

.input-button-wrapper {
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: var(--spacing-lg) var(--spacing-xs);
}

.input-button-wrapper-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--primary-color);
  flex: 1;
}

.input-button-wrapper-right {
  display: flex;
  flex-direction: row;
  min-width: 45px;
  align-items: center;
  gap: var(--spacing-sm);
  justify-content: flex-end;
}


@media (min-width: 600px) {
  .input-button-wrapper-left {
    width: calc(100% - 70px);
  }
}

@media (max-width: 768px) {
  .input-wrapper.chat {
    border-radius: var(--radius-4xl);
  }

  .input-wrapper {
    width: 94%;
  }

  .input-button-wrapper {
    padding: var(--spacing-lg) 0;
  }

  .input-button-wrapper-left {
    gap: var(--spacing-sm);
  }
}

@media (max-width: 468px) {
  .input-button-wrapper {
    padding: var(--spacing-md) 0;
  }
}

.rounded-button {
  background: none;
  border: 1px solid var(--border-color);
  cursor: pointer;
  color: var(--text-primary);
  transition: all var(--transition-base) var(--ease-out);
  position: relative;
  padding: var(--spacing-sm);
  border-radius: var(--radius-full);
  background-color: var(--background-light);
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  display: flex;
  margin: auto 0;
}

.rounded-button:hover {
  background-color: var(--background-hover);
  box-shadow: var(--shadow-sm);
  transform: var(--button-hover-transform);
}

.rounded-button:active {
  transform: var(--button-active-transform);
}

.rounded-button svg {
  color: var(--text-muted);
  min-width: 23px;
}

@media (max-width: 600px) {
  .rounded-button {
    width: 40px;
    height: 40px;
  }
}

.button-wrapper-icon.disabled {
  color: var(--text-muted);
  opacity: var(--disabled-opacity);
}

.attached-files-section {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  background: transparent;
}

.attach-button {
  background: none;
  margin-left: var(--spacing-xs);
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition: all var(--transition-base) var(--ease-out);
  position: relative;
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
}

.attach-button:hover {
  background-color: var(--background-hover);
  transform: var(--button-hover-transform);
}

.attach-button:active {
  transform: var(--button-active-transform);
}

.chat-input {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  min-height: 45px;
  max-height: 250px;
  color: var(--text-primary);
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  overflow-y: auto;
  flex-grow: 1;
  box-sizing: border-box;
  box-shadow: none;
  border: none;
  font-family: var(--font-primary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-normal);
  transition: height var(--transition-base) var(--ease-out);
}

.chat-input:focus {
  box-shadow: none;
  border: none;
}

.chat-input::-webkit-scrollbar-track,
.chat-input::-webkit-scrollbar-track-piece {
  background: var(--scrollbar-track);
}

.chat-input::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: var(--radius-full);
}

.chat-input::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

@media (max-width: 468px) {
  .chat-input {
    font-size: 16px;
  }
}

.input-wrapper input {
  flex-grow: 1;
  background: transparent;
  font-size: var(--font-size-sm);
  border: none;
  outline: none;
}

.chat-input::placeholder {
  color: var(--input-placeholder);
  font-size: var(--font-size-xs);
}

.chat-input.scrollable.empty {
  max-height: 190px;
  min-height: 20px;
  transition: height var(--transition-base) var(--ease-out);
}

.input-wrapper:has(.chat-input:focus) {
  border: 1px solid var(--border-light);
}

@media (max-width: 600px) {
  .chat-input.scrollable.empty {
    max-height: 120px;
    min-height: 20px;
  }
  
  .input-button-wrapper {
    padding-top: var(--spacing-xs);
  }

  .input-wrapper {
    gap: var(--spacing-sm);
  }
}