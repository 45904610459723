/* Expanded resource container */
.expanded-resource {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background-main);
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1), 
              width var(--transition-fast), 
              margin-left var(--transition-fast);
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  z-index: 1001;
  border: none;
}

/* Base expanded resource content */
.expanded-resource-content {
  flex-grow: 1;
  overflow-y: auto;
  transition: opacity var(--transition-base) var(--ease-out);
  will-change: opacity;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
  padding: var(--spacing-md);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  background-color: var(--background-main);
}

.expanded-resource-content.code {
  padding: 0;
}

.expanded-resource-content.test {
  padding: 0;
}

.expanded-resource-content::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Opera */
}

.expanded-resource-header {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--background-light);
  gap: var(--spacing-sm);
  border-radius: var(--radius-2xl) var(--radius-2xl) 0 0;
  max-height: 50px;
  border: 1px solid var(--border-color);
}

.expanded-resource-header h2 {
  margin: 0 0 0 var(--spacing-sm);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .expanded-resource-header {
    padding: var(--spacing-sm) var(--spacing-md);
  }
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--text-primary);
  border-radius: 10px;
  padding: 10px;
}

.back-button:hover {
  background-color: var(--background-hover);
}

.back-button svg {
  margin-right: 2px;
}

.pdf-link {
  display: inline-flex;
  align-items: center;
  background-color: var(--background-overlay);
  color: var(--text-tertiary);
  text-decoration: none;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-xs); 
  transition: all var(--transition-base);
  border: 1px solid var(--border-color);
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  cursor: pointer;
}

.pdf-link:hover {
  background-color: var(--background-hover);
  color: var(--text-primary);
  box-shadow: 0 1px 2px var(--shadow-color);
}

.pdf-link svg {
  margin-left: 3px;
  font-size: 0.65rem;
}

@media (max-width: 768px) {
  .pdf-link {
    font-size: 0.65rem;
    padding: 2px 4px;
  }
}

.smart-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-light);
  border-top: 1px solid var(--color-neutral-lighter);
  border-radius: 0 0 var(--radius-2xl) var(--radius-2xl);
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1002;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.02),
              0 -8px 40px rgba(0, 0, 0, 0.04);
  min-width: 0px;   
  max-height: 50px; 
  border: 1px solid var(--border-color);
}

.version-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.version-number {
  font-weight: 500;
  background: linear-gradient(to right, rgba(var(--accent-color-rgb), 0.08), rgba(var(--accent-color-rgb), 0.12));
  border: 1px solid rgba(var(--accent-color-rgb), 0.15);
  padding: 6px 14px;
  font-size: 0.85rem;
  color: var(--text-primary);
  transition: background-color 0.3s ease;
}

.version-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  transition: color 0.3s ease;
}

.version-toggle:hover {
  color: var(--text-primary);
}

.version-toggle-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

.version-toggle-icon:hover {
  transform: scale(1.1);
}

.version-toggle-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.version-toggle-icon.disabled:hover {
  transform: none;
}

@media (max-width: 768px) {
  .version-info {
    width: 100%;
    justify-content: space-between;
  }

  .version-toggle {
    width: 100%;
    justify-content: space-between;
  }
}

.version-toggle-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.version-toggle-icon:hover {
  transform: scale(1.1);
}

.version-toggle-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.version-toggle-icon.disabled:hover {
  transform: none;
}

.smart-footer .version-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--background-light);
  padding: 6px 12px;
  border-radius: 20px;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity;
}

.smart-footer .version-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

.smart-footer .version-nav-btn:hover:not(:disabled) {
  background-color: var(--background-main);
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.smart-footer .version-nav-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .version-divider {
    display: none;
  }

  .version-total {
    display: none;
  }

  .version-nav-btn.diff-toggle {
    display: none;
  }
}

.smart-footer .version-display {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  color: var(--text-primary);
}

.smart-footer .version-current {
  font-weight: 600;
}

.smart-footer .version-divider {
  color: var(--text-secondary);
}

.diff-content {
  padding: 2rem;
  background-color: var(--background-light);
}

.change-block {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: var(--background-main);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.change-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.previous-version, .current-version {
  padding: 1rem;
  border-radius: 6px;
}

.arrow {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-secondary);
  margin: 1rem 0;
}

.diff-content .content {
  padding: 1rem;
  border-radius: 6px;
  line-height: 1.6;
}

.content.removed {
  background-color: var(--error-light);
  border: 1px solid var(--error-color);
}

.content.added {
  background-color: var(--success-light);
  border: 1px solid var(--success-color);
}

.added-block .change-label {
  color: var(--success-color);
}

.removed-block .change-label {
  color: var(--error-color);
}

.expanded-resource .add-to-space-button{
  background: var(--background-light);
  border: 1px solid var(--color-neutral-lighter);
  border-radius: 8px;
}

.expanded-resource .add-to-space-button:hover {
  border-color: var(--accent-color);
  box-shadow: 0 4px 12px rgba(var(--accent-color-rgb), 0.1);
}

.expanded-resource .add-to-space-button:active {
  box-shadow: 0 1px 2px rgba(var(--accent-color-rgb), 0.1);
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.footer-right-button-icon {
  width: 18px;
  height: 18px;
}

.footer-right-button {
  padding: 8px;
  border-radius: 8px;
}

.footer-right-button:hover {
  background: var(--background-hover);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .expanded-resource .add-to-space-button {
    padding: 4px 8px;
    font-size: 0.85rem;
  }
}


.expanded-resource .ai-response-list-item {
  line-height: 1.1;
  position: relative;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .expanded-resource .ai-response-list,
  .expanded-resource .ai-response-ordered-list,
  .expanded-resource .ai-response-unordered-list {
    padding-left: 1rem;
  }

  .expanded-resource .ai-response-list-item.level-2 {
    margin-left: 0.8rem;
  }

  .expanded-resource .ai-response-list-item.level-3 {
    margin-left: 1.6rem;
  }

  .expanded-resource .ai-response-list-item.level-4 {
    margin-left: 2.4rem;
  }

  .expanded-resource .ai-response-sublist {
    margin-left: 0.3rem;
    padding-left: 0.8rem;
  }
}


.expanded-resource .ai-response-heading {
  margin-top: 0.1rem;
}

.expanded-resource .ai-response-heading-1 {
  font-size: 1.6rem;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.expanded-resource .ai-response-heading-2 {
  font-size: 1.35rem;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.expanded-resource .ai-response-heading-3 {
  font-size: 1.15rem;
}

.expanded-resource .ai-response-heading-4 {
  font-size: 1rem;
}

.expanded-resource .ai-response-heading-5 {
  font-size: 0.8rem;
}

.expanded-resource .ai-response-paragraph {
  margin-top: 0.1rem;
}

/* AI Response Typography */
.expanded-resource .ai-response-list,
.expanded-resource .ai-response-ordered-list,
.expanded-resource .ai-response-unordered-list,
.expanded-resource .ai-response-paragraph,
.expanded-resource .ai-response-heading {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  letter-spacing: -0.011em;
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1, 'liga' 1;
}

.expanded-resource .ai-response-heading {
  font-weight: 600;
  line-height: 1.3;
  color: var(--text-primary);
}

.expanded-resource .ai-response-paragraph {
  font-weight: 400;
  line-height: 1.6;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.expanded-resource .ai-response-list-item {
  line-height: 1.5;
  font-weight: 400;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.expanded-resource .code-language {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.diff-content .change-block {
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  background-color: var(--background-light);
}

/* Tab Styling - Minimal and Rounded */
.tabs-header {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: var(--radius-xl);
  background-color: var(--background-light);
  margin-bottom: 1rem;
}

.tab-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: var(--radius-lg);
  background: none;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  transition: all var(--transition-fast);
}

.tab-button:hover {
  color: var(--text-primary);
  background-color: var(--background-hover);
}

.tab-button[aria-selected="true"],
.tab-button.active {
  color: var(--text-primary);
  background-color: var(--background-main);
  box-shadow: var(--shadow-sm);
}

.tab-content {
  padding: 1.5rem;
  background-color: var(--background-main);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-sm);
}
