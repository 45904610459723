.chat-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-main);
  z-index: 1000;
}

.chat-not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--spacing-xl);
  max-width: 450px;
  background: var(--glass-background);
  backdrop-filter: var(--blur-md);
  border: 1px solid var(--border-glass);
  border-radius: var(--radius-lg);
}

.chat-not-found-content h2 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-tight);
}

.chat-not-found-content p {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  line-height: var(--line-height-relaxed);
  max-width: 90%;
}

@media (max-width: 768px) {
  .chat-not-found-content {
    padding: var(--spacing-lg);
    max-width: 90%;
    margin: 0 var(--spacing-md);
  }

  .chat-not-found-content h2 {
    font-size: var(--font-size-xl);
  }

  .chat-not-found-content p {
    font-size: var(--font-size-sm);
  }

}