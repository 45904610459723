.tool-action-display {
    padding: 0 var(--spacing-sm);
    padding-bottom: var(--spacing-md);
}

.tool-action-display p {
  margin: 0;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  background: linear-gradient(90deg, 
    var(--primary-light) 0%, 
    var(--primary-color) 50%, 
    var(--primary-dark) 100%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--text-on-primary);
  animation: shine 2s linear infinite;
  position: relative;
  overflow: hidden;
}

.tool-action-display p::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-main);
  transform-origin: right;
  animation: unveil 1s ease-out forwards;
}

@keyframes shine {
  to { background-position: 200% center; }
}

@keyframes unveil {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}