/* Shared Animation Timings */
:root {
  --transition-quick: 0.2s;
  --transition-normal: 0.3s;
  --transition-slow: 0.4s;
  --bezier-smooth: cubic-bezier(0.4, 0, 0.2, 1);
  --bezier-bounce: cubic-bezier(0.34, 1.56, 0.64, 1);
  --bezier-decelerate: cubic-bezier(0, 0, 0.2, 1);
}

/* Shared Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} 