.delete-button {
  color: var(--text-primary);
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-fast);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.delete-button:hover {
  background-color: var(--error-color);
  color: var(--text-light);
  border-color: var(--error-color);
  transform: var(--button-hover-transform);
}

.delete-button:active {
  transform: var(--button-active-transform);
  opacity: var(--active-opacity);
}

.delete-button:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.delete-button:disabled {
  opacity: var(--disabled-opacity);
  cursor: not-allowed;
  background-color: var(--button-disabled);
}

[data-theme="dark"] .delete-button {
  border-color: var(--border-interactive);
}

[data-theme="dark"] .delete-button:hover {
  background-color: var(--error-color);
  border-color: var(--error-color);
}
