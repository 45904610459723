/* Sidebar Base Structure */
.sidebar {
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
  color: var(--text-primary);
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 0.3vh);
  height: calc(100dvh - 0.3vh);
  width: var(--sidebar-width);
  left: 0;
  top: 0.15vh;
  z-index: var(--z-fixed);
  border-right: 1px solid var(--border-color);
  border-radius: 0 21px 21px 0px;
  transform: translateX(0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity;
  pointer-events: auto;
}

.sidebar.sidebar-locked {
  background-color: var(--sidebar-bg);
  height: 100vh;
  top: 0;
  border-radius: 0;
}

.sidebar.collapsed {
  transform: translateX(-100%);
  opacity: 0;
  transition: all var(--transition-normal) var(--bezier-smooth);
  box-shadow: none;
  border-right: none;
  pointer-events: none;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(0);
    transition: transform var(--transition-slow) var(--ease-out),
                opacity var(--transition-slow) var(--ease-out);
    will-change: transform, opacity;
    backdrop-filter: var(--blur-md);
    -webkit-backdrop-filter: var(--blur-md);
  }

  .sidebar.sidebar-locked {
    border-right: none;
    width: 300px;
  }

  .sidebar.collapsed {
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
    transition: transform var(--transition-slow) var(--ease-out),
                opacity var(--transition-slow) var(--ease-out);
  }
}

/* Sidebar Content Transitions */
.sidebar > * {
  transition: opacity 0.2s ease-in-out;
  will-change: opacity;
}

.sidebar.collapsed > * {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

/* Sidebar Header */
.sidebar-header {
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary);
  border-radius: 0 20px 0 0;
  font-weight: var(--font-weight-medium);
}

/* Sidebar Navigation */
.sidebar-nav {
  flex-grow: 1;
  color: var(--text-secondary);
  overflow-y: auto;
  padding: 0.35rem 0.3rem;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 29px 0;
  box-shadow: inset 0 0 0 var(--shadow-color);
}

.sidebar .sentinel {
  height: 1px;
  order: 1;
  width: 100%;
  visibility: hidden;
}

/* Scrollable Section */
.scrollable-section {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 0.5rem;
  width: 100%;
  color: var(--text-primary);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding: var(--spacing-sm) var(--spacing-sm);
  padding-top: 0;
  z-index: var(--z-fixed);
  scrollbar-width: thin; /* Firefox */
  gap: var(--spacing-sm);
  background-color: var(--sidebar-bg);
  border-radius: var(--radius-lg);
}

.scrollable-section::-webkit-scrollbar {
  width: 2px; /* Ridiculously thin scrollbar */
}

/* Section Headings */
.sidebar-nav h4,
.chat-section-title,
.spaces-header h4 {
  font-size: var(--font-size-xxs);
  color: var(--text-secondary);
  margin: 0.75rem 0 0.5rem 0;
  padding: var(--spacing-xs) var(--spacing-sm);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-wide);
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: var(--z-sticky);
  background-color: var(--sidebar-bg);
  border-radius: var(--radius-md);
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
}

.sidebar.sidebar-locked .sidebar-nav h4 {
  background-color: var(--sidebar-bg);
}

/* List Structure */
.sidebar-nav ul,
.chat-section ul,
.spaces-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0.5rem 0;
}

.sidebar-nav li,
.chat-section li,
.spaces-list li {
  margin-bottom: var(--spacing-xs);
  position: relative;
}

/* Selection and Controls */
.sidebar-nav .nav-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-sm);
  border-radius: var(--radius-lg);
}

.select-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  gap: 12px;
  margin-bottom: 2px;
}

/* Navigation Links (Common Styles) */
.sidebar-nav a,
.chat-section a,
.space-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-sm);
  border-radius: var(--radius-lg);
  transition: all var(--transition-fast) var(--transition-bezier);
  transform-origin: left center;
  background-color: var(--glass-background);
  border: 1px solid var(--border-light);
  line-height: var(--line-height-snug);
  white-space: nowrap;
  overflow: hidden;
  gap: var(--spacing-xs);
}

.chat-section a {
  padding-right: var(--spacing-xs);
}

/* Link Hover and Active States */
.sidebar-nav a:hover,
.chat-section a:hover,
.space-link:hover {
  color: var(--text-primary);
  border: 1px solid var(--border-interactive-hover);
  background-color: var(--background-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all var(--transition-fast) var(--ease-out);
  box-shadow: var(--text-shadow-sm);
}

.sidebar-nav a.active,
.chat-section a.active,
.space-link.active {
  border: 1px solid var(--border-color);
  background-color: var(--sidebar-active);
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
  box-shadow: var(--shadow-primary);
  transform: var(--button-hover-transform);
  transition: all var(--transition-fast) var(--ease-out);
}

/* Chat and Space Titles */
.sidebar-nav a .chat-title,
.chat-section a .chat-title,
.space-title {
  font-size: var(--font-size-xs);
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  max-width: 150px;
  flex: 1;
  font-weight: var(--font-weight-medium);
}

/* Icons */
.chat-icon,
.space-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-sm);
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
  flex-shrink: 0;
  transition: color var(--transition-fast) var(--ease-out);
}

/* Icon hover and active states */
.sidebar-nav a:hover .chat-icon,
.sidebar-nav a.active .chat-icon,
.chat-section a:hover .chat-icon,
.chat-section a.active .chat-icon,
.space-link:hover .space-icon,
.space-link.active .space-icon,
.space-link.active .space-arrow {
  color: var(--text-primary);
}

/* Button Groups */
.sidebar-nav a .button-group,
.chat-section a .button-group {
  align-items: center;
  flex-shrink: 0;
  gap: 0;
  opacity: 0;
  transition: var(--transition-fast) var(--ease-out);
  margin: auto 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.sidebar-nav a:hover .button-group,
.chat-section a:hover .button-group {
  display: flex;
  opacity: 1;
}

.sidebar-nav a .button-group button,
.chat-section a .button-group button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: var(--spacing-xs);
  border-radius: var(--radius-md);
  background-color: transparent;
  color: var(--text-tertiary);
  transition: all var(--transition-fast) var(--ease-out);
  width: 24px;
  height: 24px;
}

.sidebar-nav a .button-group button:hover,
.chat-section a .button-group button:hover {
  background-color: var(--background-dark);
  color: var(--text-primary);
}

/* Form Styling */
.sidebar-nav form,
.chat-section form {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  margin: 0;
  background-color: var(--glass-background);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-focus);
  box-shadow: 0 0 0 1px var(--input-focus);
}

.sidebar-nav form input,
.chat-section form input {
  flex-grow: 1;
  background-color: transparent;
  color: var(--text-primary);
  border: none;
  border-radius: var(--radius-md);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-xs);
  font-family: var(--font-family);
  width: 100%;
  transition: all var(--transition-fast) var(--ease-out);
}

.sidebar-nav form input:focus,
.chat-section form input:focus {
  outline: none;
}

.sidebar-nav form input::placeholder,
.chat-section form input::placeholder {
  color: var(--text-tertiary);
  opacity: 0.7;
}

/* Selection mode styling */
.selection-mode .chat-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--background-white);
}

.selection-mode .chat-checkbox:checked {
  background-color: var(--background-white);
  border-color: var(--text-secondary);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.selection-mode .chat-checkbox:checked::after {
  content: '✓';
  position: absolute;
  color: var(--text-primary);
  font-size: 10px;
  left: 1px;
  top: 0px;
  bottom: 2px;
  font-weight: bold;
  opacity: 0;
  transform: scale(0.8);
  animation: checkmark 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes checkmark {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.selection-mode .chat-checkbox:hover {
  border-color: var(--text-secondary);
  background-color: var(--background-lighter);
  transform: scale(1.05);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.selection-mode .chat-checkbox:checked:hover {
  background-color: var(--text-secondary);
  border-color: var(--text-secondary);
  transform: scale(1.05);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Delete button styling */
.minimal-delete-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  background-color: var(--background-light);
  color: var(--text-secondary);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  opacity: 0;
  transform: translateX(-10px);
  animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.minimal-delete-btn:hover {
  background-color: var(--background-lighter);
  color: var(--text-primary);
}

.minimal-delete-btn svg {
  transition: transform 0.2s ease;
}

.minimal-delete-btn:hover svg {
  transform: scale(1.1);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Sidebar Trigger Area */
.sidebar-trigger-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 41px;
  height: 100vh;
  z-index: 10;
  display: none;
}

.sidebar-trigger-area.space-chat {
  width: 15px;
}

@media (min-width: 769px) {
  .sidebar-trigger-area {
    display: block;
  }
}

/* Hover states */
.sidebar.hover-open,
.sidebar.open {
  transform: translateX(0);
  opacity: 1;
  pointer-events: auto;
}

/* Loading styles */
.loading-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  gap: 0.75rem;
}

.loading-skeleton {
  width: 100%;
  height: 2.5rem;
  background: linear-gradient(
    90deg,
    var(--background-light) 0%,
    var(--background-lighter) 20%,
    var(--background-lightest) 40%,
    var(--background-lighter) 60%,
    var(--background-light) 80%
  );
  background-size: 200% 100%;
  animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  will-change: transform, opacity;
}

.loading-skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    var(--hover-overlay),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

.loading-skeleton:nth-child(1) { animation-delay: 0.1s; }
.loading-skeleton:nth-child(2) { animation-delay: 0.2s; }
.loading-skeleton:nth-child(3) { animation-delay: 0.3s; }
.loading-skeleton:nth-child(4) { animation-delay: 0.4s; }
.loading-skeleton:nth-child(5) { animation-delay: 0.5s; }
.loading-skeleton:nth-child(odd) { width: 95%; }
.loading-skeleton:nth-child(even) { width: 90%; }
.loading-skeleton:last-child { width: 85%; }

@keyframes loading {
  0% {
    background-position: 200% 0;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    background-position: -200% 0;
    opacity: 0.5;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Position variants for tooltips */
.sidebar .hover-text.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .hover-text.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .hover-text.top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.sidebar .hover-text.toggle {
  margin-top: -10px;
  margin-left: 80px;
}

.sidebar .hover-text.bottom {
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.sidebar *:hover > .hover-text {
  opacity: 1;
  visibility: visible;
}

.sidebar.collapsed .hover-text {
  left: 100%;
  margin-left: 10px;
}

/* Upgrade section */
.sidebar-upgrade {
  padding-left: 10px;
  padding-right: 10px;
}

.plan-upgrade-button {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  margin-bottom: var(--spacing-xs);
  border-radius: var(--radius-lg);
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  transition: background-color 0.2s ease;
  border: 1px solid var(--border-color);
  background-color: var(--glass-background);
  width: 100%;
  justify-content: flex-start;
  text-align: left;
}

.plan-upgrade-button:hover {
  background-color: var(--sidebar-hover);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
}

.feature-icon {
  min-width: 35px;
  margin-right: 5px;
  margin: auto 0;
}

.upgrade-main {
  display: flex;
  flex-direction: column;
}

.upgrade-main-text {
  font-size: var(--font-size-xs);
}

.upgrade-description {
  font-size: var(--font-size-xxs);
  color: var(--text-muted);
}

.upgrade-mark {
  font-size: var(--font-size-xs);
  background-color: var(--primary-color);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-lg);
  color: var(--text-on-primary);
  margin: auto 0;
  margin-left: auto;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-wide);
  box-shadow: var(--shadow-primary);
}

.sidebar-features {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-sm);
  gap: 2px;
}

/* No Chats Container */
.no-chats-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-lg);
  margin: var(--spacing-md) 0;
  background-color: var(--glass-background);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-light);
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
}

.refresh-chats-btn {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-normal) var(--bezier-smooth);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
}

.refresh-chats-btn:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-primary);
  transform: var(--button-hover-transform);
}

/* Recent Study Spaces specific styles */
.recent-spaces {
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-xs) var(--spacing-xs);
  padding-top: 0;
  position: relative;
  background-color: var(--glass-background);
  border-radius: var(--radius-lg);
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
  transition: all var(--transition-fast) var(--ease-out);
}

.spaces-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-xs);
  padding-top: 0;
  border-bottom: 1px solid var(--border-light);
}

.spaces-header .view-all {
  font-size: var(--font-size-xxs);
  color: var(--text-tertiary);
  text-decoration: none;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-full);
  background-color: transparent;
  border: 1px solid transparent;
  transition: all var(--transition-fast) var(--ease-out);
  font-weight: var(--font-weight-medium);
  margin: auto 0;
}

.spaces-header .view-all:hover {
  color: var(--text-primary);
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: var(--button-hover-transform);
}

/* Space arrow specific */
.space-arrow {
  margin-left: auto;
  color: var(--text-tertiary);
  opacity: 0.7;
  transition: transform var(--transition-fast) var(--ease-out),
              color var(--transition-fast) var(--ease-out),
              opacity var(--transition-fast) var(--ease-out);
  flex-shrink: 0;
}

.space-link:hover .space-arrow {
  transform: translateX(2px);
  color: var(--text-primary);
  opacity: 1;
}

/* Pin Button Styles */
.pin-btn {
  color: var(--text-tertiary);
  transition: color var(--transition-fast) var(--ease-out), transform var(--transition-fast) var(--ease-out);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-btn svg {
  transition: transform 0.2s ease, color 0.2s ease;
}

.pin-btn:hover svg {
  transform: scale(1.2);
  color: #FFB800;
}

/* Filled star icon for pinned chats */
.pin-btn svg[fill="currentColor"] {
  color: #FFB800 !important;
  filter: drop-shadow(0 0 1px rgba(255, 184, 0, 0.5));
}

/* Active pin button */
.pin-btn svg[data-active="true"],
.pin-btn .active {
  color: #FFB800 !important;
  fill: #FFB800 !important;
}

/* Pinned section styles */
.chat-section-title.pinned {
  color: var(--warning-color);
  font-weight: var(--font-weight-semibold);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: rgba(255, 184, 0, 0.08);
  border-radius: var(--radius-md);
  margin-bottom: 0.75rem;
}

.chat-section-title.pinned::before {
  content: "★";
  font-size: 0.9rem;
}

/* Pinned chat section container */
.chat-section.pinned {
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px dashed var(--border-light);
}

/* Empty Study Spaces */
.no-spaces-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) var(--spacing-sm);
  margin: var(--spacing-xs) 0;
  border-radius: var(--radius-lg);
  background-color: var(--glass-background);
  border: 1px dashed var(--border-light);
}

.no-spaces-container p {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin: 0;
}

.sidebar-create-space-btn {
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-md);
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-normal) var(--bezier-smooth);
}

.sidebar-create-space-btn:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  transform: var(--button-hover-transform);
}

.new-chat-button {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  border-radius: var(--radius-lg);
  padding: var(--spacing-xs) var(--spacing-md);
  background-color: var(--primary-dark);
  color: var(--text-on-primary);
  transition: all var(--transition-fast) var(--ease-out);
  border: 1px solid transparent;
  box-shadow: 0 2px 6px var(--shadow-primary);
}

.new-chat-button svg {
  margin: auto 0;
}

.new-chat-button:hover {
  box-shadow: 0 6px 12px var(--shadow-primary-hover);
  background-color: var(--primary-dark);
}

.new-chat-button:active {
  transform: var(--button-active-transform);
  box-shadow: 0 2px 4px var(--shadow-primary);
  filter: brightness(95%);
}

@media screen and (max-width: 600px) {
  .new-chat-button {
    margin-top: var(--spacing-xs);
  }
}

