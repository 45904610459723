.message-attachments {
    padding: 8px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 650px;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.message-attachments-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.message-attachment-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-md) var(--spacing-md);
    border-radius: var(--radius-2xl);
    background-color: var(--background-primary);
    border: 1px solid var(--border-primary);
    cursor: pointer;
    transition: background-color var(--transition-fast);
}

.message-attachment-item:hover {
    background-color: var(--background-hover);
}

.message-attachment-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    color: var(--text-secondary);
}

.message-attachment-item-icon svg {
    color: var(--primary-color);
}

.message-attachment-item-title {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}