.study-playground-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-modal);
  background: var(--background-main);
}

.study-playground-modal-content {
  height: calc(100vh - 60px);
  padding: 0;
  transform-origin: center;
  animation: expandFromCenter 0.3s
}

.study-playground-screen-size-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

/* Keyframe for expanding the modal content from the center */
@keyframes expandFromCenter {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Optional: Fade in the modal background */
  @keyframes modalFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

.study-playground-modal-content > div {
  height: 100%;
  display: flex;
  gap: 0;
}

/* Panel styling */
.panel {
  width: 100%;
  height: 100%;
  background: var(--glass-background);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  backdrop-filter: blur(8px);
  transition: all var(--transition-base);
}

.panel.action {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}

.panel.viewer {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 400px;
    border-right: none;
}

/* Resize handle styling */
.resize-handle {
  position: relative;
  width: 6px;
  background: transparent;
  margin: 0;
  cursor: col-resize;
  transition: all var(--transition-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.resize-handle::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: var(--border-color);
  border-radius: var(--radius-full);
  transition: all var(--transition-base);
}

.resize-handle:hover::before {
  background-color: var(--primary-color);
  height: 48px;
  width: 3px;
  box-shadow: 0 0 8px var(--primary-color);
}

/* Active state for resize handle */
.resize-handle:active::before {
  background-color: var(--primary-light);
  height: 64px;
  width: 3px;
  box-shadow: 0 0 12px var(--primary-light);
}

/* Panel hover effect */
.panel:hover {
  border-color: var(--border-interactive-hover);
}

/* Ensure smooth transitions */
* {
  transition-property: background-color, border-color, box-shadow, transform;
  transition-duration: var(--transition-base);
  transition-timing-function: var(--ease-out);
}

.study-playground-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.study-playground-header-text {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
    margin: 0;
}

.viewer-header,
.action-bar-header {
    display: flex;
    margin-bottom: var(--spacing-sm);
    padding: var(--spacing-sm);
    border-bottom: 1px solid var(--border-color);
    gap: var(--spacing-md);
}

.viewer-header h1 {
    margin: auto 0;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
}

.viewer-header h2 {
    margin: auto 0;
}

.create-resource-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    border: 1px solid var(--border-color);
    padding: var(--spacing-sm);
    border-radius: var(--radius-lg);
    margin-left: auto;
    font-size: var(--font-size-sm);
}

.create-resource-button:hover {
    background: var(--background-hover);
    border-color: var(--border-interactive-hover);
}

.viewer-content {
    padding: var(--spacing-md);
    height: 100%;
    position: relative;
    padding-bottom: 130px;
}

.viewer-listings {
    padding: var(--spacing-md);
    height: calc(100% - 60px);
    overflow-y: auto;
}

.viewer-footer {
    padding: var(--spacing-sm) var(--spacing-md);
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--spacing-md);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.Resources {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.playground-resources-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.playground-resources-list.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--text-muted);
    text-align: center;
}

.empty-resources-message {
    padding: var(--spacing-xl);
}

.empty-resources-message p {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-relaxed);
}

.empty-resources-message strong {
    display: block;
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-xs);
    color: var(--text-primary);
}

.playground-resource-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    border-radius: var(--radius-lg);
    background: var(--background-main);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all var(--transition-base);
}

.playground-resource-item:hover {
    background: var(--background-hover);
    border-color: var(--border-interactive-hover);
    transform: translateY(-1px);
    box-shadow: var(--shadow-elevated);
}

.playground-resource-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
}

.playground-resource-info {
    flex: 1;
}

.playground-resource-title {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
    margin: 0;
}

.playground-resource-arrow {
    color: var(--text-tertiary);
    transition: transform var(--transition-base);
}

.playground-resource-item:hover .playground-resource-arrow {
    transform: translateX(4px);
    color: var(--primary-color);
}

.panel.chat .messages-list {
    height: 90%;
    overflow-y: auto;
    padding: var(--spacing-lg) var(--spacing-xl);
    overflow-x: hidden;
    padding-bottom: 70px;
}

.panel.action {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.study-playground-modal .edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-lg);
    font-size: var(--font-size-md);
    margin-left: auto;
    border: 1px solid var(--border-color);
    padding: var(--spacing-sm);
}

.study-playground-modal .edit-button:hover {
    background: var(--background-hover);
    border-color: var(--border-interactive-hover);
    box-shadow: var(--shadow-elevated);
}

.edit-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.markdown-content {
    height: 100%;
    overflow-y: auto;
    padding: var(--spacing-md);
    border-radius: var(--radius-lg);
    transition: all var(--transition-base);
}

.markdown-content.editing {
    background: var(--background-main);
    height: 100%;
    border: 1px solid var(--border-color);
    cursor: text;
    outline: none;
    white-space: pre-wrap;
}

.markdown-content.editing:focus {
    border-color: var(--border-light);
}

.panel .dynamic-header {
    position: relative;
    margin-top: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: var(--spacing-md);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mini-chat-header {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-md);
    border-bottom: 1px solid var(--border-color);
}

.mini-chat-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: var(--radius-lg);
    font-size: var(--font-size-md);
    margin-left: auto;
    border: 1px solid var(--border-color);
    padding: var(--spacing-sm);
}

.mini-chat-chats {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    padding: var(--spacing-sm);
    position: absolute;
    top: 60px;
    right: 5px;
    background: var(--background-main);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-elevated);
    z-index: 100;
    max-height: 300px;
    overflow-y: auto;
    width: 200px;
}

.mini-chat-chat {
    padding: var(--spacing-md);
    border-radius: var(--radius-lg);
    cursor: pointer;
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
}

.mini-chat-chat:hover {
    background: var(--background-hover);
    border-color: var(--border-interactive-hover);
}

.mini-chat-title {
    flex: 1;
    text-align: center;
}

.mini-chat-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: var(--spacing-md);
}
.create-resource-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    border-radius: var(--radius-lg);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    gap: var(--spacing-md);
    z-index: var(--z-modal); /* Ensure it's above other elements */
}

.create-resource-modal {
    padding: var(--spacing-xl);
    min-width: 400px;
    max-width: 80%; /* Responsive width */
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
}

.create-resource-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-light);
    padding-bottom: var(--spacing-md);
}
.create-resource-modal-header h1{
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
}

.create-resource-modal-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.create-resource-modal-header-cancel {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: var(--spacing-sm);
    border-radius: var(--radius-full);
    transition: 
        background-color var(--transition-fast),
        transform var(--transition-fast);
}

.create-resource-modal-header-cancel:hover {
    background-color: var(--background-hover-secondary);
    transform: scale(1.05);
}

.create-resource-modal-header-cancel:active {
    transform: scale(0.95);
}

.create-resource-modal-content input,
.create-resource-modal-content select { /* Style the select as well */
    width: 100%;
    padding: var(--spacing-md);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-md);
    background-color: var(--input-bg);
    color: var(--text-primary);
    font-size: var(--font-size-base);
    transition: border-color var(--transition-base), box-shadow var(--transition-base);
}

.create-resource-modal-content input:focus,
.create-resource-modal-content select:focus{
    border-color: var(--border-primary);
    outline: none;
}

.create-resource-modal-content button {
        padding: var(--spacing-md);
        border: 1px solid var(--border-color);
        border-radius: var(--radius-md);
        background-color: var(--background-dark);
        color: var(--text-light);
        font-size: var(--font-size-base);
        cursor: pointer;
        transition: background-color var(--transition-base), border-color var(--transition-base);
}
.create-resource-modal-content button:hover {
    background-color: var(--background-hover);
    border-color: var(--border-interactive-hover);
}