.katex {
    position: relative;
    font-size: var(--font-size-lg);
    white-space: normal;
    text-indent: 0;
    color: var(--text-primary);
    padding: var(--spacing-md) 0;
}

.katex-display {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    font-size: var(--font-size-xl);
}

.katex .mord.mathdefault,
.katex .mop,
.katex .mbin {
    color: var(--text-primary);
    padding: 0.15rem 0;
}

.katex .mrel {
    color: var(--error-color);
    padding: 0.15rem 0;
}

/* Chemistry-specific styles */
.katex .mhchem {
    font-size: var(--font-size-lg);
    white-space: normal;
    word-wrap: break-word;
    padding: 0.25rem 0;
}

.katex .mhchem .mord {
    margin-right: var(--spacing-xs);
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 0.15rem 0;
}


