.thinking-panel {
    background-color: var(--background-light);
    backdrop-filter: var(--blur-sm);
    border-radius: var(--radius-2xl);
    border: 1px solid var(--border-color);
    padding: var(--spacing-md);
    transition: all var(--transition-base);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: var(--text-secondary);
    margin-bottom: var(--spacing-md);
    transform-origin: center bottom;
}

.thinking-panel:hover {
    border-color: var(--border-interactive-hover);
    box-shadow: var(--shadow-primary-hover);
}

.thinking-panel.thinking {
    border-color: var(--border-strong);
    color: var(--text-primary);
}

.thinking-panel.open {
    transition: all var(--transition-slow) var(--ease-out);
}

.thinking-panel__header {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-xs);
    position: sticky;
    top: 0;
    background: inherit;
    z-index: 1;
}

.thinking-panel__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: var(--hover-opacity);
    transition: opacity var(--transition-base);
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-3px); }
}

.thinking-panel__time {
    transition: color var(--transition-base);
    color: var(--text-primary);
}

.thinking-panel__close-button {
    margin-left: auto;
    transition: all var(--transition-base);
}

.thinking-panel__close-button.open {
    transform: rotate(180deg);
    color: var(--text-primary);
}

.thinking-panel_description {
    margin-top: var(--spacing-md);
    padding: 0 var(--spacing-xs);
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    animation: fadeIn var(--transition-slow) var(--ease-out);
}

.thinking-panel__content {
    margin-top: var(--spacing-lg);
    padding-top: var(--spacing-xs);
    padding-right: var(--spacing-md); /* Added to ensure text doesn't get hidden behind the scrollbar */
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-relaxed);
    animation: fadeIn var(--transition-slow) var(--ease-out);
    word-wrap: break-word;
    white-space: pre-wrap; /* Preserve natural spacing */
    overflow-wrap: break-word;
    max-height: calc(100% - 50px); /* Constrain the container */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    transform-origin: bottom;
    will-change: transform, opacity, max-height;
    transition-property: opacity, transform, max-height, margin-top, padding-top;
    transition-duration: var(--transition-slow);
    transition-timing-function: var(--ease-out);
}

.thinking-panel__content.closing {
    animation: slideUp var(--transition-slow) var(--ease-in-out) forwards;
    pointer-events: none; /* Prevent interaction during animation */
}

.thinking-panel__content::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

@media (max-width: 600px) {
    .thinking-panel {
        max-height: 400px;
    }
    .thinking-panel__header {
        position: relative;
    }
}

@keyframes fadeIn {
    from { 
        opacity: 0;
        transform: translateY(-4px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        opacity: 1;
        transform: translateY(0);
        max-height: 1000px;
        margin-top: var(--spacing-lg);
    }
    30% {
        opacity: 0.7;
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
        max-height: 0;
        margin-top: 0;
        padding-top: 0;
    }
}
