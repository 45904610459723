.upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-modal);
  backdrop-filter: var(--blur-md);
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.21, 1, 0.55, 1) forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.upload-modal-content {
  background: var(--glass-background);
  border-radius: var(--radius-xl);
  border: 1px solid var(--border-glass);
  box-shadow: var(--shadow-elevated);
  width: min(90%, 800px);
  height: min(90%, 600px);
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  transform: scale(0.96) translateY(8px);
  opacity: 0;
  animation: modalEnter 0.4s cubic-bezier(0.21, 1, 0.55, 1) forwards;
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
}

@keyframes modalEnter {
  from {
    transform: scale(0.96) translateY(8px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.upload-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--border-light);
}

.upload-modal-header h2 {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  letter-spacing: var(--letter-spacing-tight);
}

.upload-modal-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-lg);
}

.error-message {
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid var(--error-color);
  color: var(--error-color);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  animation: errorShake 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes errorShake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
  20%, 40%, 60%, 80% { transform: translateX(2px); }
}

.upload-display {
  position: relative;
  max-width: 400px;
  min-height: 140px;
  margin: 0 auto;
  padding: var(--spacing-md);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 2px dashed var(--border-interactive);
  border-radius: var(--radius-lg);
  background-color: var(--background-light);

  cursor: pointer;
  transition: background-color 0.25s var(--ease-in-out),
              border-color 0.25s var(--ease-in-out),
              transform 0.25s var(--ease-in-out);
}


.file-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-md);
}

.upload-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);
}

.upload-text-title {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  margin: 0;
}

.upload-text-info {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  letter-spacing: 0.02em;
}

.upload-modal-files {
  margin-top: auto;
  gap: var(--spacing-xs);
}

.upload-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--border-light);
}

.primary-button, .secondary-button {
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  min-width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  transition: all 0.25s cubic-bezier(0.21, 1, 0.55, 1);
  letter-spacing: 0.01em;
  position: relative;
  overflow: hidden;
}

.primary-button {
  background: var(--primary-gradient);
  color: var(--text-light);
  border: none;
  box-shadow: 0 2px 8px var(--shadow-primary), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.primary-button:hover {
  box-shadow: 0 4px 12px var(--shadow-primary-hover), 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: var(--button-hover-transform);
}

.primary-button:active {
  transform: var(--button-active-transform);
  box-shadow: 0 3px 8px var(--shadow-primary), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition-duration: 0.1s;
}

.primary-button:disabled {
  background: linear-gradient(135deg, #d1d5db 0%, #9ca3af 100%);
  color: var(--text-light);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  opacity: 0.7;
}

.primary-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  opacity: 0;
  transition: opacity 0.2s ease;
}

.primary-button:hover::after {
  opacity: 1;
}

.secondary-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1.5px solid var(--border-interactive);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.secondary-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: var(--button-hover-transform);
}

.secondary-button:active {
  transform: var(--button-active-transform);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition-duration: 0.1s;
}

.secondary-button:disabled {
  background-color: var(--background-light);
  color: var(--text-tertiary);
  border-color: var(--border-color);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  opacity: 0.7;
}

.upload-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.upload-loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.upload-loading-dots span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--text-light);
  animation: loadingDot 1.4s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  opacity: 0.9;
}

.upload-loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.upload-loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loadingDot {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
}

@media (max-width: 768px) {
  .upload-modal-overlay {
    padding: var(--spacing-sm);
    align-items: flex-end;
  }
  
  .upload-modal-content {
    width: 100%;
    max-width: 100%;
    padding: var(--spacing-lg);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: var(--radius-xl) var(--radius-xl) 0 0;
    animation: modalEnterMobile 0.4s cubic-bezier(0.21, 1, 0.55, 1) forwards;
  }
  
  @keyframes modalEnterMobile {
    from { transform: translateY(50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .upload-modal-header h2 {
    font-size: var(--font-size-lg);
  }
  
  .upload-dropzone {
    padding: var(--spacing-md);
    min-height: 160px;
  }
  
  .upload-modal-footer {
    flex-direction: column-reverse;
    gap: var(--spacing-sm);
  }
  
  .primary-button, .secondary-button {
    width: 100%;
  }
}
