body, html {
  font-family: var(--font-primary);
  background: var(--background-light);
  margin: 0;
  padding: 0;
  min-width: 100vw;
}

.auth-container {
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 50px;
}

.auth-container .auth-form {
  border-radius: var(--radius-2xl);
  padding: 3.5rem;
  width: 100%;
  max-width: 430px;
  transition: transform var(--transition-base), box-shadow var(--transition-base);
  text-align: center;
}

.auth-container .auth-form p {
  font-size: var(--font-size-sm);
  line-height: 1.5;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

.auth-container .branding {
  text-align: center;
  margin-bottom: 3rem;
}

.auth-container .logo {
  font-size: 3rem;
  font-weight: 800;
  color: var(--primary-color);
  text-decoration: none;
  display: block;
  margin: 0 auto 1rem;
  transition: all var(--transition-base);
  text-align: center;
  letter-spacing: -1px;
  position: relative;
}

.auth-container .logo:hover {
  color: var(--primary-dark);
  transform: scale(1.05);
}

.auth-container .logo::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: var(--radius-sm);
  transition: all var(--transition-base);
}

.auth-container .logo:hover::after {
  width: 80px;
  background-color: var(--primary-dark);
}

.auth-container .form-group {
  margin-bottom: var(--spacing-sm);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.auth-container .form-group label {
  display: block;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: var(--font-size-base);
  letter-spacing: 0.5px;
}

.auth-container .form-group input::placeholder {
  font-size: var(--font-size-sm);
  letter-spacing: 0.25px;
  color: var(--text-muted);
}

.auth-container .form-group input {
  width: 100%;
  max-width: 300px;
  padding: 0.9rem;
  background-color: var(--background-dark);
  color: var(--text-primary);
  border: 1px solid var(--border-strong);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  transition: all var(--transition-base);
  margin-top: 1rem;
}

.auth-container .form-group input:focus {
  outline: none;
  border-color: var(--border-strong);
  border-width: 2px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.auth-container .form-group input:focus::placeholder {
  opacity: 1;

}

.auth-container .form-group input:-webkit-autofill,
.auth-container .form-group input:-webkit-autofill:hover,
.auth-container .form-group input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-primary);
  -webkit-box-shadow: 0 0 0px 1000px var(--background-dark) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.auth-container .password-input-wrapper {
  position: relative;
}

.auth-container .toggle-password {
  position: absolute;
  right: 20px;
  top: calc(50% + 0.5rem); /* Adjusted to account for margin-top on input */
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  opacity: 0.6;
  transition: opacity var(--transition-base);
  font-size: var(--font-size-lg);
}

.auth-container .toggle-password:hover {
  opacity: 1;
}

.auth-container .submit-button {
  margin: 2rem auto 1rem;
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  background-color: var(--background-dark);
  color: var(--text-primary);
  border: 1px solid var(--border-strong);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  font-family: var(--font-secondary);
  cursor: pointer;
  transition: all var(--transition-base);
  position: relative;
  overflow: hidden;
  display: block;
  letter-spacing: var(--letter-spacing-wide);
  text-transform: uppercase;
}

.auth-container .submit-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-elevated);
  transform: var(--button-hover-transform);
  border: 2px solid var(--border-strong);
}

.auth-container .submit-button:disabled {
  background-color: var(--background-light);
  border-color: var(--border-color);
  color: var(--text-muted);
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
  transform: none;
  box-shadow: none;
}

.auth-container .submit-button:disabled:hover {
  transform: none;
  box-shadow: none;
  background-color: var(--background-light);
}

.auth-container .error-message {
  color: var(--error-color);
  background-color: var(--error-bg, rgba(239, 68, 68, 0.1));
  margin-bottom: var(--spacing-lg);
  text-align: center;
  font-weight: var(--font-weight-medium);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  border: 1px solid var(--error-border, rgba(239, 68, 68, 0.2));
  font-size: var(--font-size-sm);
  line-height: var(--line-height-snug);
  animation: fadeIn var(--transition-base) var(--ease-out);
  box-shadow: var(--shadow-sm);
  max-width: 300px;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}

.auth-container .success-message {
  color: var(--success-color);
  background-color: var(--success-bg, rgba(34, 197, 94, 0.1));
  margin-bottom: var(--spacing-lg);
  text-align: center;
  font-weight: var(--font-weight-medium);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  border: 1px solid var(--success-border, rgba(34, 197, 94, 0.2));
  font-size: var(--font-size-sm);
  line-height: var(--line-height-snug);
  animation: fadeIn var(--transition-base) var(--ease-out);
  box-shadow: var(--shadow-sm);
  max-width: 300px;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}

.auth-container .auth-link {
  text-align: center;
  margin-top: 2rem;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.auth-container .auth-link a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  transition: color var(--transition-base);
  position: relative;
}

.auth-container .auth-link a:hover {
  color: var(--primary-dark);
}

.auth-container .auth-form h2 {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
  color: var(--text-dark);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-tight);
  text-shadow: var(--text-shadow-sm);
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  display: block;
}

@media (min-width: 1024px) {
  .auth-container {
    gap: 100px;
  }

  .auth-container .auth-form {
    padding: 4rem;
    max-width: 520px;
  }

  .auth-container .auth-form h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .auth-container .auth-form {
    padding: 1.5rem;
  }

  .auth-container .auth-form p {
    font-size: var(--font-size-sm);
  }

  .auth-container .form-group label {
    font-size: var(--font-size-lg);
  }

  .auth-container .form-group input {
    font-size: 16px;
    padding: 0.9rem;
  }

  .auth-container .checkbox-group label {
    font-size: var(--font-size-sm);
  }
}


.auth-container .cognora-logo {
  width: 200px;
  pointer-events: none;
  user-select: none;
}

@media (max-width: 800px) {
  .auth-container {
    flex-direction: column;
    gap: 2rem;
  }
  
  .auth-container .auth-form {
    margin-top: -2rem;
  }

  .auth-container .cognora-logo{
    width: 130px;
  }
}


@media (max-width: 800px) {
  .auth-container .cognora-logo {
    width: 80px;
    margin-top: -1rem;
    pointer-events: none;
    user-select: none;
  }

  .auth-container .logo::after {
   display: none;
  }
  
  .auth-container .auth-form {
    margin-top: -5rem;
  }
}

.auth-container .checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
  gap: 0.5rem;
  max-width: 300px;
  margin-top: 1.5rem;
}

.auth-container .checkbox-group input[type="checkbox"] {
  width: auto;
  min-width: 8px;
  max-width: 12px;
  height: 12px;
  margin: 0;
  cursor: pointer;
  flex-shrink: 0;
  appearance: none;
  background-color: var(--background-main);
  border: 1px solid var(--border-interactive);
  border-radius: var(--radius-md);
  transition: all var(--transition-base);
  position: relative;
}

.auth-container .checkbox-group input[type="checkbox"]:checked {
  border-color: var(--primary-color);
}

.auth-container .checkbox-group input[type="checkbox"]:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-primary);
  font-size: 12px;
  line-height: 1;
}

.auth-container .checkbox-group input[type="checkbox"]:hover:not(:checked) {
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-sm);
}

.auth-container .checkbox-group input[type="checkbox"]:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--input-focus);
}

.auth-container .checkbox-group label {
  font-size: var(--font-size-sm);
  font-weight: 400;
  color: var(--text-secondary);
  cursor: pointer;
  white-space: normal;
  line-height: 1.4;
  text-align: left;
}

.auth-container .checkbox-group a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  transition: color var(--transition-base);
  display: inline;
}

.auth-container .checkbox-group a:hover {
  color: var(--primary-dark);
}

.auth-links{
  margin-top: 0.6rem;
}

.auth-footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--text-muted);
}

.auth-footer a {
  color: var(--text-muted);
  text-decoration: none;
  transition: color var(--transition-base);
}

.auth-footer a:hover {
  color: var(--primary-color);
}

.auth-footer .separator {
  margin: 0 10px;
}

@media (max-width: 480px) {
  .auth-footer {
    position: relative;
    margin-top: 2rem;
    font-size: var(--font-size-xs);
  }
}

.email-display-container {
  align-items: center;
  gap: 0.1rem;
}

.email-display {
  color: var(--text-primary);
  margin: 0;
  font-size: var(--font-size-base);
}

.back-to-email {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: var(--font-size-sm);
  cursor: pointer;
  padding: 4px 8px;
  font-weight: 600;
  transition: all var(--transition-base);
}

.back-to-email:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}
