.search-item-container {
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: var(--radius-xl);
  background-color: var(--background-light);
  border: none;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  transition: all var(--transition-base);
}

.search-item-header {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-sm);
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--border-light);
  position: relative;
}

.search-item-header-icon {
  color: var(--primary-color);
  font-size: var(--font-size-base);
  margin-right: var(--spacing-sm);
  display: flex;
  align-items: center;
}

.search-item-header h3 {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin: 0;
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-wider);
}

.search-item-count {
  font-size: var(--font-size-xxs);
  color: var(--text-tertiary);
  font-weight: var(--font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-hover);
  min-width: 20px;
  height: 20px;
  padding: 0 var(--spacing-xs);
  border-radius: var(--radius-full);
}

.search-items-scroll-area {
  display: flex;
  overflow-x: auto;
  padding: var(--spacing-xs) 0;
  gap: var(--spacing-sm);
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  -webkit-overflow-scrolling: touch;
  margin: 0 -4px;
  padding: 4px;
  scroll-padding: var(--spacing-sm);
  scroll-snap-type: x proximity;
}

.search-items-scroll-area::-webkit-scrollbar {
  height: 4px;
}

.search-items-scroll-area::-webkit-scrollbar-track {
  background: transparent;
}

.search-items-scroll-area::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--radius-full);
  opacity: 0.6;
}

.search-items-scroll-area::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.search-item {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-md);
  border-radius: var(--radius-lg);
  cursor: pointer;
  transition: all var(--transition-fast);
  text-decoration: none;
  color: var(--text-primary);
  border: none;
  background-color: var(--background-main);
  flex-shrink: 0;
  width: 180px;
  min-height: 90px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
}

.search-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, transparent 0%, rgba(var(--background-light-rgb), 0.05) 100%);
  pointer-events: none;
}

.search-item:hover {
  background-color: var(--background-hover);
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.search-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--spacing-xs);
}

.search-item-icon {
  color: var(--primary-color);
  font-size: var(--font-size-base);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.search-item-favicon {
  width: 20px;
  height: 20px;
  border-radius: var(--radius-sm);
  object-fit: contain;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

.search-item-external {
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
  opacity: 0.5;
  transition: all var(--transition-fast);
}

.search-item:hover .search-item-external {
  color: var(--primary-color);
  opacity: 0.9;
  transform: translateY(-1px) scale(1.1);
}

.search-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.search-item-title {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-snug);
  flex-grow: 1;
  margin-bottom: var(--spacing-xs);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.search-item-url {
  font-size: var(--font-size-xxs);
  color: var(--text-tertiary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: auto;
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--border-light);
}

/* Dark mode adjustments */
[data-theme="dark"] .search-item-container {
  background-color: var(--background-light);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

[data-theme="dark"] .search-item {
  background-color: var(--background-main);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

[data-theme="dark"] .search-item:hover {
  background-color: var(--background-hover);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.hidden {
  display: none;
}

/* Sources Container Styling */
.sources-container {
  margin-bottom: var(--spacing-md);
  border-radius: var(--radius-xl);
  background-color: var(--background-light);
  border: none;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  transition: all var(--transition-base);
  overflow: hidden;
}

.sources-container.collapsed {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.sources-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md);
  border-bottom: 1px solid transparent;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-fast);
}

.sources-container.expanded .sources-header {
  border-bottom-color: var(--border-light);
}

.sources-header:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.sources-header-left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.sources-header-icon {
  color: var(--primary-color);
  font-size: var(--font-size-base);
  margin-right: var(--spacing-sm);
  display: flex;
  align-items: center;
  transition: transform var(--transition-fast);
}

.sources-container.expanded .sources-header-icon {
  transform: scale(1.1);
}

.sources-header h3 {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin: 0;
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-wider);
}

.sources-count {
  font-size: var(--font-size-xxs);
  color: var(--text-tertiary);
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-hover);
  min-width: 20px;
  height: 20px;
  padding: 0 var(--spacing-xs);
  border-radius: var(--radius-full);
  margin-left: var(--spacing-xs);
  transition: background-color var(--transition-fast);
}

.sources-container.expanded .sources-count {
  background-color: var(--background-hover-primary);
  color: var(--primary-color);
}

.sources-collapse-icon {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s var(--ease-out);
}

.sources-container.collapsed .sources-collapse-icon {
  transform: rotate(-90deg);
}

.sources-content {
  padding: var(--spacing-md);
  transition: max-height 0.3s ease, opacity 0.2s ease, transform 0.3s ease;
}

.sources-scroll-area {
  display: flex;
  overflow-x: auto;
  gap: var(--spacing-sm);
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  -webkit-overflow-scrolling: touch;
  margin: 0 -4px;
  padding: 4px;
  scroll-padding: var(--spacing-sm);
  scroll-snap-type: x proximity;
}

.sources-scroll-area::-webkit-scrollbar {
  height: 4px;
}

.sources-scroll-area::-webkit-scrollbar-track {
  background: transparent;
}

.sources-scroll-area::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--radius-full);
  opacity: 0.6;
}

.sources-scroll-area::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.source-item {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-md);
  border-radius: var(--radius-lg);
  cursor: pointer;
  transition: all var(--transition-fast);
  text-decoration: none;
  color: var(--text-primary);
  border: none;
  background-color: var(--background-main);
  flex-shrink: 0;
  width: 180px;
  min-height: 90px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
}

.source-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, transparent 0%, rgba(var(--background-light-rgb), 0.05) 100%);
  pointer-events: none;
}

.source-item:hover {
  background-color: var(--background-hover);
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.source-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--spacing-xs);
}

.source-item-icon {
  color: var(--primary-color);
  font-size: var(--font-size-base);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.source-item-favicon {
  width: 20px;
  height: 20px;
  border-radius: var(--radius-sm);
  object-fit: contain;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

.source-item-external {
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
  opacity: 0.5;
  transition: all var(--transition-fast);
}

.source-item:hover .source-item-external {
  color: var(--primary-color);
  opacity: 0.9;
  transform: translateY(-1px) scale(1.1);
}

.source-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.source-item-title {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-snug);
  flex-grow: 1;
  margin-bottom: var(--spacing-xs);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.source-item-url {
  font-size: var(--font-size-xxs);
  color: var(--text-tertiary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: auto;
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--border-light);
}

/* Collapsible animation */
.sources-container.collapsed .sources-content {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  transform: translateY(-8px);
}

.sources-container.expanded .sources-content {
  max-height: 300px;
  opacity: 1;
  transform: translateY(0);
}

/* Dark mode adjustments */
[data-theme="dark"] .sources-container {
  background-color: var(--background-light);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

[data-theme="dark"] .sources-header:hover {
  background-color: rgba(255, 255, 255, 0.03);
}

[data-theme="dark"] .source-item {
  background-color: var(--background-main);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

[data-theme="dark"] .source-item:hover {
  background-color: var(--background-hover);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.hidden {
  display: none !important;
}

/* Animation Keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--primary-color), 0.4);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(var(--primary-color), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--primary-color), 0);
  }
}

.source-item-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sources-scroll-area,
  .search-items-scroll-area {
    scroll-snap-type: x mandatory;
  }
  
  .source-item,
  .search-item {
    width: 160px;
    min-height: 84px;
  }
}

@media (max-width: 480px) {
  .source-item,
  .search-item {
    width: 140px;
  }
  
  .sources-header,
  .search-item-header {
    padding: var(--spacing-sm);
  }
}
