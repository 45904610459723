/* Modal Overlay */
@keyframes modalExpand {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.text-viewer-modal {
  position: fixed;
  inset: 0;
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--glass-background);
  backdrop-filter: blur(10px);
  animation: modalExpand 0.15s var(--transition-bezier);
  flex-direction: column;
  box-shadow: var(--shadow-elevated);
  border: 1px solid var(--border-glass);
  border-radius: var(--radius-2xl);
  will-change: transform, opacity;
}

/* Unified Modal Container */
.text-viewer-modal .modal-content {
  position: relative;
  max-width: clamp(300px, 80vw, 800px);
  max-height: calc(85vh);
  margin: var(--spacing-md);
  overflow: hidden;
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-lg);
  will-change: transform;
}

/* Integrated Header Section */
.text-viewer-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) 0;
  background: var(--background-header);
  border-bottom: 1px solid var(--border-subtle);
}

.text-viewer-modal .modal-header h1 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  letter-spacing: var(--letter-spacing-tight);
  margin: 0;
  line-height: var(--line-height-snug);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

/* Enhanced Close Button */
.text-viewer-modal .close-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--background-hover);
  border: 1.5px solid var(--border-interactive);
  border-radius: var(--radius-full);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-base) var(--transition-bezier);
  flex-shrink: 0;
}

.text-viewer-modal .close-button:hover {
  color: var(--primary-color);
  box-shadow: var(--shadow-primary-hover);
  transform: rotate(90deg);
}

.text-viewer-modal .close-button:active {
  transform: scale(0.95) rotate(90deg);
}

.text-viewer-modal .close-button:focus-visible {
  outline: none;
  box-shadow: var(--focus-ring);
}

/* Seamless Content Display */
.text-viewer-modal .content-display {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  max-height: calc(70vh);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  line-height: var(--line-height-relaxed);
  white-space: pre-wrap;
  box-sizing: border-box;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-viewer-modal .modal-content {
    max-width: 95vw;
    margin: var(--spacing-xs);
    border-radius: var(--radius-lg);
  }

  .text-viewer-modal .modal-header {
    padding: var(--spacing-sm);
  }

  .text-viewer-modal .modal-header h1 {
    font-size: var(--font-size-base);
    max-width: 70%;
  }

  .text-viewer-modal .close-button {
    width: 32px;
    height: 32px;
  }

  .text-viewer-modal .content-display {
    padding: var(--spacing-sm);
    max-height: calc(100vh - 140px);
  }
}
