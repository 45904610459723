/* Smooth Animations */
@keyframes fadeInDisplay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  
@keyframes slideHeader {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced Default Display */
.default-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: var(--text-primary);
  transition: all 0.3s var(--transition-bezier);
  opacity: 0;
  animation: fadeInDisplay 0.3s var(--transition-bezier) forwards;
  margin-top: min(15vh, 150px);
  z-index: 2;
}

.welcome-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 720px;
}

.welcome-container img {
  display: none;
}

.dynamic-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
}

.dynamic-header {
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  max-width: 90%;
  opacity: 0;
  transform: translateY(15px);
  animation: slideHeader 0.5s var(--ease-out) 0.3s forwards;
  background: linear-gradient(135deg, var(--primary-light) 0%, var(--primary-color) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  font-size: var(--font-size-3xl);
  letter-spacing: -0.02em;
  text-shadow: 0 1px 2px var(--shadow-color);
}

.dynamic-subtext {
  font-size: var(--font-size-md);
  color: var(--text-tertiary);
  line-height: var(--line-height-relaxed);
  opacity: 0;
  padding-left: 0.5rem;
  animation: slideHeader 0.5s var(--ease-out) 0.5s forwards;
  max-width: 85%;
  letter-spacing: var(--letter-spacing-normal);
  font-weight: var(--font-weight-normal);
  transition: color 0.3s var(--ease-out);
  text-shadow: var(--text-shadow-sm);
}

@media (max-width: 768px) {
  .default-display {
    margin-top: min(15vh, 100px);
  }
  .dynamic-header-container {
    padding: 0 var(--spacing-xl);
  }
}

@media (max-width: 600px) {  
  .welcome-container {
    padding: 0 var(--spacing-xs);
  }

  .welcome-container img {
    display: block;
  }
}

@media (max-width: 500px) {
  .dynamic-header-container {
    padding: 0 var(--spacing-xs);
  }
}