.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-overlay);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-modal);
    backdrop-filter: var(--blur-sm);
  }
  
  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-spinner {
    border: 3px solid var(--border-light);
    border-top: 3px solid var(--primary-color);
    border-radius: var(--radius-full);
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .loading-content p {
    margin-top: var(--spacing-md);
    font-size: var(--font-size-base);
    color: var(--text-primary);
    font-weight: var(--font-weight-medium);
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }