/* Quiz item styles */
.quiz-resource-item {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  gap: var(--spacing-sm);
  border-radius: var(--radius-xl);
  background: var(--background-light);
  transition: all var(--transition-base) var(--transition-bezier);
  cursor: pointer;
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
  margin-bottom: var(--spacing-sm);
  width: 300px;
}

.quiz-resource-item:hover {
  background: var(--background-hover);
  box-shadow: var(--shadow-primary), 0 4px 12px rgba(0, 0, 0, 0.05);
}

.quiz-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  background: var(--background-hover-secondary);
  border-radius: var(--radius-lg);
  flex-shrink: 0;
  min-width: 40px;
  min-height: 40px;
}

.quiz-title {
  flex-grow: 1;
  overflow: hidden;
}

.quiz-title span {
  margin: 0;
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quiz-resource-item:active {
  transform: var(--button-active-transform);
  box-shadow: none;
}

.quiz-resource-item:hover .resource-arrow {
  color: var(--text-primary);
}