.sitewide-banner {
  background: var(--error-color);
  color: var(--text-light);
  padding: var(--spacing-md);
  text-align: center;
  font: var(--font-weight-medium) var(--font-size-sm) var(--font-primary);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--z-sticky);
  box-shadow: var(--shadow-base);
  transition: all var(--transition-base);
  gap: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sitewide-banner .link-like-button {
  color: inherit;
  text-decoration: underline;
  transition: opacity var(--transition-fast);
}

.sitewide-banner .link-like-button:hover {
  opacity: var(--hover-opacity);
}

[data-theme="dark"] .sitewide-banner {
  box-shadow: var(--shadow-dark);
}

.banner-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.banner-close-button {
  background: none;
  border: none;
  font-size: var(--font-size-lg);
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-sm);
  color: inherit;
  transition: opacity var(--transition-fast);
}

.banner-close-button:hover {
  opacity: var(--hover-opacity);
}
