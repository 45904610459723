.avatar-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    overflow: hidden;
}

.avatar-wrapper:hover {
    transform: scale(1.05);
}
  
.avatar-wrapper.not-settings-modal {
    width: 40px;
    height: 40px;
}