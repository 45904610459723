.chat-header {
  padding: 0 var(--spacing-sm);
  padding-left: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  z-index: var(--z-sticky);
  position: absolute;
  gap: 0.5rem;
  background-color: transparent;
  width: 100%;
}

.chat-header.title-header {
  position: relative;
  backdrop-filter: var(--blur-md);
  background-color: var(--background-main);
}

.chat-header.sidebar-open.title-header {
  margin-left: 250px;
  width: calc(100% - 250px);
  transition:
    left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
    filter 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 768px) {
  .chat-header {
    padding-right: var(--spacing-xs);
    background-color: var(--background-main);
    position: relative;
  }
}



.header-left,
.header-right {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.header-right {
  gap: var(--spacing-md);
}

.header-center {
  flex-grow: 1;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 650px) {
  .chat-header.sidebar-open {
    margin-left: 0px;
    transition:
      left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      filter 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .chat-header {
    padding-left: var(--spacing-md);
    padding-right: var(--spacing-sm);
  }

  .header-right {
    gap: var(--spacing-md);
  }
}

.header-button {
  color: var(--text-primary);
  border-radius: var(--radius-md);
  position: relative;
}

.header-button svg {
  color: var(--primary-color);
}

.header-button.hoverable:hover svg {
  color: var(--primary-dark);
}

.header-button.hoverable:hover {
  background-color: var(--background-hover);
}

.settings-button {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  background: none;
  border: none;
}

.header-logo {
  max-height: 40px;
  margin-top: 5px;
  padding-left: 10px;
}

@media (max-width: 600px) {
  .header-logo {
    min-height: 40px;
    max-height: 40px;
    max-width: 70px;
  }

  .header-center {
    max-width: 40%;
  }
}

@media (max-width: 600px) and (min-height: 900px) {
  .header-logo {
    min-height: 40px;
    max-height: 40px;
    max-width: 70px;
  }
}

.auth-button {
  display: flex;
  padding: 8px 16px;
  color: var(--text-dark);
  border: 1px solid var(--border-interactive);
  border-radius: var(--radius-full);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  text-align: center;
  transition: all 0.3s var(--ease-out);
  box-shadow: 0 2px 4px var(--shadow-color);
  cursor: pointer;
  margin-top: var(--spacing-xs);
  gap: var(--spacing-xs);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(4px);
}

.auth-button:hover {
  background: linear-gradient(to right, var(--background-hover-primary), var(--background-hover-secondary));
  border-color: var(--border-primary-hover);
  box-shadow: 0 4px 8px var(--shadow-primary-hover);
  transform: translateY(-1px);
}

.auth-button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px var(--shadow-primary);
}

.auth-button.sign-in {
  color: var(--text-on-primary);
  background: var(--primary-gradient);
  border: 1px solid var(--primary-light);
  padding: var(--spacing-sm) var(--spacing-md);
  margin-right: 10px;
  transition: all 0.3s var(--ease-out);
  box-shadow: 0 2px 6px var(--shadow-primary);
}

.auth-button.sign-in:hover {
  background: linear-gradient(135deg, var(--primary-light) 0%, var(--primary-color) 100%);
  border-color: var(--primary-color);
  box-shadow: 0 4px 8px var(--shadow-primary-hover);
}

.auth-button.sign-in svg {
  color: rgba(255, 255, 255, 0.8);
  display: none;
}


.logo-text {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  letter-spacing: var(--letter-spacing-tight);
  transition: color var(--transition-base);
  display: inline-block;
  margin: 0;
  padding: 0 var(--spacing-sm);
  line-height: var(--line-height-tight);
  text-shadow: var(--text-shadow-sm);
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  z-index: 1;
}

[data-theme="dark"] .logo-text {
  text-shadow: 0 1px 3px var(--shadow-primary);
}

.return-to-chats {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  color: var(--text-tertiary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
  transition: all var(--transition-fast) var(--ease-out);
  text-decoration: none;
  background: var(--background-light);
  border: 1px solid var(--border-light);
}

/* More specific selector for the header NavLink */
.return-to-chats[data-header-link="true"] {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  color: var(--text-tertiary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
  transition: all var(--transition-fast) var(--ease-out);
  text-decoration: none;
  background: var(--background-light);
  border: 1px solid var(--border-light);
}

.return-to-chats:hover {
  background: var(--background-hover);
  transform: translateX(-1px);
  color: var(--text-primary);
  box-shadow: var(--shadow-sm);
}

/* More specific hover state for the header NavLink */
.return-to-chats[data-header-link="true"]:hover {
  background: var(--background-hover);
  transform: translateX(-1px);
  color: var(--text-primary);
  box-shadow: var(--shadow-sm);
}

@media (max-width: 768px) {
  .return-to-chats {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-xs);
  }
  
  .return-to-chats[data-header-link="true"] {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-xs);
  }
}
