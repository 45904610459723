.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 0px);
  right: 0;
  background: var(--dropdown-bg);
  backdrop-filter: blur(10px);
  border-radius: var(--radius-2xl);
  box-shadow: var(--shadow-elevated);
  min-width: 200px;
  padding: var(--spacing-sm) 0;
  z-index: var(--z-dropdown);
  transform-origin: top right;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95) translateY(-10px);
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  border: 1px solid var(--border-color);
  overflow: hidden;
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1) translateY(0);
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin: 2px 8px;
  color: var(--dropdown-text);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  transition: all 0.15s ease;
  cursor: pointer;
  gap: var(--spacing-sm);
  border-radius: var(--radius-lg);
  position: relative;
  overflow: hidden;
  width: calc(100% - 16px);
}

.dropdown-item:hover {
  background: var(--dropdown-hover);
}

.dropdown-item.active {
  background: var(--dropdown-active);
  color: var(--dropdown-text-active);
  font-weight: var(--font-weight-semibold);
}

.dropdown-item svg {
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  transition: all 0.2s ease;
}

.user-dropdown-icon {
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-dropdown-text {
  flex-grow: 1;
  text-align: left;
  letter-spacing: -0.01em;
}

.dropdown-divider {
  height: 1px;
  margin: 8px 16px;
  background: linear-gradient(
    to right,
    transparent,
    var(--border-primary),
    transparent
  );
  border: none;
}

@media (max-width: 768px) {
  .dropdown-item {
    padding: 10px 14px;
    font-size: var(--font-size-xs);
    margin: 2px 6px;
  }
  
  .dropdown-divider {
    margin: 6px 12px;
  }
}