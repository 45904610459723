@tailwind base;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 99%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 98%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 45%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 346.8 77.2% 49.8%;
    --chart-1: 347 77% 50%;
    --chart-2: 352 83% 91%;
    --chart-3: 350 80% 72%;
    --chart-4: 351 83% 82%;
    --chart-5: 349 77% 62%;
  }

  [data-theme="dark"] {
    --background: 240 5% 6%;
    --foreground: 60 5% 90%;
    --primary: 240 0% 90%;
    --primary-foreground: 60 0% 0%;
    --secondary: 240 4% 15%;
    --secondary-foreground: 60 5% 85%;
    --accent: 240 0% 13%;
    --accent-foreground: 60 0% 100%;
    --destructive: 0 60% 50%;
    --destructive-foreground: 0 0% 98%;
    --muted: 240 5% 25%;
    --muted-foreground: 60 5% 85%;
    --card: 240 4% 10%;
    --card-foreground: 60 5% 90%;
    --popover: 240 5% 15%;
    --popover-foreground: 60 5% 85%;
    --border: 240 6% 20%;
    --input: 240 6% 20%;
    --ring: 240 5% 90%;
    --chart-1: 359 2% 90%;
    --chart-2: 240 1% 74%;
    --chart-3: 240 1% 58%;
    --chart-4: 240 1% 42%;
    --chart-5: 240 2% 26%;
  }
}

:root {
  --font-size-scale: 1;

  --primary-color: #E07A68;
  --primary-light: #EEA193;
  --primary-dark: #C4604F;
  --primary-gradient: linear-gradient(135deg, #E07A68 0%, #C4604F 100%);

  --background-main: #fafafa;
  --background-light: #ffffff;
  --background-light-rgb: 248, 249, 250;
  --background-dark: #d1d5db;
  --background-overlay: rgba(255, 255, 255, 0.98);
  --background-hover: rgba(243, 244, 246, 0.8);
  --background-hover-primary: rgba(255, 241, 239, 0.9);
  --background-hover-secondary: rgba(255, 235, 232, 0.95);

  --text-primary: #000000;
  --text-secondary: #4b5563;
  --text-tertiary: #9ca3af;
  --text-light: #ffffff;
  --text-dark: #111827;
  --text-muted: #6b7280;
  --text-on-primary: #ffffff;

  --border-color: #d1d5db;
  --border-light: #f0f1f4;
  --border-strong: #d1d5db;
  --border-primary: rgba(244, 128, 107, 0.45);
  --border-primary-hover: rgba(244, 128, 107, 0.65);
  --border-focus: rgba(244, 128, 107, 0.8);
  --border-interactive: #e5e7eb;
  --border-interactive-hover: #d1d5db;

  --shadow-color: rgba(0, 0, 0, 0.08);
  --shadow-primary: rgba(244, 128, 107, 0.15);
  --shadow-primary-hover: rgba(244, 128, 107, 0.25);

  --message-user-bg: #fcfcfc;
  --message-user-border: rgba(200, 100, 80, 0.25);
  --message-loading-color: #4b5563;

  --success-color: #22c55e;
  --success-dark: #16a34a;
  --warning-color: #f59e0b;
  --error-color: #ef4444;
  --info-color: #3b82f6;

  --dropdown-bg: rgba(255, 255, 255, 0.98);
  --dropdown-hover: rgba(0, 0, 0, 0.04);
  --dropdown-text: var(--text-primary);
  --dropdown-text-hover: var(--text-dark);
  --button-disabled: #d1d5db;
  --input-focus: rgba(244, 128, 107, 0.15);

  --sidebar-width: 250px;
  --sidebar-bg: #f5f3f1;
  --sidebar-text: #1a1d21;
  --sidebar-hover: #e9ecef;
  --sidebar-active: #ffffff;
  --sidebar-border: #e2e4e9;

  --resources-width: 40%;
  --resources-width-expanded: 50%;
  --resources-width-large: 25%;
  --resources-width-medium: 28%;
  --resources-border-color: #f4806b;

  --font-primary: 'Inter', 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-secondary: 'SF Pro Display', 'Helvetica Neue', 'Arial Nova', 'Noto Sans', system-ui, sans-serif;
  --font-code-primary: 'JetBrains Mono', 'Cascadia Code', 'Fira Code', 'Operator Mono', 'SF Mono', monospace;
  --font-code-elegant: 'Dank Mono', 'Operator Mono SSm', 'Cartograph CF', 'Monolisa', 'Pragmata Pro', monospace;
  --font-code-readable: 'IBM Plex Mono', 'Source Code Pro', 'Hack', 'Ubuntu Mono', 'Iosevka', monospace;
  --font-code-classic: 'Menlo', 'Consolas', 'Monaco', 'Courier Prime', 'DejaVu Sans Mono', monospace;
  --font-code-modern: 'Victor Mono', 'Anonymous Pro', 'Space Mono', 'Input Mono', 'Recursive Mono', monospace;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 550;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;

  --font-size-xxs: calc(clamp(0.72rem, 0.67rem + 0.2vw, 0.78rem) * var(--font-size-scale));
  --font-size-xs: calc(clamp(0.78rem, 0.72rem + 0.25vw, 0.84rem) * var(--font-size-scale));
  --font-size-sm: calc(clamp(0.90rem, 0.84rem + 0.25vw, 0.96rem) * var(--font-size-scale));
  --font-size-base: calc(clamp(0.96rem, 0.91rem + 0.25vw, 1.02rem) * var(--font-size-scale));
  --font-size-lg: calc(clamp(1.27rem, 1.21rem + 0.25vw, 1.39rem) * var(--font-size-scale));
  --font-size-xl: calc(clamp(1.46rem, 1.36rem + 0.25vw, 1.66rem) * var(--font-size-scale));
  --font-size-2xl: calc(clamp(1.76rem, 1.56rem + 0.5vw, 2.16rem) * var(--font-size-scale));
  --font-size-3xl: calc(clamp(2.26rem, 1.96rem + 1vw, 2.76rem) * var(--font-size-scale));
  --font-size-4xl: calc(clamp(2.76rem, 2.36rem + 1vw, 3.26rem) * var(--font-size-scale));

  --line-height-tight: 1.2;
  --line-height-snug: 1.35;
  --line-height-base: 1.65;
  --line-height-relaxed: 1.75;
  --line-height-loose: 1.75;

  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0em;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;
  --letter-spacing-widest: 0.1em;

  --text-primary: hsl(220, 15%, 16%);
  --text-secondary: hsl(220, 15%, 40%);
  --text-tertiary: hsl(220, 15%, 55%);
  --text-light: hsl(0, 0%, 100%);
  --text-dark: hsl(220, 15%, 9%);
  --text-muted: hsl(220, 15%, 45%);

  --paragraph-spacing: 1.2em;
  --paragraph-spacing-loose: 2em;

  --text-shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.1);
  --text-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.1);
  --text-shadow-lg: 0 4px 6px rgba(0, 0, 0, 0.1);

  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;

  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-2xl: 20px;
  --radius-3xl: 24px;
  --radius-4xl: 28px;
  --radius-5xl: 32px;
  --radius-full: 9999px;

  --transition-fast: 0.2s ease;
  --transition-base: 0.3s ease;
  --transition-slow: 0.5s ease;
  --transition-bezier: cubic-bezier(0.4, 0, 0.2, 1);

  --z-dropdown: 1000;
  --z-sticky: 1020;
  --z-fixed: 1030;
  --z-modal: 1040;
  --z-popover: 1050;
  --z-tooltip: 1060;

  --blur-sm: blur(4px);
  --blur-md: blur(8px);
  --blur-lg: blur(16px);

  --ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease-in: cubic-bezier(0.4, 0, 1, 1);

  --hover-opacity: 0.8;
  --active-opacity: 0.7;
  --disabled-opacity: 0.5;
  --focus-ring: 0 0 0 2px var(--primary-light);
  --focus-ring-offset: 2px;

  --input-bg: var(--background-main);
  --input-border: var(--border-color);
  --input-placeholder: var(--text-tertiary);
  --input-disabled-bg: var(--background-light);
  --input-error-border: var(--error-color);
  --input-success-border: var(--success-color);

  --button-hover-transform: translateY(-1px);
  --button-active-transform: translateY(1px);
  --button-focus-shadow: 0 0 0 3px var(--primary-color);

  --scrollbar-width: 8px;
  --scrollbar-track: var(--background-main);
  --scrollbar-thumb: var(--text-tertiary);
  --scrollbar-thumb-hover: var(--text-secondary);

  --skeleton-bg: var(--background-light);
  --skeleton-highlight: var(--background-hover);
  --loading-spinner-color: var(--primary-color);

  --selection-bg: var(--primary-color);
  --selection-text: var(--text-light);

  transition:
    background-color 0.3s var(--ease-out),
    color 0.3s var(--ease-out),
    border-color 0.3s var(--ease-out),
    box-shadow 0.3s var(--ease-out),
    transform 0.2s var(--ease-out),
    opacity 0.2s var(--ease-out),
    fill 0.3s var(--ease-out),
    stroke 0.3s var(--ease-out);

  & button,
  & input,
  & select,
  & textarea,
  & a {
    transition:
      background-color 0.2s var(--ease-out),
      color 0.2s var(--ease-out),
      border-color 0.2s var(--ease-out),
      box-shadow 0.2s var(--ease-out),
      transform 0.1s var(--ease-out),
      opacity 0.2s var(--ease-out);
  }

  & svg {
    transition:
      fill 0.3s var(--ease-out),
      stroke 0.3s var(--ease-out),
      opacity 0.2s var(--ease-out),
      transform 0.2s var(--ease-out);
  }

  --glass-background: rgba(255, 255, 255, 0.85);
  --border-glass: rgba(255, 255, 255, 0.2);
  --shadow-elevated:
    0 10px 20px rgba(0, 0, 0, 0.1),
    0 6px 6px rgba(0, 0, 0, 0.06),
    0 0 100px rgba(0, 0, 0, 0.02);

  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;

  --content-spacing-xs: 0.5rem;
  --content-spacing-sm: 0.75rem;
  --content-spacing-md: 1rem;
  --content-spacing-lg: 1.25rem;
  --content-spacing-xl: 1.5rem;
  --content-spacing-2xl: 2rem;

  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-base: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 1.75;

  --paragraph-spacing-base: 1.25rem;
  --paragraph-spacing-large: 1.5rem;

  --list-item-spacing: 0.75rem;
  --nested-list-spacing: 1rem;

  --tooltip-bg: var(--background-main);
  --tooltip-text: var(--text-primary);
}

[data-theme="dark"] {
  --primary-color: #E08575;
  --primary-light: #F09D8F;
  --primary-dark: #C4604F;
  --primary-gradient: linear-gradient(135deg, #E08575 0%, #C4604F 100%);

  --background-main: #2a2826;
  --background-light: #383532;
  --background-light-rgb: 56, 53, 50;
  --background-dark: #1e1c1a;
  --background-overlay: rgba(42, 40, 38, 0.97);
  --background-hover: rgba(56, 53, 50, 0.75);
  --background-hover-primary: rgba(45, 35, 32, 0.85);
  --background-hover-secondary: rgba(40, 30, 28, 0.9);

  --text-primary: #EAE8E6;
  --text-secondary: #B8B5B1;
  --text-tertiary: #95918D;
  --text-light: #FFFFFF;
  --text-dark: #F8F8FA;
  --text-muted: #A8A4A0;

  --border-color: #44413e;
  --border-light: #504d4a;
  --border-strong: #5e5a56;
  --border-primary: rgba(240, 157, 143, 0.35);
  --border-primary-hover: rgba(240, 157, 143, 0.5);
  --border-focus: rgba(240, 157, 143, 0.7);
  --border-interactive: #4a4744;
  --border-interactive-hover: #55514e;

  --shadow-color: rgba(0, 0, 0, 0.35);
  --shadow-primary: rgba(240, 157, 143, 0.2);
  --shadow-primary-hover: rgba(240, 157, 143, 0.3);

  --message-user-bg: #33302d;
  --message-user-border: rgba(240, 157, 143, 0.2);
  --message-loading-color: #B8B5B1;

  --success-color: #4ADE80;
  --success-dark: #22C55E;
  --warning-color: #FBBF24;
  --error-color: #F87171;
  --info-color: #60A5FA;

  --dropdown-bg: rgba(40, 38, 36, 0.97);
  --dropdown-hover: rgba(255, 255, 255, 0.06);
  --dropdown-text: var(--text-primary);
  --dropdown-text-hover: var(--text-light);
  --button-disabled: #4a4744;
  --input-focus: rgba(240, 157, 143, 0.3);

  --sidebar-bg: #201e1c;
  --sidebar-text: var(--text-primary);
  --sidebar-hover: #302d2a;
  --sidebar-active: #383532;
  --sidebar-border: #3a3734;

  --resources-border-color: #E08575;

  --input-bg: var(--background-dark);
  --input-disabled-bg: var(--background-main);

  --scrollbar-track: #252321;
  --scrollbar-thumb: #5e5a56;
  --scrollbar-thumb-hover: #74706c;

  --skeleton-bg: #302d2a;
  --skeleton-highlight: #44413e;

  --glass-background: rgba(42, 40, 38, 0.85);
  --border-glass: rgba(255, 255, 255, 0.1);

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --text-primary: hsl(30, 4%, 90%);
  --text-dark: hsl(240, 10%, 98%);

  --tooltip-bg: var(--background-light);
  --tooltip-text: var(--text-primary);
}

.tooltip-custom {
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  padding: 0.5rem;
  border-radius: var(--radius-md);
}